import { createEvent, createStore } from "effector";

import { SortBy, SortOrder, TSelectedSort } from "../../types/common";
import { TSortList, ESortListValue, SELECTED_SEARCH } from "../../types/sort";

export const $selectedSort = createStore<TSelectedSort>(
  SELECTED_SEARCH[ESortListValue.POPULARITY],
);

export const $sortList = createStore<null | TSortList[]>([
  {
    label: "CHEAPEST_FIRST",
    value: ESortListValue.ASC_PRICE,
  },
  {
    label: "BY_POPULARITY",
    value: ESortListValue.POPULARITY,
  },
  {
    label: "MOST_EXPENSIVE_FIRST",
    value: ESortListValue.DESC_PRICE,
  },
  {
    label: "BY_RATING",
    value: ESortListValue.HOTEL_RATING,
  },
]);

export const setSelectedSort = createEvent<keyof typeof SELECTED_SEARCH>();

$selectedSort.on(setSelectedSort, (_, v) => SELECTED_SEARCH[v]);

export const $selectedSortValue = $selectedSort.map((sort) => {
  const { sort_by, sort_order } = sort;

  switch (sort_by) {
    case SortBy.PRICE:
      return sort_order === SortOrder.ASC
        ? ESortListValue.ASC_PRICE
        : ESortListValue.DESC_PRICE;
    case SortBy.POPULARITY:
      if (sort_order === SortOrder.DESC) {
        return ESortListValue.POPULARITY;
      }
      break;
    case SortBy.HOTEL_RATING:
      if (sort_order === SortOrder.DESC) {
        return ESortListValue.HOTEL_RATING;
      }
      break;
  }

  return null;
});
