import { mainTheme } from "shared/ui/styles";

export const styles = {
  expandedIcon: {
    transition: "0.2s",
  },

  title: {
    fontSize: "16px",
    fontWeight: "500",
  },

  expandIcon: {
    transition: "0.2s",
    transform: "rotateZ(-90deg)",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  header: {
    display: "flex",
    fontSize: "18px",
    cursor: "pointer",
    alignItems: "center",
    marginBottom: "12px",
    justifyContent: "space-between",
  },

  buttonText: {
    fontSize: 16,
    paddingLeft: 0,
    height: "26px",
    marginTop: "16px",
    lineHeight: "18px",
    color: "accent.brand.dark",
    [mainTheme.breakpoints.down("smd")]: {
      "&:hover": {
        opacity: 1,
      },
    },
  },

  collapsedCheckboxContainer: {
    "& .MuiCollapse-wrapperInner": {
      gap: "12px",
      display: "flex",
      flexWrap: "wrap",

      [mainTheme.breakpoints.down("searchDesktop")]: {
        gap: "16px",
        marginTop: "16px",
      },
    },
  },
};
