import { FC, useState, useCallback } from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, SxProps, Collapse, Typography } from "@mui/material";
import { useUnit } from "effector-react";

import {
  LIST_OF_CHIPS_CODES,
  DEFAULT_VISIBLE_CHECKBOXES_COUNT,
} from "../../../model/constants";
import { FilterCheckboxes } from "../checkboxes";
import { FilterCheckboxesOrChipsWrapper } from "../common-wrapper";
import { styles } from "./styles";

import { updateSearch } from "@/widgets/search/tour-search-form/model";

import { Condition } from "@/shared/lib/condition";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import {
  $selectedFilters,
  toggleSelectedFilters,
} from "@/shared/model/store/filters";
import { CustomChip, CollapseButton } from "@/shared/ui";

type TProps = {
  id?: string;
  code: string;
  title: string;
  expanded?: boolean;
  isDesktop?: boolean;
  options: {
    id: string;
    name: string;
  }[];
};

export const FilterCheckboxWrapper: FC<TProps> = ({
  id,
  code = "",
  title = "",
  options = [],
  expanded = false,
  isDesktop = false,
}) => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const numberForOptionsSlice = isDesktop
    ? undefined
    : DEFAULT_VISIBLE_CHECKBOXES_COUNT;

  const isChipsFilter = LIST_OF_CHIPS_CODES.includes(code);

  const [isExpanded, setIsExpanded] = useState(expanded);

  const filterNameForId = code !== "hotel_amenities" ? code : `${code}_${id}`;

  const [selectedFilters, toggleFiltersFn, updateSearchFn] = useUnit([
    $selectedFilters,
    toggleSelectedFilters,
    updateSearch,
  ]);

  const handleSelectionChanged = useCallback(
    (filterId: string) => {
      sendDataLayerEventFn({
        event: "tourfiltrClick",
        data: {
          choiceFiltr: [
            ...options
              .filter(
                (c) =>
                  c.id === filterId || selectedFilters?.[code]?.includes(c.id),
              )
              .map((c) => c.name),
          ],
        },
      });

      toggleFiltersFn({ code, id: filterId });

      updateSearchFn();
    },
    [
      code,
      options,
      selectedFilters,
      sendDataLayerEventFn,
      toggleFiltersFn,
      updateSearchFn,
    ],
  );

  const setCheckedStatus = useCallback(
    (code: string, itemId: string) =>
      Boolean(selectedFilters?.[code]?.some((id) => id === itemId)),
    [selectedFilters],
  );

  return (
    <Box>
      <Box
        sx={styles.header}
        onClick={() => isDesktop && setIsExpanded((prevState) => !prevState)}
      >
        <Typography sx={styles.title}>{title}</Typography>
        <Condition match={isDesktop}>
          <Box sx={isExpanded ? styles.expandedIcon : styles.expandIcon}>
            <KeyboardArrowDown fontSize="small" />
          </Box>
        </Condition>
      </Box>

      <Box sx={styles.content}>
        <FilterCheckboxesOrChipsWrapper code={code} isDesktop={isDesktop}>
          <>
            <Condition match={!isDesktop && isChipsFilter}>
              {options.map((c, index) => {
                const isChecked = setCheckedStatus(code, c.id);

                return (
                  <CustomChip
                    key={index}
                    size="medium"
                    label={c.name}
                    variant={"outlined"}
                    color={isChecked ? "primary" : "default"}
                    onClick={() => handleSelectionChanged(c.id)}
                    id={`filter_${filterNameForId}_${index}_test`}
                  />
                );
              })}
            </Condition>
            <Condition match={!isDesktop && !isChipsFilter}>
              {options
                .slice(0, DEFAULT_VISIBLE_CHECKBOXES_COUNT)
                .map((c, index) => {
                  const isChecked = setCheckedStatus(code, c.id);

                  return (
                    <FilterCheckboxes
                      item={c}
                      key={index}
                      isDesktop={isDesktop}
                      isChecked={isChecked}
                      id={`filter_${filterNameForId}_${index}_test`}
                      handleSelectionChanged={handleSelectionChanged}
                    />
                  );
                })}
            </Condition>
          </>
        </FilterCheckboxesOrChipsWrapper>
        <Condition match={(!isChipsFilter && !isDesktop) || isDesktop}>
          <Collapse
            in={isExpanded}
            sx={styles.collapsedCheckboxContainer as SxProps}
          >
            {options.slice(numberForOptionsSlice).map((c, index) => {
              const isChecked = setCheckedStatus(code, c.id);
              const checkboxIndex = numberForOptionsSlice
                ? numberForOptionsSlice + index
                : index;
              return (
                <FilterCheckboxes
                  item={c}
                  key={index}
                  isDesktop={isDesktop}
                  isChecked={isChecked}
                  handleSelectionChanged={handleSelectionChanged}
                  id={`filter_${filterNameForId}_${checkboxIndex}_test`}
                />
              );
            })}
          </Collapse>
        </Condition>
      </Box>

      <Condition
        match={
          !isDesktop &&
          !isChipsFilter &&
          options.length > DEFAULT_VISIBLE_CHECKBOXES_COUNT
        }
      >
        <CollapseButton
          isIconHidden
          isOpen={isExpanded}
          sx={styles.buttonText}
          onClick={() => setIsExpanded((prevState) => !prevState)}
        />
      </Condition>
    </Box>
  );
};
