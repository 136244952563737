import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  notFound: {
    padding: "14px 8px",
  },

  popoverStyles: {
    marginTop: "44px",
  },

  search: {
    marginTop: "8px !important",
  },

  item: {
    fontSize: "16px",
    cursor: "pointer",
    borderRadius: "4px",
  },

  label: {
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.2px",
    color: "content.grey-400",
    textTransform: "uppercase",
  },

  wrapper: {
    top: -40,
    zIndex: 1,
    width: "100%",
    marginTop: "8px",
    maxHeight: "338px",
    overflowY: "scroll",
    borderRadius: "8px",
    position: "absolute",
    padding: "12px 2px 12px 12px",
    boxShadow: "0px 0px 15px rgba(74, 85, 104, 0.1)",
    ...mainTheme.scrollbars.default,
  },
};
