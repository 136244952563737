import { useMemo } from "react";

import { Skeleton, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { SearchUninitialized } from "../search-uninitialized";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { getCountriesDetailsByIdQuery } from "@/shared/model/api/common";
import { Breadcrumbs } from "@/shared/ui";

export const SearchHeader = () => {
  const t = useTranslations();
  const { data: selectedArrivalDetail } = useUnit(getCountriesDetailsByIdQuery);

  const isIdle = useUnit(getCountriesDetailsByIdQuery.$idle);

  const breadcrumbs = useMemo(
    () => [
      {
        href: "/",
        title: t("HOME"),
      },
      {
        href: `/search`,
        title: `Туры ${selectedArrivalDetail?.preposition ?? "в"} ${
          selectedArrivalDetail?.case_forms?.accusative ?? "..."
        }`,
      },
    ],
    [
      selectedArrivalDetail?.case_forms?.accusative,
      selectedArrivalDetail?.preposition,
      t,
    ],
  );

  return (
    <>
      <Condition match={!isIdle}>
        <Breadcrumbs isBackLink={false} breadcrumbs={breadcrumbs} />

        <Typography sx={styles.desktopTitle}>
          {selectedArrivalDetail?.name ?? <Skeleton width={"200px"} />}
        </Typography>
      </Condition>

      <Condition match={isIdle}>
        <SearchUninitialized />
      </Condition>
    </>
  );
};
