import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  divider: {
    margin: "16px 0",
  },

  caption: {
    color: "content.grey-400",
  },

  icons: {
    width: "18px",
    height: "18px",
  },

  text: {
    width: "24px",
    display: "block",
    textAlign: "center",
  },

  infoBlock: {
    rowGap: "2px",
    display: "flex",
    flexDirection: "column",
  },

  listItemStyles: {
    "&:hover": {
      backgroundColor: "accent.brand.lightest",
    },
  },

  buttons: {
    display: "flex",
    columnGap: "16px",
    alignItems: "center",
    "& button": {
      width: "32px",
    },
  },

  childrenAgeText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    marginBottom: "12px",
    color: "content.grey-400",
  },

  row: {
    display: "flex",
    columnGap: "16px",
    alignItems: "center",
    justifyContent: "space-between",

    "& + &": {
      marginTop: "16px",
    },

    childrenAge: {
      display: "block",
      "& + &": {
        marginTop: "16px",
      },
    },
  },

  wrapper: {
    padding: "16px",
    marginTop: "12px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",

    [mainTheme.breakpoints.down("smd")]: {
      margin: 0,
      padding: "0",
      width: "100%",
      border: "none",
    },
  },
};
