/* eslint-disable perfectionist/sort-objects */
import { sample, createEvent, createStore } from "effector";
import { interval } from "patronum";

import { MODAL_WITH_BONUSES_FROM_APP_TIMEOUT } from "../lib/constants";

export const closeModal = createEvent();
export const openModal = createEvent();
export const $isModalOpen = createStore(false);
const $isModalAlreadyWasOpened = createStore(false);

$isModalOpen.on(closeModal, () => false);
$isModalAlreadyWasOpened.on(closeModal, () => true);

// TODO: move to fabric
export const startMainModalTimerLogic = createEvent();
const stopCounter = createEvent();
const $counter = createStore(0);

sample({
  clock: openModal,
  filter: $isModalAlreadyWasOpened.map((value) => !value),
  target: startMainModalTimerLogic,
});

const { tick } = interval({
  timeout: 1000,
  stop: stopCounter,
  start: startMainModalTimerLogic,
});

$counter.on(tick, (number) => number + 1000);

sample({
  source: $counter,
  filter: (counter) => counter > MODAL_WITH_BONUSES_FROM_APP_TIMEOUT,
  fn: () => true,
  target: [stopCounter, $isModalOpen],
});
