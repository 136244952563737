/* eslint-disable perfectionist/sort-objects */
import { sample, createEvent } from "effector";

import { updateSearch } from "../../tour-search-form/model";

import { TDataLayerEvent, sendDataLayerEvent } from "@/shared/model/analytics";
import { setSelectedSort } from "@/shared/model/store/sort";
import { ESortListValue } from "@/shared/model/types/sort";

const EVENT_MAPPING: Record<ESortListValue, TDataLayerEvent> = {
  [ESortListValue.ASC_PRICE]: "sortingByCheap",
  [ESortListValue.DESC_PRICE]: "sortingByExpensive",
  [ESortListValue.POPULARITY]: "sortingByPopular",
  [ESortListValue.HOTEL_RATING]: "sortingByRating",
};

export const updateSort = createEvent<ESortListValue>();

sample({
  clock: updateSort,
  target: [setSelectedSort, updateSearch],
});

sample({
  clock: updateSort,
  fn: (value) => ({
    event: EVENT_MAPPING[value],
  }),
  target: sendDataLayerEvent,
});
