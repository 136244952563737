import { FC } from "react";

import { Box, SxProps, Collapse, Typography } from "@mui/material";
import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import {
  LastSeenGate,
  $lastSeenTours,
  deleteLastSeen,
  $isLastSeenCollapseOpen,
} from "../../model";
import { LastSeenToursCard } from "../LastSeenToursCard";
import { styles } from "./styles";

export const LastSeenTours: FC = () => {
  useGate(LastSeenGate);

  const t = useTranslations();

  const [isOpen, lastSeen, handleLastSeenToursDelete] = useUnit([
    $isLastSeenCollapseOpen,
    $lastSeenTours,
    deleteLastSeen,
  ]);

  return (
    // collapse for smoother render
    <Collapse in={isOpen} timeout={500}>
      <Box sx={styles.wrapper}>
        <Typography sx={styles.text}>{t("RECENT_SEARCHES")}</Typography>
        <Box sx={styles.cards as SxProps}>
          {lastSeen.filter(Boolean).map((tour, index) => (
            <LastSeenToursCard
              key={index}
              tour={{ ...tour, index }}
              handleDelete={handleLastSeenToursDelete}
            />
          ))}
        </Box>
      </Box>
    </Collapse>
  );
};
