import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  wrapper: {
    mt: "16px",
    width: "100%",
  },

  skeleton: {
    height: "58px",
    width: "160px",
    marginRight: "12px",
  },

  cards: {
    display: "flex",
    paddingTop: "10px",
    scrollbarWidth: "none",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    [mainTheme.breakpoints.down("smd")]: {
      overflowX: "scroll",
    },
  },

  text: {
    opacity: "0.7",
    fontSize: "14px",
    lineHeight: "16px",
    marginBottom: "8px",
    position: "relative",
    color: "dominant.white",

    [mainTheme.breakpoints.down("smd")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
};
