import { createQuery } from "@farfetched/core";
import { attach } from "effector";

import { TFFRequest, BACKEND_KEYS } from "../../types/common";
import { TArrivalLocation, TDepartureLocation } from "../../types/header-menu";
import { TSearchHotels } from "../../types/hotels";
import {
  TCalendarData,
  TUpcomingDates,
  TAvailableNights,
  TSearchFetchNightsParams,
  TSearchFetchUpcomingDatesParams,
  TSearchFetchAvailableCalendarDatesParams,
} from "../../types/search";

import { requestFx } from "@/shared/config";
import { QueryTools } from "@/shared/lib/helpers/farfetched";

export type TDepartureLocationResponse = TFFRequest<TDepartureLocation[]>;
export type TArrivalLocationResponse = TFFRequest<TArrivalLocation[]>;
export type TAvailableNightsResponse = TFFRequest<TAvailableNights>;
export type TUpcomingDatesResponse = TFFRequest<TUpcomingDates[]>;
export type TCalendarDataResponse = TFFRequest<TCalendarData[]>;
export type THotelsResponse = TFFRequest<TSearchHotels>;
/////////////////////////////

const fetchDepartureDataFx = attach<void, TDepartureLocationResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: `tours/search-form/departure-locations`,
  }),
});

export const getDepartureDataQuery = createQuery({
  name: "getDepartureData",
  effect: fetchDepartureDataFx,
  mapData: ({ result }) => {
    return result.data;
  },
});

/////////////////////////////

const fetchArrivalDataFx = attach<string, TArrivalLocationResponse>({
  effect: requestFx,
  mapParams: (id) => ({
    url: `tours/search-form/arrival-locations`,
    params: {
      departure_location_id: id,
    },
  }),
});

export const getArrivalDataQuery = createQuery({
  name: "getArrivalData",
  effect: fetchArrivalDataFx,
  mapData: ({ result }) => {
    return result.data;
  },
});

/////////////////////////////

const fetchNightsFx = attach<
  TSearchFetchNightsParams,
  TAvailableNightsResponse
>({
  effect: requestFx,
  mapParams: ({ date_to, date_from, arrival_country, departure_location }) => ({
    url: `tours/search-form/nights`,
    params: {
      date_to,
      date_from,
      arrival_country,
      departure_location,
    },
  }),
});

export const getNightsQuery = createQuery({
  name: "getNights",
  effect: fetchNightsFx,
  mapData: ({ result }) => {
    return result.data.data;
  },
});

/////////////////////////////

const fetchAvailableCalendarDatesFx = attach<
  TSearchFetchAvailableCalendarDatesParams,
  TCalendarDataResponse
>({
  effect: requestFx,
  mapParams: ({ arrivalId, departureId }) => ({
    url: `tours/search-form/calendar`,
    params: {
      arrival_country_id: arrivalId,
      departure_location_id: departureId,
      // arrival_location_id: arrivalRegionsId.join(','),
    },
  }),
});

export const getAvailableCalendarDatesQuery = createQuery({
  name: "getAvailableCalendarDates",
  effect: fetchAvailableCalendarDatesFx,
  mapData: ({ result }) => {
    return result.data.data;
  },
});

/////////////////////////////

const fetchUpcomingDatesFx = attach<
  TSearchFetchUpcomingDatesParams,
  TUpcomingDatesResponse
>({
  effect: requestFx,
  mapParams: ({
    date_from,
    date_to = null,
    arrival_country,
    departure_location,
  }) => ({
    url: "tours/search/upcoming-dates",
    params: {
      [BACKEND_KEYS.rangeEndDay]: date_to,
      [BACKEND_KEYS.rangeStartDay]: date_from,
      [BACKEND_KEYS.arrival]: arrival_country,
      [BACKEND_KEYS.departure]: departure_location,
    },
  }),
});

export const getUpcomingDatesQuery = createQuery({
  name: "getUpcomingDates",
  effect: fetchUpcomingDatesFx,
  mapData: ({ result }) => {
    return result.data.data;
  },
});

QueryTools(getDepartureDataQuery).errors.handleAll();
QueryTools(getArrivalDataQuery).errors.handleAll();
QueryTools(getNightsQuery).errors.handleAll();
QueryTools(getAvailableCalendarDatesQuery).errors.handleAll();
