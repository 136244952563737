export const styles = {
  applyButton: {
    width: "100%",
  },

  divider: {
    margin: "16px -16px",
  },

  childrenText: {
    marginTop: "0",
    fontSize: "16px",
    color: "grey.dark",
    lineHeight: "18.56px",
  },

  drawer: {
    height: "100%",
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
