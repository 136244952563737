import dayjs from "dayjs";
import { createApi, createEvent, createStore } from "effector";

import { TMonthsDays, TMobileMonthDays } from "../../types/search";

import {
  SERVER_DATE_FORMAT,
  DEFAULT_AMOUNT_OF_NIGHTS,
} from "@/shared/lib/constants";

const INITIAL_CURRENT_DATE = dayjs().add(1, "day").format(SERVER_DATE_FORMAT);

export const $monthDays = createStore<TMonthsDays>({
  nextMonthDays: [],
  currentMonthDays: [],
});
export const $mobileMonths = createStore<null | TMobileMonthDays>(null);
export const $nights = createStore<number>(DEFAULT_AMOUNT_OF_NIGHTS);
export const $endDate = createStore<string>("");
export const $startDate = createStore<string>("");
export const $hoveredDate = createStore<null | string>(null);
export const $currentDate = createStore<string>(INITIAL_CURRENT_DATE);
export const $isCalendarLoading = createStore<boolean>(false);
export const $isCalendarCalculating = createStore<boolean>(false);
export const $isCalendarError = createStore<boolean>(false);
export const $maxNightsCount = createStore<null | number>(21);
export const $isCalendarContentOpen = createStore(false);

export const { openCalendarContent, closeCalendarContent } = createApi(
  $isCalendarContentOpen,
  {
    openCalendarContent: () => true,
    closeCalendarContent: () => false,
  },
);

export const setMonthDays = createEvent<TMonthsDays>();
$monthDays.on(setMonthDays, (_, payload) => payload);

export const setMobileMonths = createEvent<
  {
    date: dayjs.Dayjs;
    days: {
      date: string;
      label: string;
      isAvailable: boolean;
      isCurrentMonth: boolean;
    }[];
  }[]
>();
$mobileMonths.on(setMobileMonths, (_, payload) => payload);

export const { setNights, resetNights } = createApi($nights, {
  setNights: (_, payload: number) => payload,
  resetNights: () => DEFAULT_AMOUNT_OF_NIGHTS,
});

export const { setEndDate, resetEndDate } = createApi($endDate, {
  resetEndDate: () => "",
  setEndDate: (_, payload: string) => payload,
});

export const { setStartDate, resetStartDate } = createApi($startDate, {
  resetStartDate: () => "",
  setStartDate: (_, payload: string) => payload,
});

export const { setHoveredDate, resetHoveredDate } = createApi($hoveredDate, {
  resetHoveredDate: () => null,
  setHoveredDate: (_, payload: string) => payload,
});

export const { reduceCurrentDate, increaseCurrentDate } = createApi(
  $currentDate,
  {
    increaseCurrentDate: (currentDate) => {
      return dayjs(currentDate).add(1, "month").format(SERVER_DATE_FORMAT);
    },
    reduceCurrentDate: (currentDate) => {
      return dayjs(currentDate).subtract(1, "month").format(SERVER_DATE_FORMAT);
    },
  },
);

export const { setCalendarLoading, resetCalendarLoading } = createApi(
  $isCalendarLoading,
  {
    setCalendarLoading: () => true,
    resetCalendarLoading: () => false,
  },
);

export const { enableCalendarLoader, disableCalendarLoader } = createApi(
  $isCalendarCalculating,
  {
    enableCalendarLoader: () => true,
    disableCalendarLoader: () => false,
  },
);

export const { setCalendarError, resetCalendarError } = createApi(
  $isCalendarError,
  {
    setCalendarError: () => true,
    resetCalendarError: () => false,
  },
);
