export const styles = {
  defaultButtonSx: {
    width: "160px !important",
  },

  submitButton: {
    gap: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },

  mainPageWrapper: {
    zIndex: 0,
    maxWidth: "90%",
    borderRadius: "8px",
    position: "relative",
    backgroundColor: "unset",
    margin: "-100px auto 36px",
  },

  mainPageSx: {
    display: "flex",
    marginBottom: "8px",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },

  popover: {
    tourDetails: {
      right: 0,
      left: "unset",
      width: "max-content",
    },

    default: {
      left: "50%",
      right: "unset",
      width: "max-content",
      transform: "translateX(-50%)",
    },
  },
};
