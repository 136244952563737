import { FC, memo } from "react";

import { Box } from "@mui/material";
import debounce from "lodash/debounce";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { SearchInput } from "@/shared/ui";

const DEBOUNCE_DELAY = 500;

type TProps = {
  value: string;
  setValue: (v: string) => void;
};

export const ArrivalRegionsSearch: FC<TProps> = memo(({ value, setValue }) => {
  const t = useTranslations();
  const handleSearch = debounce((searchValue: string) => {
    setValue(searchValue);
    handleSearch(searchValue);
  }, DEBOUNCE_DELAY);

  return (
    <Box sx={styles.wrapper}>
      <SearchInput
        value={value}
        sxInput={null}
        buttonProps={{}}
        disabled={false}
        isLoading={false}
        onChange={setValue}
        onClick={() => null}
        searchButtonText={""}
        showClearIcon={false}
        showSearchButton={false}
        placeholder={t("FIND_REGION")}
      />
    </Box>
  );
});
