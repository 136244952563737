export const styles = {
  title: {
    fontSize: "26px",
    lineHeight: "30px",
    marginBottom: "8px",
  },

  text: {
    fontSize: "18px",
    lineHeight: "22px",
    marginBottom: "24px",
  },

  container: {
    margin: "24px 0",
    width: "stretch",
    textAlign: "center",
    padding: "48px 24px",
  },
};
