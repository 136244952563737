import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  sectionBorder: {
    borderLeft: "1px solid",
    borderColor: "content.grey-100",
  },

  section: {
    display: "flex",
    flexDirection: "column",

    [mainTheme.breakpoints.down("smd")]: {
      border: "none",
    },

    [mainTheme.breakpoints.up("smd")]: {
      overflow: "hidden",
    },

    "&[disabled]": {
      userSelect: "none",
      pointerEvents: "none",
      color: "content.grey-200",
    },
  },
};
