export const styles = {
  collapse: {
    width: "100%",
  },

  wrapper: {
    padding: "16px",
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
    backgroundColor: "dominant.cloud",
  },
};
