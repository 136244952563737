export const styles = {
  text: {
    fontSize: "12px",
    lineHeight: "12px",
  },

  ratingWrapper: {
    display: "flex",
    alignItems: "center",
  },

  rating: {
    fontSize: "14px",
    lineHeight: "14px",
    marginRight: "6px",
  },

  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  card: {
    gap: "16px",
    padding: "2px",
    display: "flex",
    cursor: "pointer",
    borderRadius: "4px",
    border: `1px solid`,
    borderColor: "transparent",

    "&:hover": {
      borderColor: "content.secondary",
    },
  },

  name: {
    lineClamp: 1,
    fontSize: "16px",
    lineHeight: "16px",
    overflow: "hidden",
    marginBottom: "4px",
    display: "-webkit-box",
    color: "content.primary",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  },
};
