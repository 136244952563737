import { FC, memo, useMemo, useState, useCallback } from "react";

import { Person } from "@mui/icons-material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { getErrorText } from "../../lib/helpers";
import { QuantityCommon } from "../QuantityCommon";
import { styles } from "./styles";

import {
  MAX_ADULTS,
  ErrorTypes,
} from "@/entities/search/Quantity/lib/constants";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { mainOnboarding } from "@/shared/model/onboardings/main-onboarding";
import { $searchType } from "@/shared/model/store/common";
import {
  $adults,
  $children,
  $errorType,
  resetQuantityError,
  openQuantityContent,
  closeQuantityContent,
  $isQuantityContentOpen,
} from "@/shared/model/store/quantity";
import { ESearchType } from "@/shared/model/types/search";
import { Input, Popover } from "@/shared/ui";

type TTriggerProps = {
  onClick: () => void;
  isPopoverOpen: boolean;
  fullInputWidth?: boolean;
};

const Trigger: FC<TTriggerProps> = memo(function Trigger({
  onClick,
  isPopoverOpen,
  fullInputWidth = false,
}) {
  const t = useTranslations();
  const [showQuantityError, setShowQuantityError] = useState(false);

  const [errorType, adults, children, resetQuantityErrorFn] = useUnit([
    $errorType,
    $adults,
    $children,
    resetQuantityError,
  ]);

  // TODO: refactor errors
  const warning = useMemo(() => {
    if (adults === MAX_ADULTS && showQuantityError) {
      return t(getErrorText(ErrorTypes.Quantity));
    }

    return false;
  }, [adults, showQuantityError, t]);

  const value = useMemo(() => {
    const adultsText = t(
      children === 0 || fullInputWidth
        ? "ADULT_DECLINATIONS"
        : "ADULT_DECLINATIONS_SHORT",
      { count: adults },
    );

    const childrenText =
      children !== 0
        ? `${t(fullInputWidth ? "CHILDREN_DECLINATIONS" : "CHILDREN_DECLINATIONS_SHORT", { count: children })}`
        : null;

    return [adultsText, childrenText].filter((el) => !!el).join(", ");
  }, [adults, children, fullInputWidth, t]);

  const handleOnClick = useCallback(() => {
    if (errorType) {
      resetQuantityErrorFn();
    }

    onClick();
  }, [errorType, onClick, resetQuantityErrorFn]);

  return (
    <Input
      readOnly
      value={value}
      focused={isPopoverOpen}
      onClick={handleOnClick}
      id="quantity_field_test"
      placeholder="Кол-во людей"
      startAdornment={<Person />}
      wrapperId="quantity-onboarding"
      popperStyles={styles.popperStyles}
      sxWrapper={styles.inputWrapperStyles}
      showPopper={Boolean(errorType ?? warning)}
      sxPopperInsideBox={styles.sxPopperInsideBox}
      onMouseEnter={() => setShowQuantityError(true)}
      onMouseLeave={() => setShowQuantityError(false)}
      isError={
        errorType === ErrorTypes.ChildrenAges ||
        errorType === ErrorTypes.Infants
      }
      popperContent={
        getErrorText(errorType) || warning
          ? t(getErrorText(errorType) || warning)
          : null
      }
    />
  );
});

export const DesktopQuantity: FC = memo(() => {
  const searchType = useUnit($searchType);
  const [isQuantityContentOpen, closeQuantityContentFn, openQuantityContentFn] =
    useUnit([
      $isQuantityContentOpen,
      closeQuantityContent,
      openQuantityContent,
    ]);

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const handleOpenPopover = useCallback(() => {
    sendDataLayerEventFn({
      event: "navTourmenuClick",
      data: {
        clickText: "Туристы",
      },
    });

    if (mainOnboarding.isActive()) {
      mainOnboarding.show("quantity-modal");
    }

    openQuantityContentFn();
  }, [openQuantityContentFn, sendDataLayerEventFn]);

  const handleClose = () => {
    if (!mainOnboarding.isActive()) {
      closeQuantityContentFn();
    }
  };

  return (
    <Popover
      closePopover={handleClose}
      open={isQuantityContentOpen}
      popoverStyles={styles.popoverStyles}
      fullInputWidth={searchType === ESearchType.Hotel}
      trigger={
        <Trigger
          onClick={handleOpenPopover}
          isPopoverOpen={isQuantityContentOpen}
          fullInputWidth={searchType === ESearchType.Hotel}
        />
      }
    >
      <QuantityCommon />
    </Popover>
  );
});
