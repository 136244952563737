/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useRouter } from "next/navigation";
import { FC } from "react";

import { East, Place, ClearOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useUnit } from "effector-react";

import { generateQueryFromLastSeen } from "../../lib/helpers";
import { $destinationTexts } from "../../model";
import { styles } from "./styles";

import { TCommonProps } from "@/entities/search/LastSeenTours/lib/types";

import { useGetDeclinations } from "@/shared/lib/hooks/useGetDeclinations";
import { QUERY_OPTIONS, TLastSeenCardInfo } from "@/shared/model/types/common";

type TProps = TCommonProps & {
  tour: TLastSeenCardInfo;
};

export const LastSeenToursCard: FC<TProps> = ({ tour, handleDelete }) => {
  const router = useRouter();

  const destinations = useUnit($destinationTexts);

  const arrivalName =
    destinations.arrivals?.[tour[QUERY_OPTIONS.arrival]] ?? "-";
  const departureName =
    destinations.departures?.[tour[QUERY_OPTIONS.departure]] ?? "-";

  const searchPath = generateQueryFromLastSeen(tour);

  const { daysText, datesText, regionsText, passengersText } =
    useGetDeclinations(
      {
        adults: tour[QUERY_OPTIONS.adults],
        children: tour[QUERY_OPTIONS.children],
        endDate: tour[QUERY_OPTIONS.rangeEndDay],
        startDate: tour[QUERY_OPTIONS.rangeStartDay],
        nights: Number(tour[QUERY_OPTIONS.selectedNight]),
        regions: tour[QUERY_OPTIONS.selectedRegionIds]?.length,
      },
      true,
    );

  const combinedText = `${datesText}, ${daysText}, ${passengersText}`;

  const onCardClick = () => {
    void router.push(searchPath);
  };

  if (!arrivalName || !departureName) {
    handleDelete(tour.index);
    return null;
  }

  return (
    <Box sx={styles.card} onClick={onCardClick}>
      <Place sx={styles.icon} />
      <Box sx={styles.content}>
        <Box sx={styles.geolocations}>
          <Box sx={styles.geolocation}>{departureName}</Box>
          <East sx={styles.icon} />
          <Box sx={styles.geolocation}>{`${arrivalName}${regionsText}`}</Box>
        </Box>
        <Box sx={styles.info}>{combinedText}</Box>
      </Box>

      <IconButton
        sx={styles.delete}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(tour.index);
        }}
      >
        <ClearOutlined sx={styles.deleteIcon} />
      </IconButton>
    </Box>
  );
};
