import { FC, memo, useState, useCallback, KeyboardEvent } from "react";

import { FlightTakeoff } from "@mui/icons-material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { DepartureCities } from "../DepartureCities/DepartureCities";
import { styles } from "./styles";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { getDepartureDataQuery } from "@/shared/model/api/search-form";
import { $searchType } from "@/shared/model/store/common";
import {
  $departure,
  setDeparture,
  resetDeparture,
  $departureFilter,
  $isDepartureError,
  setDepartureFilter,
  resetDepartureError,
  resetDepartureFilter,
} from "@/shared/model/store/departure";
import { ESearchType } from "@/shared/model/types/search";
import { Input, Popover } from "@/shared/ui";

type TProps = {
  isLoading: boolean;
};

type TTriggerProps = {
  open: boolean;
  onClick: () => void;
} & TProps;

const Trigger: FC<TTriggerProps> = memo(({ open, onClick, isLoading }) => {
  const t = useTranslations();
  const { data: departures } = useUnit(getDepartureDataQuery);
  const [filter, isError] = useUnit([$departureFilter, $isDepartureError]);
  const [
    departure,
    setDepartureFn,
    setDepartureFilterFn,
    resetDepartureFilterFn,
    resetDepartureFn,
    resetDepartureErrorFn,
  ] = useUnit([
    $departure,
    setDeparture,
    setDepartureFilter,
    resetDepartureFilter,
    resetDeparture,
    resetDepartureError,
  ]);

  const handleChange = useCallback(
    (value: string) => {
      if (!value) {
        resetDepartureFn();
      }

      setDepartureFilterFn(value);
    },
    [resetDepartureFn, setDepartureFilterFn],
  );

  const handleFocusLeave = useCallback(() => {
    if (!filter || !departures) return;

    const departure = departures?.data?.find((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase()),
    );

    if (departure) {
      setDepartureFn(departure);
    }

    resetDepartureFilterFn();
  }, [departures, filter, resetDepartureFilterFn, setDepartureFn]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== "Enter") return;
      handleFocusLeave();
    },
    [handleFocusLeave],
  );

  const handleClick = useCallback(() => {
    if (isError) {
      resetDepartureErrorFn();
    }

    onClick();
  }, [isError, onClick, resetDepartureErrorFn]);

  return (
    <Input
      focused={open}
      isError={isError}
      showPopper={isError}
      disabled={isLoading}
      onClick={handleClick}
      onChange={handleChange}
      placeholder={t("FROM_2")}
      onBlur={handleFocusLeave}
      onKeyDown={handleKeyDown}
      startAdornment={<FlightTakeoff />}
      sxWrapper={styles.inputWrapperStyles}
      value={filter || departure?.name || ""}
      popperContent={t("FIRST_SELECT_DESTINATION")}
    />
  );
});

export const DesktopDeparture: FC<TProps> = memo(({ isLoading }) => {
  const [isContentOpen, setContentOpen] = useState(false);

  const searchType = useUnit($searchType);
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const handleOpenContent = useCallback(() => {
    sendDataLayerEventFn({
      event: "navTourmenuClick",
      data: {
        clickText: "Откуда",
      },
    });

    setContentOpen(true);
  }, [sendDataLayerEventFn]);
  const handleCloseContent = useCallback(() => setContentOpen(false), []);

  return (
    <Popover
      open={isContentOpen}
      closePopover={handleCloseContent}
      fullInputWidth={searchType === ESearchType.Hotel}
      trigger={
        <Trigger
          open={isContentOpen}
          isLoading={isLoading}
          onClick={handleOpenContent}
        />
      }
    >
      <DepartureCities isLoading={isLoading} onClose={handleCloseContent} />
    </Popover>
  );
});
