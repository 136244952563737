import { Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

export const SearchFormNotFound = () => {
  const t = useTranslations();

  return <Typography sx={styles.text}>{t("NOT_FOUND")}</Typography>;
};
