"use client";
import { useEffect, useCallback, useSyncExternalStore } from "react";

const useLocalStorageSubscribe = (callback: {
  (this: Window, ev: StorageEvent): void;
  (this: Window, ev: StorageEvent): void;
}) => {
  window.addEventListener("storage", callback);
  return () => window.removeEventListener("storage", callback);
};

const dispatchStorageEvent = (key: string, newValue: null | string) => {
  window.dispatchEvent(new StorageEvent("storage", { key, newValue }));
};

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

export const useLocalStorage = <T = string>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] => {
  const getLocalStorageItem = (key: string): T | null => {
    const storageValue = window.localStorage.getItem(key);

    return storageValue ? (JSON.parse(storageValue) as T) : null;
  };

  const setLocalStorageItem = (key: string, value: T | null) => {
    const stringifiedValue = JSON.stringify(value);
    window.localStorage.setItem(key, stringifiedValue);
    dispatchStorageEvent(key, stringifiedValue);
  };

  const getLocalStorageServerSnapshot = () => {
    return null;
  };

  const getSnapshot = () => getLocalStorageItem(key);

  const store = useSyncExternalStore<T | null>(
    useLocalStorageSubscribe,
    getSnapshot,
    getLocalStorageServerSnapshot,
  );

  const setState = useCallback(
    (newValue: T) => {
      try {
        if (newValue === undefined || newValue === null) {
          removeLocalStorageItem(key);
        } else {
          setLocalStorageItem(key, newValue);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, store],
  );

  useEffect(() => {
    if (
      getLocalStorageItem(key) === null &&
      typeof initialValue !== "undefined"
    ) {
      setLocalStorageItem(key, initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, initialValue]);

  return [store ? store : initialValue, setState];
};
