import { offset } from "@floating-ui/dom";
import Shepherd from "shepherd.js";

import { sendDataLayerEvent } from "@/shared/model/analytics";

export const commonStepsConfig = {
  scrollTo: false,
  modalOverlayOpeningRadius: 10,
  modalOverlayOpeningPadding: 6,
  cancelIcon: {
    enabled: true,
  },
  floatingUIOptions: {
    middleware: [offset({ mainAxis: 30, crossAxis: 12 })],
  },
};

export const setupProgressIndicator = (withProgressBar = true) => {
  const currentStep = Shepherd.activeTour?.getCurrentStep();
  const currentStepElement = currentStep?.getElement();
  const footer = currentStepElement?.querySelector(".shepherd-footer");
  const progressCounter = document.createElement("span");
  progressCounter.classList.add("shepherd-progress-counter");

  if (!currentStep || !footer || !Shepherd?.activeTour?.steps) return;

  progressCounter.innerText = `${Shepherd.activeTour.steps.indexOf(currentStep) + 1}/${Shepherd.activeTour.steps.length}`;
  footer.prepend(progressCounter);

  if (withProgressBar) {
    const progress = document.createElement("progress");
    progress.classList.add("shepherd-progress");
    progress.max = Shepherd.activeTour.steps.length;
    progress.value = Shepherd.activeTour.steps.indexOf(currentStep) + 1;
    footer.prepend(progress);
  }
};

export const createTooltipContent = (
  textContent: string,
  imgSrc: string,
): HTMLElement => {
  const wrapper = document.createElement("div");
  const text = document.createElement("div");
  text.innerHTML = textContent;

  const icon = document.createElement("img");
  icon.src = imgSrc;
  icon.style.width = "72px";

  wrapper.append(text, icon);
  wrapper.style.display = "flex";
  wrapper.style.alignItems = "self-start";
  wrapper.style.justifyContent = "space-between";

  return wrapper;
};

export const goToNextStep = (event: Event, onboarding: Shepherd.Tour) => {
  const currentStep = onboarding?.getCurrentStep();
  const currentStepIndex = currentStep
    ? onboarding?.steps.indexOf(currentStep)
    : 0;

  sendDataLayerEvent({
    event: "startOnboarding",
    data: {
      stepOnboarding: currentStepIndex,
    },
  });

  event.stopPropagation();
  (
    onboarding as {
      next: () => void;
    }
  ).next();
};
