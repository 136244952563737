import { memo, useMemo, useState, useCallback } from "react";

import { Alert, Button, Divider, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { ErrorTypes, MAX_ADULTS, MAX_CHILDREN } from "../../lib/constants";
import { getErrorText } from "../../lib/helpers";
import { QuantityCommon } from "../QuantityCommon";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import {
  checkAmountOfInfants,
  checkIfChildrenAgesValid,
} from "@/shared/lib/helpers/search/validations";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import {
  $adults,
  $children,
  $childrenAges,
} from "@/shared/model/store/quantity";
import { SwipeableDrawer, MobileSearchBox } from "@/shared/ui";

export const MobileQuantity = memo(() => {
  const t = useTranslations();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const [adults, children, childrenAges] = useUnit([
    $adults,
    $children,
    $childrenAges,
  ]);

  const handleOpenPopover = useCallback(() => {
    sendDataLayerEventFn({
      event: "navTourmenuClick",
      data: {
        clickText: "Туристы",
      },
    });

    setPopoverOpen(true);
  }, [sendDataLayerEventFn]);

  const handlePopoverClose = useCallback(() => setPopoverOpen(false), []);

  const isChildrenAgesFieldFilled = checkIfChildrenAgesValid(
    childrenAges,
    children,
  );
  const isInfantsFieldsValid = checkAmountOfInfants(adults, childrenAges);

  // TODO: refactor errors
  const error = useMemo(() => {
    if (!isChildrenAgesFieldFilled) {
      return t(getErrorText(ErrorTypes.ChildrenAges));
    }

    if (!isInfantsFieldsValid) {
      return t(getErrorText(ErrorTypes.Infants));
    }

    return null;
  }, [isChildrenAgesFieldFilled, isInfantsFieldsValid, t]);

  // TODO: refactor errors
  const warning = useMemo(() => {
    if (adults === MAX_ADULTS || children === MAX_CHILDREN) {
      return t(getErrorText(ErrorTypes.Quantity));
    }

    return false;
  }, [adults, children, t]);

  const mobileText = useMemo(() => {
    const childrenText = t("CHILDREN_DECLINATIONS", { count: children });
    const adultText = t("ADULT_DECLINATIONS", { count: adults });

    return {
      children: children ? childrenText : "",
      adults: `${adultText}${children ? "," : ""}`,
    };
  }, [adults, children, t]);

  return (
    <>
      <MobileSearchBox
        isError={Boolean(error)}
        title={mobileText.adults}
        onClick={handleOpenPopover}
        fieldId="quantity_field_test"
        fieldName={t("NUMBER_OF_TOURIST")}
      >
        <Typography sx={styles.childrenText}>{mobileText.children}</Typography>
      </MobileSearchBox>

      <SwipeableDrawer
        isOpen={isPopoverOpen}
        onOpen={handleOpenPopover}
        onClose={handlePopoverClose}
        title={t("NUMBER_OF_TOURIST")}
        childrenWrapperSx={styles.drawer}
        Footer={
          <>
            <Condition match={Boolean(error ?? warning)}>
              <Alert severity={error ? "error" : "warning"}>
                <Typography color="dominant.white">
                  {error ?? warning}
                </Typography>
              </Alert>
              <Divider sx={styles.divider} />
            </Condition>

            <Button
              variant="contained"
              sx={styles.applyButton}
              onClick={handlePopoverClose}
            >
              {t("APPLY")}
            </Button>
          </>
        }
      >
        <QuantityCommon />
      </SwipeableDrawer>
    </>
  );
});
