export const styles = {
  searchButtonSx: {
    width: "250px",
  },

  mobileDrawerContentWrapper: {
    height: "100%",
  },

  mobileDrawerContent: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },

  filterHeader: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    color: "content.primary",
  },

  desktopFilter: {
    gap: "8px",
    width: "100%",
    display: "flex",
    marginRight: "8px",
    alignItems: "center",
  },

  filterWrapper: {
    display: "flex",
    marginTop: "16px",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },

  contentWrapper: {
    gap: "16px",
    height: "100%",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  inputsWrapper: {
    display: "flex",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },

  wrapper: {
    padding: "24px",
    border: "1px solid",
    borderRadius: "16px",
    backgroundColor: "dominant.white",
    borderColor: "accent.brand.lightest",
  },

  mobileWrapper: {
    top: 0,
    zIndex: 1000,
    position: "sticky",
    margin: "0 0 16px 0",
    padding: "16px 16px",
    backgroundColor: "dominant.white",
    boxShadow: "0px 1px 0px rgba(164, 179, 190, 0.25)",
  },

  tourSearchMobileInfoWrapper: {
    display: "flex",
    border: "1px solid",
    borderRadius: "8px",
    padding: "8px 12px",
    alignItems: "center",
    borderColor: "content.grey-200",
    justifyContent: "space-between",
    backgroundColor: "background.default",
  },
};
