export const styles = {
  wrapper: {
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  },

  buttons: {
    gap: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  filter: {
    display: "flex",
    fontSize: "14px",
    cursor: "pointer",
    padding: "13px 0",
    color: "content.primary",
  },
};
