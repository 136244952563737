export const styles = {
  list: {
    padding: 0,
    rowGap: "8px",
    display: "flex",
    listStyle: "none",
    flexDirection: "column",
    color: "content.secondary",
  },

  listItem: {
    padding: 0,
    display: "flex",
    columnGap: "8px",
    lineHeight: "24px",
    alignItems: "center",

    "&::before": {
      width: "24px",
      height: "24px",
      alignSelf: "flex-start",
      content: "url(/icons/common/check.svg)",
    },
  },
};
