import { FC, memo, useCallback } from "react";

import { FlightLand } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { ArrivalCountries } from "../arrival-countries";
import { ArrivalRegions } from "../arrival-regions";
import { styles } from "./styles";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { mainOnboarding } from "@/shared/model/onboardings/main-onboarding";
import {
  $isError,
  $regions,
  $arrival,
  resetArrival,
  resetArrivalError,
  openArrivalContent,
  closeArrivalContent,
  $isArrivalContentOpen,
  $arrivalFilterByCountry,
  setArrivalFilterByCountry,
} from "@/shared/model/store/arrival";
import { $departure, setDepartureError } from "@/shared/model/store/departure";
import { Input, Popover, InputLoader } from "@/shared/ui";

const gridSectionWithBorder = {
  ...styles.section,
  ...styles.sectionBorder,
};

const gridStyles = { ...styles.contentStyles, ...styles.mainGrid };

type TTriggerProps = {
  open: boolean;
  isLoading: boolean;
  onClick: () => void;
};

type TProps = {
  isLoading: boolean;
  fullInputWidth?: boolean;
};

const Trigger: FC<TTriggerProps> = memo(
  ({ open, onClick, isLoading = false }) => {
    const t = useTranslations();
    const [
      isError,
      arrival,
      arrivalRegions,
      departureCode,
      filter,
      resetArrivalFn,
      setArrivalFilterByCountryFn,
      setDepartureErrorFn,
      resetArrivalErrorFn,
    ] = useUnit([
      $isError,
      $arrival,
      $regions,
      $departure,
      $arrivalFilterByCountry,
      resetArrival,
      setArrivalFilterByCountry,
      setDepartureError,
      resetArrivalError,
    ]);

    // TODO: normalize data
    const regionsNames = arrival?.locations
      .filter((item) => arrivalRegions?.includes(item.id))
      .map((item) => item.name)
      .join(", ");

    const handleChange = useCallback((value: string) => {
      // TODO: add debounce func
      if (!value) {
        resetArrivalFn();
      }

      setArrivalFilterByCountryFn(value);
    }, []);

    const handleClick = useCallback(() => {
      if (!departureCode) {
        setDepartureErrorFn();
        return;
      }

      if (isError) {
        resetArrivalErrorFn();
      }

      onClick();
    }, [
      departureCode,
      isError,
      onClick,
      resetArrivalErrorFn,
      setDepartureErrorFn,
    ]);

    return (
      <Input
        focused={open}
        disabled={isLoading}
        onClick={handleClick}
        onChange={handleChange}
        id="arrival_field_test"
        placeholder={t("WHERE_TO")}
        wrapperId="arrival-onboarding"
        startAdornment={<FlightLand />}
        isError={isError || !arrivalRegions}
        sxWrapper={styles.inputWrapperStyles}
        showPopper={isError || !arrivalRegions}
        endAdornment={<InputLoader isLoading={isLoading} />}
        value={filter || regionsNames || arrival?.name || ""}
        popperContent={t(
          !arrivalRegions
            ? "SELECT_AT_LEAST_ONE_REGION"
            : "FIRST_SELECT_DESTINATION",
        )}
      />
    );
  },
);

export const DesktopArrival: FC<TProps> = memo(
  ({ isLoading, fullInputWidth }) => {
    const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

    const [isContentOpen, closeContentFn, openContentFn] = useUnit([
      $isArrivalContentOpen,
      closeArrivalContent,
      openArrivalContent,
    ]);

    const handleOpenContent = useCallback(() => {
      sendDataLayerEventFn({
        event: "navTourmenuClick",
        data: {
          clickText: "Куда",
        },
      });

      if (mainOnboarding.isActive()) {
        mainOnboarding.show("arrival-modal");
      }

      openContentFn();
    }, [openContentFn, sendDataLayerEventFn]);

    const handleCloseContent = useCallback(() => {
      if (!mainOnboarding.isActive()) {
        closeContentFn();
      }
    }, [closeContentFn]);

    return (
      <>
        <Popover
          boxPosition="static"
          open={isContentOpen}
          fullInputWidth={fullInputWidth}
          closePopover={handleCloseContent}
          popoverStyles={styles.popoverStyles}
          trigger={
            <Trigger
              open={isContentOpen}
              isLoading={isLoading}
              onClick={handleOpenContent}
            />
          }
        >
          <Box sx={gridStyles}>
            <ArrivalCountries isLoading={isLoading} />
            <ArrivalRegions
              isPhone={false}
              isLoading={isLoading}
              onClose={handleCloseContent}
              sectionStyles={gridSectionWithBorder}
            />
          </Box>
        </Popover>
      </>
    );
  },
);
