import { FC, memo, useCallback } from "react";

import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { useGetCalendarTexts } from "@/shared/lib/hooks";
import { $arrival, setArrivalError } from "@/shared/model/store/arrival";
import {
  $endDate,
  $startDate,
  $hoveredDate,
  $isCalendarError,
  $isCalendarLoading,
  resetCalendarError,
} from "@/shared/model/store/calendar";
import { $departure, setDepartureError } from "@/shared/model/store/departure";
import { Input, InputLoader } from "@/shared/ui";

import CalendarIcon from "@/public/icons/common/calendar.svg";

type TProps = {
  open: boolean;
  onClick: () => void;
};

export const Trigger: FC<TProps> = memo(({ open, onClick }) => {
  const t = useTranslations();

  const [
    isError,
    arrivalCode,
    departureCode,
    endDate,
    startDate,
    hoveredDate,
    isLoading,
  ] = useUnit([
    $isCalendarError,
    $arrival,
    $departure,
    $endDate,
    $startDate,
    $hoveredDate,
    $isCalendarLoading,
  ]);

  const currentlyDisplayedEndDate = hoveredDate ?? endDate;

  const { combinedText } = useGetCalendarTexts({
    startDate,
    isInput: true,
    endDate: currentlyDisplayedEndDate,
  });

  const handleClick = useCallback(() => {
    if (!departureCode) {
      setDepartureError();
      return;
    }

    if (!arrivalCode) {
      setArrivalError();
      return;
    }

    if (isError) {
      resetCalendarError();
    }

    onClick();
  }, [arrivalCode, departureCode, isError, onClick]);

  return (
    <Input
      readOnly
      focused={open}
      isError={isError}
      showPopper={isError}
      disabled={isLoading}
      value={combinedText}
      onClick={handleClick}
      placeholder={t("DATE")}
      id="calendar_field_test"
      wrapperId="calendar-onboarding"
      popperContent={t("SELECT_TOUR_DATES")}
      endAdornment={<InputLoader isLoading={isLoading} />}
      startAdornment={<CalendarIcon width="24px" height="23px" />}
    />
  );
});
