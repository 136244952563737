import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  sectionBorder: {
    borderLeft: "1px solid",
    borderColor: "content.grey-100",
  },

  inputWrapperStyles: {
    [mainTheme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  popoverStyles: {
    width: "70%",

    [mainTheme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  mainGrid: {
    width: "100%",
    height: "420px",
    display: "grid",
    overflow: "hidden",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },

  contentStyles: {
    marginTop: "12px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },

  section: {
    display: "flex",
    flexDirection: "column",
    [mainTheme.breakpoints.down("smd")]: {
      border: "none",
    },
    [mainTheme.breakpoints.up("smd")]: {
      overflow: "hidden",
    },
    "&[disabled]": {
      userSelect: "none",
      pointerEvents: "none",
      color: "content.grey-200",
    },
  },
};
