// TODO: merge component with MobileArrivalHotels (DRY)
import { FC, memo, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { HotelsSearchGate } from "../../model";
import { HotelCard } from "../hotel-card";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { resetSelectedFilters } from "@/shared/model/store/filters";
import {
  $filteredTours,
  $searchHotelsQuery,
  setSearchHotelsQuery,
  resetSearchHotelsQuery,
} from "@/shared/model/store/search";
import { List, Popover, SearchInput } from "@/shared/ui";

export const ArrivalHotels: FC = memo(() => {
  const t = useTranslations();
  const [open, setOpen] = useState(false);

  useGate(HotelsSearchGate);

  const [
    filteredTours,
    searchHotelsQuery,

    resetSearchHotelsQueryFn,

    setSearchHotelsQueryFn,
    resetSelectedFiltersFn,
  ] = useUnit([
    $filteredTours,
    $searchHotelsQuery,

    resetSearchHotelsQuery,

    setSearchHotelsQuery,
    resetSelectedFilters,
  ]);

  const handlePopoverClose = () => setOpen(false);
  const handlePopoverOpen = () => setOpen(true);
  const handleSearchClear = () => {
    resetSelectedFiltersFn();
    resetSearchHotelsQueryFn();
  };

  return (
    <Popover
      open={open}
      closePopover={handlePopoverClose}
      popoverStyles={styles.popoverStyles}
      trigger={
        <SearchInput
          showClearIcon
          sxInput={null}
          disabled={false}
          buttonProps={{}}
          searchButtonText={""}
          showSearchButton={false}
          value={searchHotelsQuery}
          onClear={handleSearchClear}
          onClick={handlePopoverOpen}
          placeholder={t("FIND_HOTEL")}
          onChange={setSearchHotelsQueryFn}
        />
      }
    >
      <Condition match={!!searchHotelsQuery}>
        <List as="div" sx={styles.wrapper}>
          <Condition
            match={Boolean(
              Number(filteredTours?.data?.length) > 0 && searchHotelsQuery,
            )}
          >
            {filteredTours?.data?.map((tour) => (
              <HotelCard
                hotel={tour.hotel}
                key={`hotel-${tour.hotel.id}`}
                onClick={setSearchHotelsQueryFn}
              />
            ))}
          </Condition>

          <Condition match={!filteredTours?.data?.length}>
            <Box>
              <Typography component="p" sx={styles.notFound}>
                {t("TRY_ENTERING_ANOTHER_NAME")}
              </Typography>

              <Button
                variant="text"
                sx={styles.notFound}
                onClick={handleSearchClear}
              >
                {t("RESET")}
              </Button>
            </Box>
          </Condition>
        </List>
      </Condition>
    </Popover>
  );
});
