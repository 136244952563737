import { FC, memo } from "react";

import { useGate, useUnit } from "effector-react";

import { DepartureDataGate } from "../../model";
import { DesktopDeparture } from "../DesktopDeparture";
import { MobileDeparture } from "../MobileDeparture";

import { useSearchBreakpoints } from "@/shared/lib/hooks";
import { getDepartureDataQuery } from "@/shared/model/api/search-form";
import { $searchType } from "@/shared/model/store/common";

export const Departure: FC = memo(() => {
  useGate(DepartureDataGate);

  const searchType = useUnit($searchType);
  const { pending: isLoading } = useUnit(getDepartureDataQuery);

  const { isPhone } = useSearchBreakpoints({ type: searchType });

  if (isPhone) {
    return <MobileDeparture isLoading={isLoading} />;
  }

  return <DesktopDeparture isLoading={isLoading} />;
});
