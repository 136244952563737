import { FC } from "react";

import PlaceIcon from "@mui/icons-material/Place";
import { Box, Slide, Button, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { toggleTabletMap, $isTabletMapOpen } from "@/shared/model/store/common";

import ListIcon from "@/public/icons/common/list.svg";

export const TabletMapButton: FC = () => {
  const t = useTranslations();

  const [isTabletMapOpen, toggleTabletMapFn] = useUnit([
    $isTabletMapOpen,
    toggleTabletMap,
  ]);

  return (
    <Slide in mountOnEnter unmountOnExit direction="up">
      <Box sx={styles.buttonBox}>
        <Button
          variant="contained"
          sx={styles.mapButton}
          onClick={toggleTabletMapFn}
        >
          {isTabletMapOpen ? <ListIcon /> : <PlaceIcon htmlColor="white" />}

          <Typography sx={styles.tabletButtonText}>
            {t(isTabletMapOpen ? "LIST_VIEW" : "ON_MAP")}
          </Typography>
        </Button>
      </Box>
    </Slide>
  );
};
