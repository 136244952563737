export const MIN_CHILDREN = 0;
export const MAX_CHILDREN = 4;
export const MIN_ADULTS = 1;
export const MAX_ADULTS = 8;

export const MAX_AGE_OF_INFANT = 2;
export const MAX_CHILD_AGE = 16;

export enum ErrorTypes {
  Infants = "infants",
  Quantity = "quantity",
  ChildrenAges = "childrenAges",
}

export const TOOLTIP_CHILD_AGE_TEXT =
  "MAKE_SURE_YOU_HAVE_ENTERED_THE_CORRECT_CHILD_AGE";
export const TOOLTIP_MAX_QUANTITY_TEXT =
  "CONTACT_AN_OFFICE_TO_INCREASE_THE_NUMBER_OF_TOURISTS";
export const TOOLTIP_INFANTS_TEXT =
  "FOR_EACH_CHILD_UNDER_YEARS_THERE_MUST_BE_AN_ADULT";
