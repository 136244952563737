import Image from "next/image";
import { FC } from "react";

import { Box, SxProps, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

type TProps = {
  textSx?: SxProps;
  imageSrc: string;
  imageWrapperSx?: SxProps;
};

export const QrBlock: FC<TProps> = ({
  imageSrc,
  textSx = {},
  imageWrapperSx = {},
}) => {
  const t = useTranslations();
  const imageWrapperStyles = {
    ...styles.qrImageWrapper,
    ...imageWrapperSx,
  };

  return (
    <>
      <Box sx={imageWrapperStyles}>
        <Image
          width={179}
          height={175}
          src={imageSrc}
          alt={t("FOR_APP_DOWNLOAD")}
        />
      </Box>
      <Typography sx={{ ...styles.text, ...textSx } as SxProps}>
        {t("SCAN_QR_CODE_DOWNLOAD_APP")}
      </Typography>
    </>
  );
};
