import { combine, createApi, createStore } from "effector";
import { produce } from "immer";

import {
  getInitialSearchQuery,
  getActualizedSearchQuery,
} from "@/shared/model/api/search";

export const $selectedFilters = createStore<Record<string, string[]>>({});

export const { resetSelectedFilters, toggleSelectedFilters } = createApi(
  $selectedFilters,
  {
    resetSelectedFilters: () => ({}),
    toggleSelectedFilters: (state, payload: { id: string; code: string }) => {
      return produce(state, (draft) => {
        const { id, code } = payload;

        if (!draft[code]) {
          draft[code] = [];
        }

        const index = draft[code].indexOf(id);

        if (index > -1) {
          draft[code].splice(index, 1);

          if (draft[code].length === 0) {
            delete draft[code];
          }
        } else {
          draft[code].push(id);
        }
      });
    },
  },
);

export const $filtersList = combine(
  getInitialSearchQuery.$data,
  getActualizedSearchQuery.$data,
  (initData, actualizedData) => {
    if (initData?.meta?.filters?.length === 0) {
      return actualizedData?.meta?.filters ?? [];
    }

    return initData?.meta?.filters ?? [];
  },
);
