export const styles = {
  paper: {
    height: "auto",
  },

  formControl: {
    width: "100%",
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },

  filter: {
    display: "flex",
    fontSize: "14px",
    cursor: "pointer",
    padding: "13px 0",
    color: "content.primary",
  },
};
