import { FC, memo } from "react";

import { Box, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useGate, useUnit } from "effector-react";

import { DatePickerGate } from "../../model/worker";
import { DatepickerPane } from "./DatepickerPane";
import { datepickerStyles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import { $monthDays, $currentDate } from "@/shared/model/store/calendar";

export const DesktopDatePickerPaneWrapper: FC = memo(() => {
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useGate(DatePickerGate, "desktop");

  const [monthDays, currentDate] = useUnit([$monthDays, $currentDate]);

  return (
    <>
      <DatepickerPane
        leftArrow
        date={dayjs(currentDate)}
        days={monthDays.currentMonthDays}
        rightArrow={isDesktop ? false : true}
      />
      <Condition match={isDesktop}>
        <Box sx={datepickerStyles.leftMonthBox}>
          <DatepickerPane
            rightArrow
            days={monthDays.nextMonthDays}
            date={dayjs(currentDate).add(1, "month")}
          />
        </Box>
      </Condition>
    </>
  );
});
