import { FC } from "react";

import { Box } from "@mui/material";

import { LIST_OF_CHIPS_CODES } from "../../../model/constants";
import { styles } from "./styles";

type TProps = {
  code: string;
  isDesktop: boolean;
  children: JSX.Element;
};

export const FilterCheckboxesOrChipsWrapper: FC<TProps> = ({
  code,
  children,
  isDesktop,
}) => {
  return (
    <Box
      sx={{
        ...styles.controlLabelList(
          isDesktop,
          LIST_OF_CHIPS_CODES.includes(code),
        ),
      }}
    >
      {children}
    </Box>
  );
};
