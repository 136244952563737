import { mainTheme } from "shared/ui/styles";

export const styles = {
  nights: {
    fontWeight: 500,
    marginLeft: "8px",
    color: "content.primary",
  },

  footerText: {
    fontSize: "16px",
    textAlign: "center",
    color: "content.secondary",

    [mainTheme.breakpoints.down("smd")]: {
      mb: "16px",
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
};
