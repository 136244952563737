/* eslint-disable perfectionist/sort-objects */
import dayjs from "dayjs";
import { sample, createEvent, createEffect } from "effector";
import { createGate } from "effector-react";
import Cookies from "js-cookie";
import { combineEvents } from "patronum";
import Shepherd from "shepherd.js";

import {
  $defaultDeparture,
  $previousDefaultDeparture,
} from "../store/default-departure";

import { openModal } from "@/widgets/global-modals";

import { IS_PROD_ENV } from "@/shared/config";
import {
  SERVER_DATE_FORMAT,
  MAIN_ONBOARDING_COOKIE,
  DEFAULT_AMOUNT_OF_NIGHTS,
} from "@/shared/lib/constants";
import { getTranslateFn } from "@/shared/lib/helpers/locales";
import {
  goToNextStep,
  commonStepsConfig,
  createTooltipContent,
  setupProgressIndicator,
} from "@/shared/lib/helpers/onboardings";
import {
  getNightsQuery,
  getArrivalDataQuery,
  getAvailableCalendarDatesQuery,
} from "@/shared/model/api/search-form";
import {
  $arrival,
  setArrival,
  openArrivalContent,
  closeArrivalContent,
} from "@/shared/model/store/arrival";
import {
  $endDate,
  setNights,
  $startDate,
  setEndDate,
  setStartDate,
  openCalendarContent,
  closeCalendarContent,
} from "@/shared/model/store/calendar";
import {
  $children,
  $childrenAges,
  setChildrenAges,
  openQuantityContent,
  closeQuantityContent,
} from "@/shared/model/store/quantity";

const startOnboardingLogic = createEvent();
export const HomePageSearchMounted = createGate<{
  isDesktop: boolean;
}>();

const startOnboardingFx = createEffect(async () => {
  if (!mainOnboarding) return;

  const t = await getTranslateFn();
  mainOnboarding.addSteps(getSteps(t)).start();

  ["complete", "cancel"].forEach((event) =>
    mainOnboarding.once(event, () => {
      Cookies.set(MAIN_ONBOARDING_COOKIE, "true", { expires: 15 });

      openModal();
    }),
  );
});

sample({
  clock: HomePageSearchMounted.open,
  target: startOnboardingLogic,
});

sample({
  clock: startOnboardingLogic,
  source: {
    defaultDeparture: $defaultDeparture,
    isDesktop: HomePageSearchMounted.state.map(({ isDesktop }) => isDesktop),
  },
  filter: ({ isDesktop, defaultDeparture }) =>
    IS_PROD_ENV &&
    defaultDeparture !== null &&
    isDesktop &&
    Cookies.get(MAIN_ONBOARDING_COOKIE) !== "true" &&
    !mainOnboarding.isActive(),
  target: startOnboardingFx,
});

sample({
  clock: startOnboardingLogic,
  source: HomePageSearchMounted.state,
  filter: ({ isDesktop }) =>
    !IS_PROD_ENV ||
    !isDesktop ||
    Cookies.get(MAIN_ONBOARDING_COOKIE) === "true",
  target: openModal,
});

sample({
  clock: $defaultDeparture.updates,
  source: {
    previousDeparture: $previousDefaultDeparture,
    homePageSearchMounted: HomePageSearchMounted.status,
  },
  filter: ({ previousDeparture, homePageSearchMounted }) =>
    homePageSearchMounted && previousDeparture === null,
  target: startOnboardingLogic,
});

const autoSetArrival = createEvent();
const autoSetStartDate = createEvent();
const autoSetEndDataAndNights = combineEvents([
  getNightsQuery.finished.success,
  autoSetStartDate,
]);
const autoSetQuantity = createEvent();

sample({
  clock: autoSetArrival,
  source: getArrivalDataQuery.$data,
  filter: $arrival.map((arrival) => !arrival),
  fn: (data) => data?.data[0] || null,
  target: setArrival,
});

sample({
  clock: autoSetStartDate,
  source: getAvailableCalendarDatesQuery.$data,
  filter: $startDate.map((startDate) => !startDate),
  fn: (data) => data?.[0]?.date ?? "",
  target: setStartDate,
});

sample({
  clock: autoSetEndDataAndNights,
  source: {
    startDate: $startDate,
    nightsData: getNightsQuery.$data,
  },
  filter: $endDate.map((endDate) => !endDate),
  fn: ({ startDate, nightsData }) => {
    return dayjs(startDate)
      .add(nightsData?.nights?.[0] || DEFAULT_AMOUNT_OF_NIGHTS, "day")
      .format(SERVER_DATE_FORMAT);
  },
  target: setEndDate,
});

sample({
  clock: autoSetEndDataAndNights,
  source: {
    startDate: $startDate,
    endDate: $endDate,
  },
  filter: $endDate.map((endDate) => !endDate),
  fn: ({ startDate, endDate }) => {
    return Math.abs(dayjs(startDate).diff(dayjs(endDate), "day"));
  },
  target: setNights,
});

sample({
  clock: autoSetQuantity,
  source: {
    children: $children,
    childrenAges: $childrenAges,
  },
  filter: ({ children, childrenAges }) =>
    children !== 0 && childrenAges.length !== 0,
  fn: ({ children, childrenAges }) =>
    Array.from({ length: children }).map((_, index) => {
      return childrenAges[index] || 0;
    }),
  target: setChildrenAges,
});

const getSteps = (t: (string: string) => string) => [
  {
    id: "arrival",
    title: t("SELECT_COUNTRY_TO_FLY_TO"),
    attachTo: { on: "bottom", element: "#arrival-onboarding" },
    when: {
      hide: openArrivalContent,
      show: setupProgressIndicator,
    },
    text: () =>
      createTooltipContent(
        t("CLICK_SELECTED_AREA_TO_VIEW_DEPARTURE_COUNTRY_OPTIONS"),
        "/img/onboarding/arrival-onboarding.webp",
      ),
    buttons: [
      {
        type: "next",
        text: t("NEXT"),
        classes: "shepherd-button-primary shepherd-button-next",
        action: (event: Event) => {
          void goToNextStep(event, mainOnboarding);
        },
      },
    ],
    ...commonStepsConfig,
  },
  {
    id: "arrival-modal",
    title: t("SELECT_COUNTRY_FROM_LIST"),
    text: t("SELECT_COUNTRY_TO_FLY_TO"),
    attachTo: { on: "top", element: "#arrival-modal-onboarding" },
    when: {
      show: setupProgressIndicator,
    },
    buttons: [
      {
        type: "next",
        text: t("NEXT"),
        action: (event: Event) => {
          autoSetArrival();
          void goToNextStep(event, mainOnboarding);
        },
        classes: "shepherd-button-primary",
      },
    ],
    ...commonStepsConfig,
  },
  {
    title: t("OPTIONALLY_SELECT_REGION"),
    id: "arrival-modal-regions",
    text: t(
      "NOW_YOU_CAN_MORE_ACCURATELY_CHOOSE_DIRECTION_BY_SELECTING_ONE_OR_SEVERAL_REGIONS",
    ),
    attachTo: { on: "top", element: "#arrival-modal-regions-onboarding" },
    when: {
      hide: closeArrivalContent,
      show: setupProgressIndicator,
    },
    buttons: [
      {
        type: "next",
        text: t("NEXT"),
        classes: "shepherd-button-primary",
        action: (event: Event) => void goToNextStep(event, mainOnboarding),
      },
    ],
    ...commonStepsConfig,
  },
  {
    id: "calendar",
    title: t("SELECT_TOUR_DATES"),
    attachTo: { on: "top", element: "#calendar-onboarding" },
    when: {
      show: setupProgressIndicator,
      hide: openCalendarContent,
    },
    text: () =>
      createTooltipContent(
        t("SELECT_INTERESTING_DATES_FROM_AVAILABLE_ONES_IN_CALENDAR"),
        "/img/onboarding/calendar-onboarding.webp",
      ),
    buttons: [
      {
        type: "next",
        text: t("NEXT"),
        classes: "shepherd-button-primary",
        action: (event: Event) => void goToNextStep(event, mainOnboarding),
      },
    ],
    ...commonStepsConfig,
  },
  {
    id: "calendar-modal",
    title: t("CHOOSE_DATES"),
    attachTo: { on: "top", element: "#calendar-modal-onboarding" },
    when: {
      hide: closeCalendarContent,
      show: setupProgressIndicator,
    },
    text: t("SPECIFY_TOUR_DATE_RANGE_SELECT_DEPARTURE_DATE_AND_RETURN_DATE"),
    buttons: [
      {
        type: "next",
        text: t("NEXT"),
        classes: "shepherd-button-primary",
        action: (event: Event) => {
          autoSetStartDate();
          void goToNextStep(event, mainOnboarding);
        },
      },
    ],
    ...commonStepsConfig,
  },
  {
    id: "quantity",
    title: t("SPECIFY_NUMBER_OF_TOURISTS"),
    attachTo: { on: "bottom", element: "#quantity-onboarding" },
    when: {
      hide: openQuantityContent,
      show: setupProgressIndicator,
    },
    text: () =>
      createTooltipContent(
        t("YOU_CAN_CHOOSE_UP_TO_ADULTS_AND_UP_TO_CHILDREN"),
        "/img/onboarding/quantity-onboarding.webp",
      ),
    buttons: [
      {
        type: "next",
        text: t("NEXT"),
        classes: "shepherd-button-primary",
        action: (event: Event) => void goToNextStep(event, mainOnboarding),
      },
    ],
    ...commonStepsConfig,
  },
  {
    id: "quantity-modal",
    title: t("SPECIFY_NUMBER_OF_TOURISTS"),
    attachTo: { on: "top", element: "#quantity-modal-onboarding" },
    when: {
      hide: closeQuantityContent,
      show: setupProgressIndicator,
    },
    text: t("USE_PLUS_AND_MINUS_BUTTONS_TO_SPECIFY_NUMBER_OF_TOURISTS"),
    buttons: [
      {
        type: "next",
        text: t("NEXT"),
        classes: "shepherd-button-primary",
        action: (event: Event) => {
          autoSetQuantity();
          void goToNextStep(event, mainOnboarding);
        },
      },
    ],
    ...commonStepsConfig,
  },
  {
    id: "search-button",
    title: t("REST_IS_ON_US"),
    when: {
      show: setupProgressIndicator,
    },
    attachTo: { on: "bottom", element: "#main_page_search_btn_test" },
    text: () =>
      createTooltipContent(
        t("FIND_AND_WE_WILL_FIND_OPTIONS_FOR_YOUR_REQUEST"),
        "/img/onboarding/search-onboarding.webp",
      ),
    ...commonStepsConfig,
  },
];

export const mainOnboarding = new Shepherd.Tour({
  exitOnEsc: true,
  useModalOverlay: true,
  keyboardNavigation: false,
  defaultStepOptions: {
    classes: "custom-onboarding",
    cancelIcon: {
      enabled: true,
    },
  },
});
