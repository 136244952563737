import { useSearchParams } from "next/navigation";
import { FC, useEffect } from "react";

import { Box, SxProps, Typography } from "@mui/material";
import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { SEARCH_BUTTON_ID_ON_HOTEL } from "../../lib";
import { SearchGate, initSearch } from "../../model";
import { StyledWrapper } from "../styled-wrapper";
import { styles } from "./styles";

import { TourSearchButton } from "@/features/search";

import {
  Quantity,
  Calendar,
  Departure,
  TourSearchMobileInfo,
} from "@/entities/search";

import { getSearchParamsString } from "@/shared/lib/helpers/router/getSearchParamsString";
import { useSearchBreakpoints } from "@/shared/lib/hooks";
import {
  openMobileSearchDrawer,
  closeMobileSearchDrawer,
  $isMobileSearchDrawerOpen,
} from "@/shared/model/store/common";
import { ESearchType, TQuerySearchData } from "@/shared/model/types/search";
import { SwipeableDrawer } from "@/shared/ui";

export const HotelPageSearch: FC = () => {
  const { isPhone } = useSearchBreakpoints({ type: ESearchType.Hotel });
  const initSearchFn = useUnit(initSearch);
  const searchParams = useSearchParams();
  const t = useTranslations();

  useGate(SearchGate, ESearchType.Hotel);

  const [
    isMobileSearchDrawerOpen,
    openMobileSearchDrawerFn,
    closeMobileSearchDrawerFn,
  ] = useUnit([
    $isMobileSearchDrawerOpen,
    openMobileSearchDrawer,
    closeMobileSearchDrawer,
  ]);

  // TODO: nextjs issue
  useEffect(() => {
    initSearchFn();
  }, [initSearchFn, searchParams]);

  const handleSearch = (queryData: TQuerySearchData) => {
    const query = getSearchParamsString(queryData);

    // to prevent triggering search form requests twice
    window.history.pushState(null, "", `?${query}`);
    initSearchFn();

    if (isPhone) {
      closeMobileSearchDrawerFn();
    }
  };

  if (isPhone) {
    return (
      <>
        <Box key="grid" sx={styles.mobileWrapper as SxProps}>
          <TourSearchMobileInfo
            onClick={openMobileSearchDrawerFn}
            sx={styles.tourSearchMobileInfoWrapper}
          />
        </Box>

        <SwipeableDrawer
          keepMounted={false}
          title={t("TOUR_SELECTION")}
          isOpen={isMobileSearchDrawerOpen}
          onOpen={openMobileSearchDrawerFn}
          onClose={closeMobileSearchDrawerFn}
          childrenWrapperSx={styles.mobileDrawerContentWrapper}
          Footer={
            <TourSearchButton
              buttonSx={{}}
              onClick={handleSearch}
              id={SEARCH_BUTTON_ID_ON_HOTEL}
            />
          }
        >
          <Box sx={styles.mobileDrawerContent}>
            <Box sx={styles.inputsWrapper}>
              <StyledWrapper left right>
                <Departure />
              </StyledWrapper>
            </Box>
            <Box sx={styles.inputsWrapper}>
              <StyledWrapper left>
                <Calendar />
              </StyledWrapper>

              <StyledWrapper right>
                <Quantity />
              </StyledWrapper>
            </Box>
          </Box>
        </SwipeableDrawer>
      </>
    );
  }

  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.filterHeader}>
        {t("AVAILABLE_TOURS_IN_THIS_HOTEL")}
      </Typography>

      <Box sx={styles.filterWrapper}>
        <Box sx={styles.desktopFilter as SxProps}>
          <Calendar />
          <Quantity />
          <Departure />
        </Box>
        <TourSearchButton
          onClick={handleSearch}
          id={SEARCH_BUTTON_ID_ON_HOTEL}
        />
      </Box>
    </Box>
  );
};
