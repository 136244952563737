import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  header: {
    padding: "24px 0px 16px 0px",
  },

  headerTitle: {
    fontWeight: 500,
    fontSize: "18px",
  },

  calendarWrapper: {
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderColor: "accent.brand.lightest",
  },

  footerText: {
    fontSize: "16px",
    textAlign: "center",
    color: "content.secondary",

    [mainTheme.breakpoints.down("smd")]: {
      fontSize: "18px",
      lineHeight: "22px",
      marginBottom: "16px",
    },
  },

  wrapper: {
    marginTop: "12px",
    overflow: "hidden",
    borderRadius: "8px",
    border: "1px solid",
    padding: "0px 24px",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",

    [mainTheme.breakpoints.down("smd")]: {
      border: "none",
      padding: "0px",
      borderRadius: 0,
      margin: "0 -16px",
    },
  },

  footer: {
    display: "flex",
    alignItems: "center",
    padding: "16px 0px 24px 0px",
    justifyContent: "space-between",

    nights: {
      fontWeight: 500,
      marginLeft: "8px",
      color: "content.primary",
    },

    [mainTheme.breakpoints.down("smd")]: {
      width: "100%",
      borderTop: "1px solid",
      flexDirection: "column",
      padding: "16px 16px 36px 16px",
      borderColor: "content.grey-100",
      backgroundColor: "dominant.white",
    },
  },
};

export const defaultPopoverStyles = {
  left: "50%",
  right: "unset",
  width: "max-content",
  transform: "translateX(-50%)",
};

export const tourDetailsStyles = {
  width: "max-content",
};
