import { mainTheme } from "shared/ui/styles";

export const styles = {
  arrivalRegionText: {
    padding: "9px 16px",
  },

  button: {
    [mainTheme.breakpoints.down("smd")]: {
      display: "block",
    },
  },

  checkboxLabel: {
    margin: 0,
    columnGap: "4px",
    color: "content.secondary",
  },
  title: {
    fontWeight: 500,
    lineHeight: "24px",
    [mainTheme.breakpoints.down("smd")]: {
      fontSize: "20px",
    },
  },

  sectionHeader: {
    padding: "16px",

    [mainTheme.breakpoints.down("smd")]: {
      gap: "10px",
      display: "flex",
      fontSize: "20px",
      fontWeight: "500",
      alignItems: "center",
      lineHeight: "23.2px",
      color: "content.primary",
    },
  },
  regionFooter: {
    display: "flex",
    marginTop: "auto",
    padding: "7px 16px",
    alignItems: "center",
    borderTop: "1px solid",
    justifyContent: "flex-end",
    borderColor: "content.grey-100",

    [mainTheme.breakpoints.down("smd")]: {
      bottom: 0,
      width: "100%",
      padding: "16px",
      display: "flex",
      position: "fixed",
      justifyContent: "space-between",
      backgroundColor: "background.default",
    },
  },

  list: {
    overflowY: "auto",
    overflowX: "hidden",
    padding: "2px 16px 16px",
    overscrollBehavior: "contain",
    ...mainTheme.scrollbars.default,

    [mainTheme.breakpoints.down("smd")]: {
      padding: "0 16px 16px",
    },
    ".Mui-disabled": {
      "&:hover": {
        opacity: "1",
      },
    },

    region: {
      [mainTheme.breakpoints.down("smd")]: {
        marginBottom: "75px",
      },
    },

    ".MuiFormControlLabel-root": {
      transition: "0.2s",
      "&:hover": {
        opacity: "0.7",
      },
    },
  },
};
