/* eslint-disable perfectionist/sort-objects */
import { sample, combine } from "effector";

import { getArrivalDataQuery } from "@/shared/model/api/search-form";
import {
  $arrival,
  resetArrival,
  $arrivalFilterByRegion,
  $arrivalFilterByCountry,
  resetArrivalFilterByRegion,
  resetArrivalFilterByCountry,
} from "@/shared/model/store/arrival";
import { $searchType } from "@/shared/model/store/common";
import { setDeparture, resetDeparture } from "@/shared/model/store/departure";
import { ESearchType } from "@/shared/model/types/search";

sample({
  clock: [resetDeparture, setDeparture],
  filter: Boolean,
  fn: (departure) => departure?.id,
  target: [
    getArrivalDataQuery.refresh,
    resetArrivalFilterByCountry,
    resetArrivalFilterByRegion,
  ],
});

sample({
  clock: [resetDeparture, setDeparture],
  source: $searchType,
  filter: (searchType, departure) =>
    searchType !== ESearchType.Hotel && !!departure,
  target: resetArrival,
});

export const $filteredArrivalsByCountry = combine(
  getArrivalDataQuery.$data,
  $arrivalFilterByCountry,
  (arrivals, filter) => {
    return arrivals?.data?.filter((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase()),
    );
  },
  {
    skipVoid: false,
  },
);

export const $filteredArrivalsByRegion = combine(
  $arrival,
  $arrivalFilterByRegion,
  (arrival, filter) => {
    return arrival?.locations?.filter((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase()),
    );
  },
  {
    skipVoid: false,
  },
);
