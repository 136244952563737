export const styles = {
  wrapper: (left: boolean, right: boolean) => ({
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    borderTopLeftRadius: left ? "8px" : 0,
    borderTopRightRadius: right ? "8px" : 0,
    borderBottomLeftRadius: left ? "8px" : 0,
    borderLeft: left ? "1px solid #E5E5E5" : 0,
    borderBottomRightRadius: right ? "8px" : 0,
  }),
};
