import { mainTheme } from "shared/ui/styles";

export const styles = {
  contentStyles: {
    marginTop: "12px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },

  list: {
    maxHeight: "325px",
    ...mainTheme.scrollbars.default,
    overflowY: "auto",
    overflowX: "hidden",

    [mainTheme.breakpoints.down("smd")]: {
      padding: 0,
      border: "none",
      maxHeight: "100%",
    },
  },
};
