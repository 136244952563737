import { FC, memo, useCallback } from "react";

import { Box, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { $filteredArrivalsByCountry } from "../../model";
import { ArrivalListContent } from "./arrival-list-content";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { mainOnboarding } from "@/shared/model/onboardings/main-onboarding";
import {
  $arrival,
  setArrival,
  setAllRegionsSelected,
  setArrivalRegionsStep,
  resetArrivalFilterByCountry,
} from "@/shared/model/store/arrival";
import { TArrivalLocation } from "@/shared/model/types/header-menu";
import { List } from "@/shared/ui";

type TProps = {
  isPhone?: boolean;
  isLoading?: boolean;
};

export const ArrivalCountries: FC<TProps> = memo(
  ({ isPhone = false, isLoading = false }) => {
    const t = useTranslations();

    const [
      arrivals,
      arrival,
      setArrivalFn,
      setAllRegionsSelectedFn,
      setArrivalRegionsStepFn,
      resetArrivalFilterByCountryFn,
    ] = useUnit([
      $filteredArrivalsByCountry,
      $arrival,
      setArrival,
      setAllRegionsSelected,
      setArrivalRegionsStep,
      resetArrivalFilterByCountry,
    ]);

    const handleItemClick = useCallback(
      (arrival: TArrivalLocation) => {
        if (!isPhone && mainOnboarding.isActive()) {
          mainOnboarding.show("arrival-modal-regions");
        }

        resetArrivalFilterByCountryFn();
        setArrivalFn(arrival);
        setAllRegionsSelectedFn();

        // TODO: refactor
        if (isPhone) {
          setArrivalRegionsStepFn();
        }
      },
      [
        isPhone,
        resetArrivalFilterByCountryFn,
        setAllRegionsSelectedFn,
        setArrivalFn,
        setArrivalRegionsStepFn,
      ],
    );

    return (
      <Box sx={styles.section} id="arrival-modal-onboarding">
        <Condition match={!isPhone}>
          <Box sx={styles.sectionHeader}>
            <Typography sx={styles.title}>{t("SELECT_COUNTRY")}</Typography>
          </Box>
        </Condition>

        <List as="div" sx={styles.list}>
          <ArrivalListContent
            arrival={arrival}
            arrivals={arrivals}
            isLoading={isLoading}
            onClick={handleItemClick}
          />
        </List>
      </Box>
    );
  },
);
