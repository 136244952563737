import { FC, memo } from "react";

import { Box, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useGetCalendarTexts } from "@/shared/lib/hooks";
import {
  $endDate,
  $startDate,
  $hoveredDate,
} from "@/shared/model/store/calendar";

export const FormattedDate: FC = memo(() => {
  const t = useTranslations();
  const [endDate, startDate, hoveredDate] = useUnit([
    $endDate,
    $startDate,
    $hoveredDate,
  ]);

  const currentlyDisplayedEndDate = hoveredDate ?? endDate;

  const { datesText, nightsText } = useGetCalendarTexts({
    startDate,
    endDate: currentlyDisplayedEndDate,
  });

  return (
    <Typography sx={styles.footerText}>
      {datesText || t("CHOOSE_DATES")}
      <Condition match={Boolean(nightsText)}>
        <Box component="span" sx={styles.nights}>
          ({nightsText})
        </Box>
      </Condition>
    </Typography>
  );
});
