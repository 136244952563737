//TODO: remove comments
import { memo } from "react";

import { ExpandMore } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import Box from "@mui/material/Box";

import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";
import { TCustomTheme } from "@/shared/ui/styles/theme/types";

type TArrowButton = {
  left?: boolean;
  expanded: boolean;
  onClick: () => void;
};

// TODO: FSD refactor

const mobileWeekdayStyles = {
  [mainTheme.breakpoints.down("smd")]: {
    fontSize: "12px",
    lineHeight: "14px",
    padding: "16px 0px",
  },
};

const mobileGridStyles = {
  [mainTheme.breakpoints.down("smd")]: {
    gridTemplateColumns: "repeat(7, 14.2%)",
  },
};

export const StyledDatepickerGrid = memo(
  styled(Box)(() => {
    return {
      gap: "4px 0px",
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      ...mobileGridStyles,
    };
  }),
);

export const StyledDatepickerWeekday = memo(
  styled(Box)(({ theme }) => {
    return {
      width: "48px",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16px",
      paddingBlock: "7px",
      textAlign: "center",
      textTransform: "uppercase",
      color: theme.palette.grey[200],
      ...mobileWeekdayStyles,
    };
  }),
);

const StyledArrowButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean; theme: TCustomTheme }>(({ theme, expanded }) => {
  return {
    border: "none",
    cursor: "pointer",
    background: "none",
    borderRadius: "4px",
    display: "inline-flex",
    padding: expanded ? "9px" : "7px",
    transition: "background 0.15s ease",
    "&:hover": {
      background: theme.palette.cloud,
    },
  };
});

// eslint-disable-next-line react/display-name
export const ArrowButton = memo(({ left, onClick, expanded }: TArrowButton) => {
  return (
    <StyledArrowButton
      type="button"
      theme={mainTheme}
      onClick={onClick}
      expanded={expanded}
    >
      <ExpandMore
        style={{
          width: expanded ? "14px" : "10px",
          height: expanded ? "14px" : "10px",
          transform: left ? "rotate(90deg)" : "rotate(-90deg)",
        }}
      />
    </StyledArrowButton>
  );
});

export const DatepickerDay = styled(Button)(() => {
  const { accent, content, dominant } = mainTheme.palette;
  return {
    padding: 0,
    border: "none",
    fontWeight: 400,
    outline: "none",
    fontSize: "14px",
    cursor: "pointer",
    minWidth: "unset",
    overflow: "hidden",
    userSelect: "none",
    lineHeight: "16px",
    background: "none",
    borderRadius: "8px",
    alignItems: "center",
    display: "inline-flex",
    color: content.primary,
    flexDirection: "column",
    justifyContent: "center",
    width: "max(100%, 48px)",
    height: "min(48px, 48px)",

    "&.rangeEnd, &.rangeStart": {
      borderRadius: "8px",
    },
    "&:disabled": {
      pointerEvents: "none",
      color: content["grey-200"],
    },

    "&.isLoading": {
      pointerEvents: "none",
      color: content["grey-200"],
    },
    "&.inRange": {
      borderRadius: 0,
      backgroundColor: accent.brand.lightest,
    },

    "&:hover": {
      opacity: "100",
      outline: `1px solid ${accent.brand.secondary}`,
    },

    "&:disabled&.inRange": {
      borderRadius: 0,
      backgroundColor: accent.brand.lightest,
    },

    "&.isToday&.isCurrentMonth": {
      fontWeight: 500,
      border: "1px solid transparent",
      borderColor: accent.brand.lightest,
    },

    "&:not(.isCurrentMonth), &:not(.isCurrentMonth)&:after": {
      content: "''",
      pointerEvents: "none",
      color: content["grey-200"],
      backgroundColor: "transparent !important",
    },

    "&.isActive": {
      position: "relative",
      color: dominant.white,
      backgroundColor: accent.brand.lightest,

      "&.rangeStart": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },

      "&.rangeEnd": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },

      "&:after": {
        width: "100%",
        content: "''",
        height: "100%",
        borderRadius: "8px",
        position: "absolute",
        backgroundColor: accent.brand.primary,
      },
    },
  };
});
