import { FC, useState } from "react";

import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { updateSort } from "@/widgets/search/search-page-content/model";

import { $sortList, $selectedSortValue } from "@/shared/model/store/sort";
import { ESortListValue } from "@/shared/model/types/sort";
import { CollapseButton, SwipeableDrawer } from "@/shared/ui";

export const MobileSort: FC = () => {
  const t = useTranslations();
  const [isMobileSortDrawer, setIsMobileSortDrawer] = useState(false);

  const [selectedSortValue, sortList, updateSortFn] = useUnit([
    $selectedSortValue,
    $sortList,
    updateSort,
  ]);

  const selectedSortLabel =
    sortList?.find((sort) => sort.value === selectedSortValue)?.label ?? "-";

  const handleSortChange = (value: ESortListValue) => {
    updateSortFn(value);
  };

  return (
    <>
      <CollapseButton
        variant="text"
        sx={styles.filter}
        isOpen={isMobileSortDrawer}
        onClick={() => setIsMobileSortDrawer(true)}
      >
        {t(selectedSortLabel)}
      </CollapseButton>

      <SwipeableDrawer
        title={t("SORT")}
        hideBackdrop={false}
        paperSx={styles.paper}
        isOpen={isMobileSortDrawer}
        onOpen={() => setIsMobileSortDrawer(true)}
        onClose={() => setIsMobileSortDrawer(false)}
      >
        <FormControl sx={styles.formControl}>
          <RadioGroup
            id="sort_list_test"
            value={selectedSortValue}
            defaultValue={sortList?.[0]}
          >
            {sortList?.map(({ value, label }, index) => (
              <FormControlLabel
                key={index}
                value={value}
                label={t(label)}
                id={`${value}_test`}
                control={<Radio onClick={() => handleSortChange(value)} />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </SwipeableDrawer>
    </>
  );
};
