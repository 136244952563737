import { FC, memo } from "react";

import { Typography } from "@mui/material";
import cm from "classnames";
import dayjs from "dayjs";
import { useUnit } from "effector-react";

import { DaysTooltip } from "../DaysTooltip";
import { DatepickerDay } from "./styledComponents";
import { paneStyles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import {
  $endDate,
  setNights,
  $startDate,
  setEndDate,
  resetNights,
  resetEndDate,
  setStartDate,
  resetStartDate,
  $isCalendarLoading,
} from "@/shared/model/store/calendar";
import { TDays } from "@/shared/model/types/search";

type TProps = {
  day: TDays;
};

export const DatepickerGridDay: FC<TProps> = memo(({ day }) => {
  const [
    endDate,
    startDate,
    isCalendarLoading,

    setNightsFn,
    setEndDateFn,
    resetNightsFn,
    resetEndDateFn,
    setStartDateFn,
    resetStartDateFn,
  ] = useUnit([
    $endDate,
    $startDate,
    $isCalendarLoading,

    setNights,
    setEndDate,
    resetNights,
    resetEndDate,
    setStartDate,
    resetStartDate,
  ]);

  const isActive =
    startDate && (startDate === day.date || endDate === day.date);

  const inRange =
    startDate && endDate
      ? dayjs(day.date).isBetween(startDate, endDate, null, "[]")
      : false;

  const onDateClick = (day: string) => {
    const wrappedDay = dayjs(day);
    const wrappedSelectedDate = dayjs(startDate);
    const nights = wrappedDay.diff(startDate, "days");

    if (startDate && wrappedDay.isSame(startDate, "day")) {
      resetStartDateFn();
      resetEndDateFn();
      resetNightsFn();

      return;
    }

    if (
      wrappedDay.isBefore(startDate) ||
      (startDate && wrappedSelectedDate.add(31, "days").isBefore(wrappedDay))
    ) {
      setStartDateFn(day);
      resetEndDateFn();
      resetNightsFn();

      return;
    }

    if (startDate && !endDate) {
      setEndDateFn(day);
      setNightsFn(nights);
    } else {
      setStartDateFn(day);
      resetEndDateFn();
      resetNightsFn();
    }
  };

  return (
    <DaysTooltip day={day}>
      <DatepickerDay
        key={day.date}
        id={`date_${day.date}_test`}
        onClick={() => {
          onDateClick(day.date);
        }}
        className={cm({
          inRange,
          isActive,
          rangeEnd: day.date === endDate,
          isCurrentMonth: day.isCurrentMonth,
          rangeStart: day.date === startDate,
          isToday: dayjs().isSame(day.date, "day"),
          isLoading: isCalendarLoading || (!day.isAvailable && !isActive),
        })}
      >
        <Condition match={day.isCurrentMonth}>
          <Typography sx={paneStyles.datepickerDayLabel}>
            {day.label}
          </Typography>
        </Condition>
      </DatepickerDay>
    </DaysTooltip>
  );
});
