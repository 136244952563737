/* eslint-disable perfectionist/sort-objects */
import { sample, combine } from "effector";

import {
  getNightsQuery,
  getAvailableCalendarDatesQuery,
} from "@/shared/model/api/search-form";
import {
  $arrival,
  setArrival,
  resetArrival,
} from "@/shared/model/store/arrival";
import {
  $endDate,
  $startDate,
  resetNights,
  resetEndDate,
  $currentDate,
  resetStartDate,
  $maxNightsCount,
} from "@/shared/model/store/calendar";
import {
  $departure,
  setDeparture,
  resetDeparture,
} from "@/shared/model/store/departure";
import { BACKEND_KEYS } from "@/shared/model/types/common";

export const $dataForCalculateAvailableDays = combine(
  $endDate,
  $startDate,
  $currentDate,
  getNightsQuery.$data,
  getAvailableCalendarDatesQuery.$data,
  (endDate, startDate, currentDate, availableNights, initialCalendarData) => ({
    endDate,
    startDate,
    currentDate,
    availableNights,
    initialCalendarData,
  }),
);

sample({
  clock: [setDeparture, resetDeparture, setArrival, resetArrival],
  source: {
    arrival: $arrival,
    departure: $departure,
  },
  filter: ({ arrival, departure }) => Boolean(departure?.id && arrival?.id),
  fn: ({ arrival, departure }) => ({
    arrivalId: arrival?.id || "",
    departureId: departure?.id || "",
  }),
  target: getAvailableCalendarDatesQuery.refresh,
});

sample({
  source: {
    arrival: $arrival,
    departure: $departure,
    startDate: $startDate,
  },
  filter: ({ arrival, departure, startDate }) =>
    Boolean(departure && arrival && startDate),
  fn: ({ arrival, departure, startDate }) => ({
    [BACKEND_KEYS.rangeEndDay]: startDate,
    [BACKEND_KEYS.rangeStartDay]: startDate,
    [BACKEND_KEYS.arrival]: arrival?.code as string,
    [BACKEND_KEYS.departure]: departure?.code as string,
  }),
  target: getNightsQuery.refresh,
});

sample({
  clock: getNightsQuery.finished.success,
  fn: (data) => Math.max(...data.result.nights),
  target: $maxNightsCount,
});

sample({
  // TODO: rewrite by events
  source: [$departure, $arrival],
  target: [resetNights, resetStartDate, resetEndDate],
});
