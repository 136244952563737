import { FC } from "react";

import { Box } from "@mui/material";

import { styles } from "./styles";

type TStyleWrapperProps = {
  left?: boolean;
  right?: boolean;
  children: React.ReactNode;
};

export const StyledWrapper: FC<TStyleWrapperProps> = ({
  children,
  left = false,
  right = false,
}) => {
  return <Box sx={styles.wrapper(left, right)}>{children}</Box>;
};
