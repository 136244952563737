/* eslint-disable perfectionist/sort-objects */
import {
  sample,
  combine,
  createStore,
  createEvent,
  createEffect,
} from "effector";
import { createGate } from "effector-react";
import { persist } from "effector-storage/local";

import { LAST_SEEN_KEY } from "@/shared/lib/constants";
import { actualizeLastSeen } from "@/shared/lib/helpers";
import {
  getArrivalDataQuery,
  getDepartureDataQuery,
} from "@/shared/model/api/search-form";
import { TLastSeenCardInfo } from "@/shared/model/types/common";

export const LastSeenGate = createGate();

export const $lastSeenTours = createStore<TLastSeenCardInfo[]>([]);
export const $isLastSeenCollapseOpen = createStore(false);

export const deleteLastSeen = createEvent<number>();
const closeLastSeenCollapse = createEvent();
const openLastSeenCollapse = createEvent();

const setLastSeenFx = createEffect((tours: TLastSeenCardInfo[]) =>
  localStorage.setItem(LAST_SEEN_KEY, JSON.stringify(tours)),
);

export const $destinationTexts = combine(
  getArrivalDataQuery.$data,
  getDepartureDataQuery.$data,
  (arrivals, departures) => {
    return {
      arrivals: arrivals?.data.reduce(
        (result: { [key: string]: string }, item) => {
          const { code, name } = item;
          result[code] = name;
          return result;
        },
        {},
      ),
      departures: departures?.data.reduce(
        (result: { [key: string]: string }, item) => {
          const { code, name } = item;
          result[code] = name;
          return result;
        },
        {},
      ),
    };
  },
);

persist({
  store: $lastSeenTours,
  key: LAST_SEEN_KEY,
  deserialize: actualizeLastSeen,
  pickup: LastSeenGate.open,
});

sample({
  clock: LastSeenGate.open,
  source: $lastSeenTours,
  filter: (lastSeen) => lastSeen.length > 0,
  target: openLastSeenCollapse,
});

sample({
  clock: deleteLastSeen,
  source: $lastSeenTours,
  fn: (lastSeen, deleteIndex) =>
    lastSeen.filter((_tour, index) => index !== deleteIndex),
  target: [setLastSeenFx, $lastSeenTours],
});

sample({
  clock: $lastSeenTours,
  filter: (lastSeen) => lastSeen.length === 0,
  target: closeLastSeenCollapse,
});

$isLastSeenCollapseOpen.on(openLastSeenCollapse, () => true);
$isLastSeenCollapseOpen.on(closeLastSeenCollapse, () => false);
