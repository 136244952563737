import { mainTheme } from "shared/ui/styles";

export const styles = {
  wrapper: {
    top: "0",
    zIndex: "1",
    gap: "10px",
    border: "none",
    display: "flex",
    margin: "0 -16px",
    position: "sticky",
    padding: "8px 16px",
    color: "content.primary",
    borderRadius: "0 0 16px 16px",
    justifyContent: "space-between",
    backgroundColor: "dominant.white",
    boxShadow: "0px 1px 0px rgba(164, 179, 190, 0.25)",

    [mainTheme.breakpoints.down("smd")]: {
      borderRadius: "0 0 8px 8px",
    },
  },
};
