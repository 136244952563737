import { FC, memo, useCallback } from "react";

import { Add, Remove } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import {
  MAX_ADULTS,
  MIN_ADULTS,
  MAX_CHILDREN,
  MIN_CHILDREN,
  MAX_CHILD_AGE,
} from "@/entities/search/Quantity/lib/constants";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import {
  $adults,
  $children,
  $childrenAges,
  decrementAdults,
  incrementAdults,
  decrementChildren,
  incrementChildren,
  setChildrenAgesByIndex,
} from "@/shared/model/store/quantity";
import { Select, FabIconButton } from "@/shared/ui";

export const QuantityCommon: FC = memo(() => {
  const t = useTranslations();
  const theme = useCustomTheme();

  const [
    adults,
    children,
    childrenAges,

    setChildrenAgesByIndexFn,
    decrementAdultsFn,
    incrementAdultsFn,
    decrementChildrenFn,
    incrementChildrenFn,
  ] = useUnit([
    $adults,
    $children,
    $childrenAges,

    setChildrenAgesByIndex,
    decrementAdults,
    incrementAdults,
    decrementChildren,
    incrementChildren,
  ]);

  const generateChildrenAges = (maxChildAge: number) => {
    const initialArray = Array.from(
      { length: maxChildAge + 1 },
      (_, index) => ({
        value: index,
        id: `child's_age_is_${index}_year_test`,
        label: !index ? t("UNDER_1_YEAR") : t("YEARS_OLD", { count: index }),
      }),
    );

    return initialArray;
  };

  const CHILDREN_AGES = generateChildrenAges(MAX_CHILD_AGE);

  const handleChildAgeChange = useCallback((index: number, value: number) => {
    setChildrenAgesByIndexFn({ index, value });
  }, []);

  const handleDecrementAdults = useCallback(() => {
    decrementAdultsFn();
  }, []);

  const handleIncrementAdults = useCallback(() => {
    incrementAdultsFn();
  }, []);

  const handleDecrementChildren = useCallback(() => {
    decrementChildrenFn();
  }, []);

  const handleIncrementChildren = useCallback(() => {
    incrementChildrenFn();
  }, []);

  return (
    <Box sx={styles.wrapper} id="quantity-modal-onboarding">
      <Box sx={styles.row}>
        <Box sx={styles.infoBlock}>
          <Typography>{t("ADULTS")}</Typography>
          <Typography sx={styles.caption}>
            {t("ADULTS_ABOVE_YEARS_OLD", {
              count: 16,
            })}
          </Typography>
        </Box>
        <Box sx={styles.buttons}>
          <FabIconButton
            // @ts-expect-error
            theme={theme}
            id="decrement_adults_test"
            onClick={handleDecrementAdults}
            disabled={adults === MIN_ADULTS}
          >
            <Remove style={styles.icons} />
          </FabIconButton>
          <Typography sx={styles.text}>{adults}</Typography>
          <FabIconButton
            // @ts-expect-error
            theme={theme}
            id="increment_adults_test"
            onClick={handleIncrementAdults}
            disabled={adults === MAX_ADULTS}
          >
            <Add style={styles.icons} />
          </FabIconButton>
        </Box>
      </Box>
      <Box sx={styles.row}>
        <Box sx={styles.infoBlock}>
          <Typography component="p">{t("CHILDREN")}</Typography>
          <Typography component="p" sx={styles.caption}>
            {t("CHILDREN_BELOW_YEARS_OLD", { count: 16 })}
          </Typography>
        </Box>
        <Box sx={styles.buttons}>
          <FabIconButton
            // @ts-expect-error
            theme={theme}
            id="decrement_children_test"
            onClick={handleDecrementChildren}
            disabled={children === MIN_CHILDREN}
          >
            <Remove style={styles.icons} />
          </FabIconButton>
          <Typography sx={styles.text}>{children}</Typography>
          <FabIconButton
            // @ts-expect-error
            theme={theme}
            id="increment_children_test"
            onClick={handleIncrementChildren}
            disabled={children === MAX_CHILDREN}
          >
            <Add style={styles.icons} />
          </FabIconButton>
        </Box>
      </Box>

      <Condition match={children !== 0 && Array.isArray(childrenAges)}>
        <Divider sx={styles.divider} />
        <Typography paragraph sx={styles.childrenAgeText}>
          {t("PLEASE_INDICATE_CHILDRENS_AGE_AT_THE_END_OF_THE_TRIP")}
        </Typography>

        {childrenAges &&
          Object.keys(childrenAges).map((key) => {
            return (
              <Box key={key} sx={styles.row.childrenAge}>
                <Select
                  label=""
                  selectKey={key}
                  options={CHILDREN_AGES}
                  id="select_children_age_test"
                  placeholder={t("INDICATE_AGE")}
                  sxListItem={styles.listItemStyles}
                  onChange={(value) => {
                    handleChildAgeChange(Number(key), Number(value));
                  }}
                  value={
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    childrenAges[Number(key)] !== null
                      ? Number(childrenAges[Number(key)])
                      : ""
                  }
                />
              </Box>
            );
          })}
      </Condition>
    </Box>
  );
});
