"use client";
import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import { FC } from "react";

import { useUnit } from "effector-react";

import { Loader as ContentHeaderLoader } from "../content-header/loader";
import { SearchPageContentWrapper } from "../content-wrapper";
import { MobileMapButton } from "../mobile-map-button";
import { Loader as SearchCardsLoader } from "../search-cards/loader";
import { SearchHeader } from "../search-header";
import { SearchUninitialized } from "../search-uninitialized";
import { TabletMapButton } from "../tablet-map-button";
import { styles } from "./styles";

import { Map, MobileMap } from "@/entities/map";
import { TourFilters } from "@/entities/search";

import { useSearchBreakpoints } from "@/shared/lib/hooks";
import {
  getInitialSearchQuery,
  getActualizedSearchQuery,
  getActualizedSearchQueryWithWs,
} from "@/shared/model/api/search";
import { $isTabletMapOpen } from "@/shared/model/store/common";
import { $filteredTours } from "@/shared/model/store/search";
import { ESearchType } from "@/shared/model/types/search";

const ContentHeader = dynamic(() => import("../content-header"), {
  ssr: false,
  loading: () => <ContentHeaderLoader />,
});

const SearchCards = dynamic(() => import("../search-cards"), {
  ssr: false,
  loading: () => <SearchCardsLoader />,
});

const MIN_NUMBER_OF_TOURS_TO_DISPLAY_SCROLL = 6;

export const SearchPageContent: FC = () => {
  const searchParams = useSearchParams();

  const { isPhone, isTablet, isDesktop } = useSearchBreakpoints({
    type: ESearchType.Main,
  });

  const { pending: isInitializedLoading } = useUnit(getInitialSearchQuery);
  const { pending: isActualizedLoading } = useUnit(getActualizedSearchQuery);
  const { pending: isWsLoading } = useUnit(getActualizedSearchQueryWithWs);

  const [toursData, isTabletMapOpen] = useUnit([
    $filteredTours,
    $isTabletMapOpen,
  ]);

  const isDataPresent = toursData?.meta?.packages_total !== 0;
  const amountOfTours = toursData?.meta?.packages_total ?? 0;
  const isDataEmpty =
    !isActualizedLoading &&
    !isInitializedLoading &&
    !isWsLoading &&
    !isDataPresent;

  const isMainLoaderVisible =
    (!isDataPresent && (isActualizedLoading || isWsLoading)) ||
    isInitializedLoading;
  const isProgressLoaderVisible = isActualizedLoading || isWsLoading;
  const isScrollToTopVisible =
    toursData?.meta?.packages_total && !isDataEmpty
      ? toursData.meta.packages_total >= MIN_NUMBER_OF_TOURS_TO_DISPLAY_SCROLL
      : false;

  const MapComponent = isPhone ? MobileMap : Map;
  const mapProps = {
    toursData: toursData,
    sx: isPhone ? styles.mobileMap : styles.map,
  };

  const searchPageContentProps = {
    isLoading: isProgressLoaderVisible,
    Map: isDesktop || isPhone ? <MapComponent {...mapProps} /> : null,
    wrapperSx: isPhone ? styles.mobileSearchWrapper : styles.searchWrapper,
    childrenWrapperSx:
      isPhone || isTablet ? styles.mainContentTablet : styles.mainContent,
  };

  const headerContent = isDesktop ? (
    <TourFilters
      isDesktop
      isScrollToTopVisible={isScrollToTopVisible}
      isLoading={isMainLoaderVisible || isProgressLoaderVisible}
    />
  ) : isTablet ? (
    <>
      <TabletMapButton />
      <TourFilters
        isDesktop={false}
        isScrollToTopVisible={isScrollToTopVisible}
        isLoading={isMainLoaderVisible || isProgressLoaderVisible}
      />
    </>
  ) : null;

  const mapOrSearchCards =
    isTablet && isTabletMapOpen ? (
      <MapComponent {...mapProps} />
    ) : (
      <SearchCards
        toursData={toursData}
        isDataEmpty={isDataEmpty}
        isLoading={isMainLoaderVisible}
      />
    );

  if (!searchParams.toString()) {
    return <SearchUninitialized />;
  }

  return (
    <>
      {!isPhone && <SearchHeader />}

      <SearchPageContentWrapper
        {...searchPageContentProps}
        Header={headerContent}
      >
        <>
          {isPhone && <MobileMapButton />}

          <ContentHeader
            amountOfTours={amountOfTours}
            isLoading={isMainLoaderVisible || isProgressLoaderVisible}
          />

          {mapOrSearchCards}
        </>
      </SearchPageContentWrapper>
    </>
  );
};
