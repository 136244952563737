import { FC, useMemo, useCallback } from "react";

import { useMediaQuery } from "@mui/material";
import { Button, SxProps } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { $searchForm } from "../model";
import { styles } from "./styles";

import { setLastSeen, transformDataToQuery } from "@/shared/lib/helpers";
import { checkIfRouterValuesValid } from "@/shared/lib/helpers/search/validations";
import { useCustomTheme } from "@/shared/lib/hooks";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { $nights } from "@/shared/model/store/calendar";
import { $searchType } from "@/shared/model/store/common";
import { resetSelectedFilters } from "@/shared/model/store/filters";
import {
  resetPrices,
  resetSelectedMaxPrice,
  resetSelectedMinPrice,
} from "@/shared/model/store/prices";
import { $adults, $childrenAges } from "@/shared/model/store/quantity";
import {
  resetSelectedHotel,
  $searchHotelsQuery,
  resetSearchHotelsQuery,
} from "@/shared/model/store/search";
import { TLastSeenCardInfo } from "@/shared/model/types/common";
import { ESearchType, TQuerySearchData } from "@/shared/model/types/search";

type TProps = {
  id?: string;
  buttonSx?: SxProps;
  mobileWrapperSx?: SxProps;
  children?: string | JSX.Element;
  onClick: (queryData: TQuerySearchData) => void;
};

export const TourSearchButton: FC<TProps> = ({
  id,
  onClick,
  children = "FIND",
  buttonSx = styles.defaultButtonSx,
}) => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("smd"));

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const [
    searchType,
    nights,
    adults,
    childrenAges,
    data,
    resetSelectedFiltersFn,
    resetSelectedMaxPriceFn,
    resetSelectedMinPriceFn,
    resetPricesFn,
    resetSearchHotelsQueryFn,
    resetSelectedHotelFn,
    searchHotelsQuery,
  ] = useUnit([
    $searchType,
    $nights,
    $adults,
    $childrenAges,
    $searchForm,
    resetSelectedFilters,
    resetSelectedMaxPrice,
    resetSelectedMinPrice,
    resetPrices,
    resetSearchHotelsQuery,
    resetSelectedHotel,
    $searchHotelsQuery,
  ]);

  const { isFormValid, dispatchErrors } = useMemo(
    () => checkIfRouterValuesValid(data),
    [data],
  );

  const handleTourSearchClick = useCallback(() => {
    if (!isFormValid) {
      dispatchErrors();
      return;
    }

    if (searchType !== ESearchType.Hotel) {
      resetSearchHotelsQueryFn();
      resetSelectedHotelFn();
    }

    resetSelectedFiltersFn();
    resetSelectedMaxPriceFn();
    resetSelectedMinPriceFn();
    resetPricesFn();

    try {
      sendDataLayerEventFn({
        event: "searchTour",
        data: {
          nightsCount: nights,
          adultsCount: adults,
          childrenAges: childrenAges,
        },
      });
    } catch (error) {
      console.log(error);
    }

    // TODO: remove any
    const queryData = transformDataToQuery(data as any);
    setLastSeen(queryData as TLastSeenCardInfo);

    onClick({
      ...queryData,
      hotel: searchHotelsQuery,
    });
  }, [
    isFormValid,
    searchType,
    resetSelectedFiltersFn,
    resetSelectedMaxPriceFn,
    resetSelectedMinPriceFn,
    resetPricesFn,
    data,
    onClick,
    searchHotelsQuery,
    dispatchErrors,
    resetSearchHotelsQueryFn,
    resetSelectedHotelFn,
    sendDataLayerEventFn,
    nights,
    adults,
    childrenAges,
  ]);

  if (isPhone) {
    return (
      <Button
        id={id}
        fullWidth
        sx={buttonSx}
        color="primary"
        disableElevation
        variant="contained"
        disabled={!isFormValid}
        onClick={handleTourSearchClick}
      >
        {children === "FIND" ? t(children) : children}
      </Button>
    );
  }

  return (
    <Button
      id={id}
      sx={buttonSx}
      color="primary"
      disableElevation
      onClick={handleTourSearchClick}
      fullWidth={searchType === ESearchType.Hotel}
      variant={searchType === ESearchType.Hotel ? "outlined" : "contained"}
    >
      {children === "FIND" ? t(children) : children}
    </Button>
  );
};
