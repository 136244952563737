import { FC } from "react";

import { Box, Rating, Typography } from "@mui/material";
import isNumber from "lodash/isNumber";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { THotelData } from "@/shared/model/types/common";
import { Image } from "@/shared/ui";

type TProps = {
  hotel: THotelData;
  withImage?: boolean;
  onClick: (name: string) => void;
};

export const HotelCard: FC<TProps> = ({ hotel, onClick, withImage }) => {
  const { name, images, rating, category } = hotel;

  return (
    <Box sx={styles.card} onClick={() => onClick(name)}>
      <Condition match={!!withImage}>
        <Image
          alt={name}
          width={60}
          height={45}
          rounded={"sm"}
          src={images[0]?.src ?? ""}
        />
      </Condition>

      <Box sx={styles.info}>
        <Typography sx={styles.name}>{name}</Typography>
        <Box sx={styles.ratingWrapper}>
          {isNumber(category?.value) && (
            <Rating
              readOnly
              size="medium"
              sx={styles.rating}
              max={Number(category?.value)}
              value={Number(category?.value)}
            />
          )}

          <Condition match={Boolean(rating)}>
            <Typography sx={styles.text}>{rating}</Typography>
          </Condition>
        </Box>
      </Box>
    </Box>
  );
};
