import { FC, memo, useCallback } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/kk";
import { useUnit } from "effector-react";

import { DatepickerGrid } from "./DatepickerGrid";
import { ArrowButton } from "./styledComponents";
import { paneStyles } from "./styles";

import type { TDatepickerPane } from "@/entities/search/Calendar/model/types";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import {
  reduceCurrentDate,
  increaseCurrentDate,
} from "@/shared/model/store/calendar";

const DESKTOP_DATE_FORMAT = "MMMM YYYY";
const MOBILE_DATE_FORMAT = "MMMM, YYYY";

export const DatepickerPane: FC<TDatepickerPane> = memo(
  ({ date, days, leftArrow = false, rightArrow = false }) => {
    const theme = useCustomTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("smd"));

    const [reduceCurrentDateFn, increaseCurrentDateFn] = useUnit([
      reduceCurrentDate,
      increaseCurrentDate,
    ]);

    const setPrevMonth = useCallback(() => {
      reduceCurrentDateFn();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setNextMonth = useCallback(() => {
      increaseCurrentDateFn();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Box sx={paneStyles.container}>
        <Box sx={paneStyles.monthWrapper}>
          <Condition match={isDesktop && leftArrow}>
            <ArrowButton left expanded={isDesktop} onClick={setPrevMonth} />
          </Condition>

          <Typography component="p" sx={paneStyles.monthName}>
            {dayjs(date).format(
              isDesktop ? DESKTOP_DATE_FORMAT : MOBILE_DATE_FORMAT,
            )}
          </Typography>

          <Condition match={isDesktop && rightArrow}>
            <ArrowButton expanded={isDesktop} onClick={setNextMonth} />
          </Condition>
        </Box>

        <DatepickerGrid days={days} />
      </Box>
    );
  },
);
