export const styles = {
  notFound: {
    padding: "14px 8px",
  },

  expandedIcon: {
    transition: "0.2s",
  },

  title: {
    fontSize: "16px",
    fontWeight: "500",
  },

  wrapper: {
    inputWrapper: {
      marginTop: "8px",
    },
  },

  expandIcon: {
    transition: "0.2s",
    transform: "rotateZ(-90deg)",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  filters: {
    gap: "25px",
    display: "flex",
    flex: "0 0 240px",
    flexDirection: "column",
  },

  sliderWrapper: {
    width: "100%",
    height: "auto",
    marginTop: "12px",
    padding: "0 12px",
    overflow: "hidden",
  },

  header: {
    display: "flex",
    fontSize: "18px",
    cursor: "pointer",
    alignItems: "center",
    marginBottom: "21px",
    justifyContent: "space-between",
  },

  priceInputs: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
    "&>div": {
      margin: 0,
    },

    input: {
      fontSize: "14px",
      padding: "8px 12px",
    },
  },

  slider: {
    height: "2px",

    ".MuiSlider-thumbColorPrimary": {
      boxShadow: "none",
      transition: "0.2s",
      border: "2px solid",
      borderColor: "grey.white",

      "&:hover": {
        boxShadow: "none",
        borderColor: "primary.main",
      },
    },
  },
};
