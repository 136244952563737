import { FC } from "react";

import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useUnit } from "effector-react";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import {
  $isCalendarLoading,
  $isCalendarCalculating,
} from "@/shared/model/store/calendar";

export const CalendarLoader: FC = () => {
  const [isCalendarCalculating, isCalendarLoading] = useUnit([
    $isCalendarCalculating,
    $isCalendarLoading,
  ]);

  return (
    <Condition match={isCalendarLoading || isCalendarCalculating}>
      <Box sx={styles.loader}>
        <CircularProgress size={36} />
      </Box>
    </Condition>
  );
};
