import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { FC } from "react";

import { Box } from "@mui/material";
import { useGate } from "effector-react";

import { SEARCH_BUTTON_ID_ON_MAIN } from "../../lib";
import { StyledWrapper } from "../styled-wrapper";
import { styles, mobileStyles, backgroundStyles } from "./styles";

import { TourSearchButton } from "@/features/search";

import { HomePageTexts } from "@/entities/home-page-texts";
import {
  Arrival,
  Calendar,
  Quantity,
  Departure,
  LastSeenTours,
} from "@/entities/search";

import { getSearchParamsString } from "@/shared/lib/helpers/router/getSearchParamsString";
import { useSearchBreakpoints } from "@/shared/lib/hooks";
import {
  mainOnboarding,
  HomePageSearchMounted,
} from "@/shared/model/onboardings/main-onboarding";
import { ESearchType, TQuerySearchData } from "@/shared/model/types/search";

import kawai from "@/public/img/common/kawai.png";

export const HomePageSearch: FC = () => {
  const router = useRouter();
  const isKawai = useSearchParams()?.get("kawaii");

  const { isPhone, isDesktop } = useSearchBreakpoints({
    type: ESearchType.Home,
  });

  useGate(HomePageSearchMounted, {
    isDesktop,
  });

  const handleSearch = (queryData: TQuerySearchData) => {
    const query = getSearchParamsString(queryData);

    router.push(`/search?${query}`);

    if (isDesktop && mainOnboarding.isActive()) {
      mainOnboarding.complete();
    }
  };

  if (isPhone) {
    return (
      <Box sx={styles.mainWrapper}>
        <Box sx={backgroundStyles.image} />
        <Box sx={mobileStyles.textWrapper}>
          <HomePageTexts />
        </Box>

        <Box sx={mobileStyles.wrapper}>
          <Box sx={mobileStyles.inner}>
            <Box sx={mobileStyles.inputs}>
              <StyledWrapper left>
                <Departure />
              </StyledWrapper>
              <StyledWrapper right>
                <Arrival />
              </StyledWrapper>
            </Box>
            <Box sx={mobileStyles.inputs}>
              <StyledWrapper left>
                <Calendar />
              </StyledWrapper>
              <StyledWrapper right>
                <Quantity />
              </StyledWrapper>
            </Box>

            <TourSearchButton
              mobileWrapperSx={{}}
              onClick={handleSearch}
              id={SEARCH_BUTTON_ID_ON_MAIN}
              buttonSx={mobileStyles.buttonSx}
            />
          </Box>

          <LastSeenTours />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.mainWrapper}>
      <Box sx={backgroundStyles.image}></Box>
      {isKawai ? (
        <Box sx={styles.kawai}>
          <Image src={kawai} width={500} alt="kawai" placeholder="blur" />
        </Box>
      ) : (
        <HomePageTexts />
      )}

      <Box sx={styles.wrapper}>
        <Box sx={styles.formWrapper}>
          <Box sx={styles.form}>
            <Box sx={styles.inputs}>
              <Departure />
              <Arrival />
              <Calendar />
              <Quantity />
            </Box>
            <TourSearchButton
              onClick={handleSearch}
              buttonSx={styles.buttonSx}
              id={SEARCH_BUTTON_ID_ON_MAIN}
            />
          </Box>
        </Box>
        <LastSeenTours />
      </Box>
    </Box>
  );
};
