"use client";
import { FC, useState, useEffect, useCallback } from "react";

import { Close } from "@mui/icons-material";
import { Typography, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useTranslations } from "next-intl";

import { TLeadCreationModal } from "../../lib/types";
import { styles } from "./styles";

import { LeadDialog } from "@/features/lead-form";

import { isClient } from "@/shared/lib/helpers/isClient";

export const LeadModal: FC<TLeadCreationModal> = ({
  wasModalOpened,
  handleUpdateOpeningTime,
}) => {
  const t = useTranslations();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onModalOpen = useCallback(() => {
    handleUpdateOpeningTime(dayjs().valueOf());
  }, [handleUpdateOpeningTime]);

  useEffect(() => {
    if (document.visibilityState === "visible" && wasModalOpened) {
      onModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasModalOpened]);

  if (!isClient) {
    return null;
  }

  window.onfocus = () => onModalOpen();

  return (
    <LeadDialog
      title=""
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      formWrapperSx={styles.form}
      WrapperProps={styles.wrapperProps}
      innerWrapperSx={styles.contentWrapper}
      PaperProps={{ sx: styles.idleUserPaperSx }}
    >
      <IconButton
        aria-label="close"
        sx={styles.closeIcon}
        onClick={handleCloseModal}
      >
        <Close />
      </IconButton>
      <Typography component="h2" sx={styles.title}>
        {t("HAVE_QUESTIONS_ABOUT_TOUR")}
      </Typography>
      <Typography sx={styles.subtitle}>
        {t("LEAVE_PHONE_NUMBER_AND_WE_WILL_CONTACT")}
      </Typography>
    </LeadDialog>
  );
};
