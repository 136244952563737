/* eslint-disable perfectionist/sort-objects */
import { sample, combine, createEvent, createStore } from "effector";
import { createGate } from "effector-react";
import { debounce } from "patronum";

import { TYPES_FOR_GROUPED_ACTUALIZE_RESPONSE } from "../lib";

import {
  PER_PAGE,
  MAIN_SEARCH_INCLUDES,
  HOTELS_SEARCH_INCLUDES,
} from "@/shared/lib/constants";
import { getCountriesDetailsByIdQuery } from "@/shared/model/api/common";
import { getHotelDetailsQuery } from "@/shared/model/api/hotels";
import {
  getInitialSearchQuery,
  getActualizedSearchQuery,
  getActualizedSearchQueryWithWs,
} from "@/shared/model/api/search";
import {
  $arrival,
  $regions,
  setAllRegionsSelected,
  resetArrivalFilterByRegion,
  resetArrivalFilterByCountry,
} from "@/shared/model/store/arrival";
import { $nights, $startDate } from "@/shared/model/store/calendar";
import { $searchType, setSearchType } from "@/shared/model/store/common";
import { $departure } from "@/shared/model/store/departure";
import {
  $selectedFilters,
  resetSelectedFilters,
} from "@/shared/model/store/filters";
import {
  resetPrices,
  $selectedMinPrice,
  $selectedMaxPrice,
} from "@/shared/model/store/prices";
import {
  $adults,
  $children,
  $childrenAges,
} from "@/shared/model/store/quantity";
import { $selectedHotel, setSelectedHotel } from "@/shared/model/store/search";
import { $selectedSort } from "@/shared/model/store/sort";
import { BACKEND_KEYS } from "@/shared/model/types/common";
import { ESearchType } from "@/shared/model/types/search";
import { ESortListValue, SELECTED_SEARCH } from "@/shared/model/types/sort";

export const SearchGate = createGate<ESearchType>();
export const initSearch = createEvent<void>();
export const updateSearch = createEvent<void>();
const debouncedActualized = debounce({
  timeout: 700,
  source: updateSearch,
});

sample({
  clock: SearchGate.open,
  target: setSearchType,
});

sample({
  clock: SearchGate.close,
  target: [
    resetSelectedFilters,
    resetPrices,
    setAllRegionsSelected,
    resetArrivalFilterByCountry,
    resetArrivalFilterByRegion,
  ],
});

sample({
  source: { searchType: $searchType, hotelData: getHotelDetailsQuery.$data },
  filter: ({ searchType, hotelData }) =>
    searchType === ESearchType.Hotel && !!hotelData?.id,
  fn: ({ hotelData }) => hotelData?.id as string,
  target: setSelectedHotel,
});

const $queryPayload = createStore({
  [ESearchType.Main]: {
    include: MAIN_SEARCH_INCLUDES.INIT.join(","),
    per_page: PER_PAGE,
  },
  [ESearchType.Hotel]: {
    ...SELECTED_SEARCH[ESortListValue.ASC_PRICE],
    include: HOTELS_SEARCH_INCLUDES.INIT.join(","),
  },
  [ESearchType.Home]: {
    include: "",
  },
  [ESearchType.Constructor]: {
    ...SELECTED_SEARCH[ESortListValue.POPULARITY],
    include: MAIN_SEARCH_INCLUDES.INIT.join(","),
    per_page: 10,
  },
});

const $isFormValid = combine(
  [
    $adults,
    $nights,
    $children,
    $arrival,
    $departure,
    $childrenAges,
    $startDate,
    $selectedHotel,
    $searchType,
  ],
  ([
    adults,
    nights,
    children,
    arrival,
    departure,
    childrenAges,
    startDate,
    hotel,
    searchType,
  ]) => {
    if (searchType === ESearchType.Hotel) {
      return !!hotel;
    }

    if (searchType === ESearchType.Main) {
      return (
        !!arrival &&
        !!departure &&
        !!startDate &&
        !!nights &&
        !!adults &&
        typeof children === "number" &&
        childrenAges.length === children
      );
    }

    return true;
  },
);

const $bodyPayload = combine(
  [
    $adults,
    $nights,
    $children,
    $arrival,
    $departure,
    $childrenAges,
    $startDate,
    $regions,
    $selectedMinPrice,
    $selectedMaxPrice,
    $selectedFilters,
    $selectedHotel,
    $searchType,
  ],
  ([
    adults,
    nights,
    children,
    arrival,
    departure,
    childrenAges,
    startDate,
    regions,
    priceFrom,
    priceTo,
    filters,
    hotel,
    searchType,
  ]) => ({
    [BACKEND_KEYS.adults]: adults,
    [BACKEND_KEYS.nights]: nights,
    [BACKEND_KEYS.children]: children,
    [BACKEND_KEYS.arrival]: arrival?.code ?? "",
    [BACKEND_KEYS.departure]: departure?.code ?? "",
    [BACKEND_KEYS.rangeEndDay]: startDate,
    [BACKEND_KEYS.rangeEndNight]: nights,
    [BACKEND_KEYS.childrenAges]: childrenAges,
    [BACKEND_KEYS.rangeStartDay]: startDate,
    [BACKEND_KEYS.rangeStartNight]: nights,
    [BACKEND_KEYS.selectedRegionIds]: regions,
    hotels: hotel ? [hotel] : [],
    price_from: priceFrom,
    price_to: priceTo,
    ...filters,
    grouped: TYPES_FOR_GROUPED_ACTUALIZE_RESPONSE.includes(searchType),
  }),
);

const $bodyPayloadWithoutPrice = $bodyPayload.map((payload) => {
  return {
    ...payload,
    price_from: 0,
    price_to: 0,
  };
});

const $searchParams = combine(
  $searchType,
  $queryPayload,
  $selectedSort,
  (type, params, sort) => {
    if (type === ESearchType.Hotel) {
      return {
        ...params[type],
      };
    }

    return {
      ...params[type],
      ...sort,
    };
  },
);

sample({
  clock: [initSearch, getHotelDetailsQuery.finished.success],
  source: {
    data: $bodyPayloadWithoutPrice,
    params: $searchParams,
    searchType: $searchType,
    isFormValid: $isFormValid,
  },
  filter: ({ isFormValid }) => isFormValid,
  fn: ({ data, params }) => ({
    data,
    params,
  }),
  target: [
    getInitialSearchQuery.start,
    getActualizedSearchQueryWithWs.reset,
    getActualizedSearchQuery.reset,
  ],
});

sample({
  clock: getInitialSearchQuery.finished.success,
  source: {
    isFormValid: $isFormValid,
  },
  filter: ({ isFormValid }, { result }) => {
    const { query_id, completed } = result.meta;

    return !!query_id && !completed && isFormValid;
  },
  fn: (_, { result }) => result.meta.query_id as string,
  target: getActualizedSearchQueryWithWs.start,
});

sample({
  clock: getInitialSearchQuery.finished.success,
  source: $arrival,
  fn: (arrival) => arrival?.id ?? "",
  target: getCountriesDetailsByIdQuery.start,
});

sample({
  clock: getActualizedSearchQueryWithWs.finished.success,
  source: {
    data: $bodyPayloadWithoutPrice,
    id: getInitialSearchQuery.$data.map((data) => data?.meta?.query_id, {
      skipVoid: false,
    }),
    completed: getInitialSearchQuery.$data.map(
      (data) => data?.meta?.completed,
      {
        skipVoid: false,
      },
    ),
    params: $searchParams,
    isFormValid: $isFormValid,
  },
  // TODO: add search validation
  filter: ({ data, id, completed, isFormValid }) =>
    !!data && !!id && !completed && isFormValid,
  fn: ({ data, id, params }) => ({
    data,
    id: id as string,
    params,
  }),
  target: getActualizedSearchQuery.start,
});

sample({
  clock: debouncedActualized,
  source: {
    data: $bodyPayload,
    id: getInitialSearchQuery.$data.map((data) => data?.meta?.query_id, {
      skipVoid: false,
    }),
    params: $searchParams,
    isFormValid: $isFormValid,
  },
  // TODO: add search validation
  filter: ({ data, id, isFormValid }) => !!data && !!id && isFormValid,
  fn: ({ data, id, params }) => ({
    data,
    id: id as string,
    params,
  }),
  target: getActualizedSearchQuery.start,
});
