"use client";

import { FC, useState, useEffect } from "react";

import { Close } from "@mui/icons-material";
import { Box, Collapse, IconButton } from "@mui/material";
import dayjs from "dayjs";

import { MobileAppBlock } from "../mobile-app-block";
import { styles } from "./styles";

import { isClient } from "@/shared/lib/helpers/isClient";

export const MobileDownloadAppBlock: FC = () => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const lastTime = isClient
    ? localStorage.getItem("lastTimeAppModalOpened")
    : null;

  const checkLastTimeAppModalOpened =
    lastTime !== null ? Boolean(dayjs().diff(lastTime, "hour") >= 12) : true;

  useEffect(() => {
    if (checkLastTimeAppModalOpened) {
      setIsCollapseOpen(true);
    }
  }, [checkLastTimeAppModalOpened]);

  const handleClose = () => {
    setIsCollapseOpen(false);
    localStorage.setItem("lastTimeAppModalOpened", dayjs().toJSON());
  };

  return (
    <Collapse in={isCollapseOpen} sx={styles.collapse}>
      <Box sx={styles.wrapper}>
        <IconButton
          size="small"
          color="inherit"
          aria-label="close"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <MobileAppBlock />
      </Box>
    </Collapse>
  );
};
