import dayjs from "dayjs";
import "dayjs/locale/kk";
import "dayjs/locale/ru";

import {
  SERVER_DATE_FORMAT,
  DEFAULT_AMOUNT_OF_NIGHTS,
} from "@/shared/lib/constants";
import { getSearchParamsString } from "@/shared/lib/helpers/router/getSearchParamsString";
import { QUERY_OPTIONS, TLastSeenCardInfo } from "@/shared/model/types/common";

export const generateQueryFromLastSeen = (tour: TLastSeenCardInfo) => {
  const startDate = tour[QUERY_OPTIONS.rangeStartDay];
  const endDate = tour[QUERY_OPTIONS.rangeEndDay];
  const nights = Number(tour[QUERY_OPTIONS.selectedNight]);

  const newQuery = {
    ...tour,
    [QUERY_OPTIONS.rangeStartDay]:
      startDate || dayjs().format(SERVER_DATE_FORMAT),
    [QUERY_OPTIONS.rangeEndDay]:
      endDate ||
      dayjs()
        .add(nights || DEFAULT_AMOUNT_OF_NIGHTS, "day")
        .format(SERVER_DATE_FORMAT),
  } as Partial<TLastSeenCardInfo>;

  // TODO: temporarily needed for older logic cases
  delete newQuery.arrivalName;
  delete newQuery.departureName;
  delete newQuery.index;

  return `/search?${getSearchParamsString(newQuery)}`;
};
