import { FC, memo } from "react";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import { DatepickerGridDay } from "./DatepickerGridDay";
import {
  StyledDatepickerGrid,
  StyledDatepickerWeekday,
} from "./styledComponents";

import type { TDatepickerGrid } from "@/entities/search/Calendar/model/types";

import { RU_DAYS_OF_WEEK } from "@/shared/lib/constants";

dayjs.extend(isBetween);

export const DatepickerGrid: FC<TDatepickerGrid> = memo(({ days = [] }) => {
  return (
    <StyledDatepickerGrid>
      {RU_DAYS_OF_WEEK.map((weekday) => (
        <StyledDatepickerWeekday key={weekday}>
          {weekday}
        </StyledDatepickerWeekday>
      ))}

      {days.map((day) => (
        <DatepickerGridDay day={day} key={day.date} />
      ))}
    </StyledDatepickerGrid>
  );
});
