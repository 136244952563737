import { COLORS } from "shared/ui/styles";

export const styles = {
  loader: {
    display: "flex",
    flexDirection: "column",
  },

  mobileWrapper: {
    width: "stretch",
    paddingTop: "16px",
  },

  mobileLoader: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  tooltip: {
    maxWidth: "90%",
    marginBottom: 0,
    marginTop: "2px",
    fontSize: "14px",
    overflow: "hidden",
    lineHeight: "16px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: COLORS.LIGHT_GRAY,
    overflowWrap: "break-word",

    showAddress: {
      cursor: "pointer",
      color: COLORS.BLUE,
    },
  },
};
