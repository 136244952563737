"use client";
import { usePathname } from "next/navigation";

import { useMediaQuery } from "@mui/material";

import { AppBonusesModal } from "./app-bonuses-modal";
import { DelayedLeadModal } from "./delayed-lead-modal";

import {
  PAGES_WITH_LEAD_MODAL,
  PAGES_WITH_BONUSES_MODAL,
} from "@/widgets/global-modals/lib/constants";

import { QuestionnaireModal } from "@/entities/questionnaire";

import { useCustomTheme } from "@/shared/lib/hooks";

export const Renderer = () => {
  const pathname = usePathname();
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {PAGES_WITH_LEAD_MODAL.includes(pathname) && <DelayedLeadModal />}
      {isDesktop && PAGES_WITH_BONUSES_MODAL.includes(pathname) && (
        <AppBonusesModal />
      )}
      <QuestionnaireModal />
    </>
  );
};
