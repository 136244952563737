import { FC, memo } from "react";

import { Box } from "@mui/material";

import { MobileFilter } from "../mobile-filter";
import { MobileSort } from "../mobile-sort";
import { styles } from "./styles";

export const MobileTourFilters: FC = memo(() => {
  return (
    <Box sx={styles.wrapper}>
      <MobileSort />
      <MobileFilter />
    </Box>
  );
});
