import { mainTheme } from "shared/ui/styles";

export const styles = {
  resetIcon: {
    marginRight: "10px",
  },

  filters: {
    gap: "25px",
    display: "flex",
    flex: "0 0 240px",
    height: "fit-content",
    flexDirection: "column",
  },

  wrapper: {
    inputWrapper: {
      marginTop: "8px",
    },

    [mainTheme.breakpoints.down("md")]: {
      paddingTop: "32px",
    },
  },

  resetFilters: {
    fontSize: "16px",
    marginTop: "30px",
    lineHeight: "18px",

    [mainTheme.breakpoints.down("md")]: {
      marginTop: 0,
      width: "100%",
      marginRight: "15px",
    },
  },
};
