/* eslint-disable perfectionist/sort-objects */
"use client";
import { split, sample, createEffect } from "effector";
import { createGate } from "effector-react";

import { $dataForCalculateAvailableDays } from "./edge";
import { TWorkerPayload, TWorkerResponse } from "./types";

import {
  setMonthDays,
  setMobileMonths,
  enableCalendarLoader,
  disableCalendarLoader,
} from "@/shared/model/store/calendar";
import { TDeviceTypes } from "@/shared/model/types/common";
import { TMonthsDays } from "@/shared/model/types/search";

export const DatePickerGate = createGate<TDeviceTypes>();

const workerFx = createEffect((payload: TWorkerPayload) => {
  return new Promise<TMonthsDays>((resolve) => {
    const worker = new Worker(
      new URL("../lib/calendarWorker.js", import.meta.url),
    );

    worker.postMessage(payload);

    worker.onmessage = (
      e: MessageEvent<{
        result: TWorkerResponse;
      }>,
    ) => {
      resolve(e.data.result);
      worker.terminate();
    };
  });
});

split({
  source: workerFx.doneData,
  match: DatePickerGate.state,
  cases: {
    desktop: [setMonthDays, disableCalendarLoader],
    mobile: [setMobileMonths, disableCalendarLoader],
  },
});

sample({
  source: {
    days: $dataForCalculateAvailableDays,
    device: DatePickerGate.state,
  },
  fn: ({ days, device }) => ({
    type: device,
    data: days,
  }),
  target: [workerFx, enableCalendarLoader],
});
