export const styles = {
  text: {
    textAlign: "center",
    color: "content.secondary",
  },

  qrImageWrapper: {
    width: "190px",
    height: "195px",
    padding: "10px",
    marginBottom: "12px",
    borderRadius: "12px",
    backgroundColor: "dominant.white",
  },
};
