import Image from "next/image";
import { FC } from "react";

import { Box, SxProps, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { ListBlock } from "../list-block";
import { QrBlock } from "../qr-block";
import { styles } from "./styles";

import { DOWNLOAD_APP_QR } from "@/entities/app-downloading-block/lib/constants";

import PhonesImage from "@/public/img/common/appDownloadImage.png";

type TProps = {
  wrapperSx?: SxProps;
};

export const DownloadAppBlock: FC<TProps> = ({ wrapperSx = {} }) => {
  const t = useTranslations();

  return (
    <Box sx={{ ...styles.wrapper, ...wrapperSx }}>
      <Box sx={styles.phonesImageWrapper}>
        <Image
          width={398}
          height={313}
          src={PhonesImage}
          alt={t("MOBILE_APP")}
        />
      </Box>
      <Box sx={styles.textBlockWrapper}>
        <Typography component="h2" sx={styles.title}>
          {t("MOBILE_APP")}
        </Typography>
        <ListBlock />
      </Box>
      <Box sx={styles.qrBlockWrapper}>
        <QrBlock imageSrc={DOWNLOAD_APP_QR.qrBlockOnMain} />
      </Box>
    </Box>
  );
};
