import { FC, memo, useState, useCallback } from "react";

import { Box, Alert } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { DepartureCities } from "../DepartureCities/DepartureCities";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import {
  $departure,
  $isDepartureError,
  resetDepartureError,
} from "@/shared/model/store/departure";
import { MobileSearchBox, SwipeableDrawer } from "@/shared/ui";

type TProps = {
  isLoading: boolean;
};

export const MobileDeparture: FC<TProps> = memo(({ isLoading }) => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const t = useTranslations();

  const [departure, isError, resetDepartureErrorFn] = useUnit([
    $departure,
    $isDepartureError,
    resetDepartureError,
  ]);

  const [isContentOpen, setContentOpen] = useState(false);

  const handleOpenContent = useCallback(() => {
    sendDataLayerEventFn({
      event: "navTourmenuClick",
      data: {
        clickText: "Откуда",
      },
    });

    if (isError) {
      resetDepartureErrorFn();
    }

    setContentOpen(true);
  }, [isError, resetDepartureErrorFn, sendDataLayerEventFn]);

  const handleCloseContent = useCallback(() => setContentOpen(false), []);

  return (
    <>
      <MobileSearchBox
        isError={isError}
        subtitle={"Казахстан"}
        fieldName={t("FROM_2")}
        title={departure?.name}
        onClick={handleOpenContent}
      />

      <SwipeableDrawer
        isOpen={isContentOpen}
        title={t("SELECT_CITY")}
        onOpen={handleOpenContent}
        onClose={handleCloseContent}
      >
        <Box sx={styles.wrapper}>
          <DepartureCities isLoading={isLoading} onClose={handleCloseContent} />
          <Condition match={isError}>
            <Alert severity="error" sx={{ marginTop: "60%" }}>
              {t("FIRST_SELECT_DESTINATION")}
            </Alert>
          </Condition>
        </Box>
      </SwipeableDrawer>
    </>
  );
});
