import { FC } from "react";

import { Box, SxProps } from "@mui/material";

import { ProgressCollapse } from "../progress-collapse";
import { styles } from "./styles";

import { SEARCH_TYPE } from "@/entities/map/lib/constants";

type TProps = {
  wrapperSx: SxProps;
  isLoading: boolean;
  children: JSX.Element;
  Map: null | JSX.Element;
  Header: null | JSX.Element;
  childrenWrapperSx: SxProps;
};

export const SearchPageContentWrapper: FC<TProps> = ({
  Map,
  Header,
  children,
  wrapperSx,
  isLoading,
  childrenWrapperSx,
}) => {
  return (
    <Box sx={wrapperSx}>
      {Header}
      <Box sx={styles.wrapper}>
        <ProgressCollapse isVisible={isLoading} />

        <Box sx={styles.searchWrapper}>
          <Box
            component="div"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            data={SEARCH_TYPE}
            sx={childrenWrapperSx}
          >
            {children}
          </Box>

          {Map}
        </Box>
      </Box>
    </Box>
  );
};
