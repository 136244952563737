import { createApi, createStore } from "effector";

import { TArrivalLocation } from "../../types/header-menu";
import { ARRIVAL_STEPS } from "../../types/search";

export const $arrival = createStore<null | TArrivalLocation>(null);
export const $isError = createStore<boolean>(false);
export const $regions = createStore<null | string[]>([]);
export const $arrivalStep = createStore<ARRIVAL_STEPS>(0);
export const $arrivalFilterByRegion = createStore<string>("");
export const $arrivalFilterByCountry = createStore<string>("");
export const $isArrivalMobileContentOpen = createStore(false);
export const $isArrivalContentOpen = createStore(false);

export const { openArrivalMobileContent, closeArrivalMobileContent } =
  createApi($isArrivalMobileContentOpen, {
    openArrivalMobileContent: () => true,
    closeArrivalMobileContent: () => false,
  });

export const { openArrivalContent, closeArrivalContent } = createApi(
  $isArrivalContentOpen,
  {
    openArrivalContent: () => true,
    closeArrivalContent: () => false,
  },
);

export const { setArrival, resetArrival } = createApi($arrival, {
  resetArrival: () => null,
  setArrival: (_, payload: null | TArrivalLocation) => payload,
});

export const { setArrivalError, resetArrivalError } = createApi($isError, {
  setArrivalError: () => true,
  resetArrivalError: () => false,
});

export const { setRegions, setAllRegionsSelected, setAllRegionsDeselected } =
  createApi($regions, {
    setAllRegionsSelected: () => [],
    setAllRegionsDeselected: () => null,
    setRegions: (_, payload: string[]) => payload,
  });

export const { setArrivalRegionsStep, setArrivalCountriesStep } = createApi(
  $arrivalStep,
  {
    setArrivalRegionsStep: () => ARRIVAL_STEPS.REGIONS,
    setArrivalCountriesStep: () => ARRIVAL_STEPS.COUNTRIES,
  },
);

export const { setArrivalFilterByRegion, resetArrivalFilterByRegion } =
  createApi($arrivalFilterByRegion, {
    resetArrivalFilterByRegion: () => "",
    setArrivalFilterByRegion: (_, payload: string) => payload,
  });

export const { setArrivalFilterByCountry, resetArrivalFilterByCountry } =
  createApi($arrivalFilterByCountry, {
    resetArrivalFilterByCountry: () => "",
    setArrivalFilterByCountry: (_, payload: string) => payload,
  });

export const { setRegion } = createApi($regions, {
  setRegion: (state, payload: string) => {
    const id = payload;

    if (!state || !state.length) {
      return [id];
    }

    if (state.includes(id)) {
      return state.filter((region) => region !== id);
    }

    return [...state, id];
  },
});
