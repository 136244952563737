import { SxProps } from "@mui/material";

export const styles = {
  controlLabel: {
    margin: 0,
    width: "100%",
    color: "content.secondary",

    "& .MuiButtonBase-root": {
      padding: "2px",
      marginRight: "6px",
    },
  } as SxProps,
};
