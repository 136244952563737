import { FC, memo } from "react";

import { useUnit } from "effector-react";

import { $filteredDepartures } from "../../model";
import { styles } from "./styles";

import {
  resetArrival,
  setAllRegionsSelected,
  setArrivalCountriesStep,
} from "@/shared/model/store/arrival";
import { $searchType } from "@/shared/model/store/common";
import { $departure, setDeparture } from "@/shared/model/store/departure";
import { TDepartureLocation } from "@/shared/model/types/header-menu";
import { ESearchType } from "@/shared/model/types/search";
import { List, ListItem } from "@/shared/ui";

type TProps = {
  isLoading: boolean;
  onClose: () => void;
};

export const DepartureCities: FC<TProps> = memo(
  ({ isLoading = false, onClose = () => {} }) => {
    const searchType = useUnit($searchType);

    const [
      filteredDepartures,
      departure,

      setDepartureFn,
      resetArrivalFn,
      setAllRegionsSelectedFn,
      setArrivalCountriesStepFn,
    ] = useUnit([
      $filteredDepartures,
      $departure,

      setDeparture,
      resetArrival,
      setAllRegionsSelected,
      setArrivalCountriesStep,
    ]);

    const handleListItemClick = (departure: TDepartureLocation) => {
      setDepartureFn(departure);

      if (searchType !== ESearchType.Hotel) {
        resetArrivalFn();
      }

      setAllRegionsSelectedFn();
      setArrivalCountriesStepFn();

      onClose();
    };

    return (
      <List sx={[styles.contentStyles, styles.list]}>
        {filteredDepartures?.map((item) => (
          <ListItem
            key={item.id}
            value={item.name}
            disabled={isLoading}
            onClick={() => handleListItemClick(item)}
            selected={Boolean(departure && departure.id === item.id)}
          >
            {item.name}
          </ListItem>
        ))}
      </List>
    );
  },
);
