import { FC } from "react";

import { useUnit } from "effector-react";

import { DesktopCalendar } from "../DesktopCalendar";
import { MobileCalendar } from "../MobileCalendar";

import { useSearchBreakpoints } from "@/shared/lib/hooks";
import { $searchType } from "@/shared/model/store/common";

export const Calendar: FC = () => {
  const searchType = useUnit($searchType);

  const { isPhone } = useSearchBreakpoints({ type: searchType });

  if (isPhone) {
    return <MobileCalendar />;
  }

  return <DesktopCalendar />;
};
