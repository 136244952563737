import { FC, memo, useMemo, useCallback } from "react";

import { Box, Button, Divider, SxProps, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { $filteredArrivalsByRegion } from "../../model";
import { ArrivalRegionsSearch } from "../arrival-regions-search";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import {
  $arrival,
  $regions,
  setRegion,
  setRegions,
  setAllRegionsSelected,
  $arrivalFilterByRegion,
  setAllRegionsDeselected,
  setArrivalFilterByRegion,
  resetArrivalFilterByRegion,
} from "@/shared/model/store/arrival";
import { List, CustomCheckbox } from "@/shared/ui";

type TProps = {
  isPhone: boolean;
  isLoading: boolean;
  onClose: () => void;
  sectionStyles: SxProps;
};

export const ArrivalRegions: FC<TProps> = memo(
  ({ onClose, isPhone = true, isLoading = false, sectionStyles = {} }) => {
    const t = useTranslations();

    const [filterInputValue, arrival, selectedRegions, filteredRegions] =
      useUnit([
        $arrivalFilterByRegion,
        $arrival,
        $regions,
        $filteredArrivalsByRegion,
      ]);

    const isSelectedRegionsOfArrayType = Array.isArray(selectedRegions);
    const isSelectedRegionsEmptyArray =
      isSelectedRegionsOfArrayType && selectedRegions.length === 0;

    const isAllRegionsSelected = useMemo(
      () =>
        isSelectedRegionsOfArrayType &&
        (selectedRegions.length === arrival?.locations?.length ||
          isSelectedRegionsEmptyArray),
      [
        arrival?.locations?.length,
        isSelectedRegionsEmptyArray,
        isSelectedRegionsOfArrayType,
        selectedRegions?.length,
      ],
    );

    const resetRegionSelection = useCallback(() => {
      if (
        !selectedRegions ||
        (isSelectedRegionsOfArrayType &&
          selectedRegions.length > 0 &&
          selectedRegions.length !== arrival?.locations?.length)
      ) {
        setAllRegionsSelected();
      } else {
        setAllRegionsDeselected();
      }
    }, [
      arrival?.locations?.length,
      isSelectedRegionsOfArrayType,
      selectedRegions,
    ]);

    const arrivalRegionText = useMemo(() => {
      if (filterInputValue) {
        return t("REGION_NOT_FOUND");
      } else if (filteredRegions) {
        return t("NO_AVAILABLE_REGIONS_TO_SELECT");
      } else {
        return t("FIRST_SELECT_COUNTRY");
      }
    }, [filterInputValue, filteredRegions, t]);

    const handleRegionClick = useCallback(
      (id: string) => {
        if (
          // if backend response has a single region option
          arrival?.locations?.length === 1 &&
          isSelectedRegionsEmptyArray
        ) {
          setAllRegionsDeselected();
          return;
        }

        if (isAllRegionsSelected) {
          const regions =
            arrival?.locations
              .filter((reg) => reg.id !== id)
              ?.map((r) => r.id) ?? [];

          setRegions(regions);
        } else {
          setRegion(id);
        }

        resetArrivalFilterByRegion();
      },
      [arrival?.locations, isAllRegionsSelected, isSelectedRegionsEmptyArray],
    );

    const handleSetFilter = useCallback((value: string) => {
      setArrivalFilterByRegion(value);
    }, []);

    return (
      <Box sx={sectionStyles} id="arrival-modal-regions-onboarding">
        <Condition match={!isPhone}>
          <Box sx={styles.sectionHeader}>
            <Typography component="p" sx={styles.title}>
              {t("REGION")}
            </Typography>
            <Condition match={!!arrival?.locations?.length}>
              <ArrivalRegionsSearch
                value={filterInputValue}
                setValue={handleSetFilter}
              />
            </Condition>
          </Box>
        </Condition>

        <Condition match={!!filteredRegions?.length}>
          <List as="div" sx={[styles.list, styles.list.region]}>
            <Condition match={filterInputValue === ""}>
              <CustomCheckbox
                disabled={isLoading}
                label={t("ALL_REGIONS")}
                sx={styles.checkboxLabel}
                checked={isAllRegionsSelected}
                onChange={() => resetRegionSelection()}
              />
              <Divider />
            </Condition>

            {filteredRegions?.map(({ id = "", name = "" }, idx) => (
              <CustomCheckbox
                label={name}
                key={`${id}-${idx}`}
                disabled={isLoading}
                sx={styles.checkboxLabel}
                onChange={() => handleRegionClick(id)}
                checked={selectedRegions?.includes(id) || isAllRegionsSelected}
              />
            ))}
          </List>
        </Condition>

        <Condition match={!filteredRegions?.length}>
          <Typography sx={styles.arrivalRegionText}>
            {arrivalRegionText}
          </Typography>
        </Condition>

        {!isPhone && (
          <Box sx={styles.regionFooter}>
            <Button
              onClick={onClose}
              sx={styles.button}
              variant="contained"
              disabled={!arrival || !selectedRegions}
            >
              {t("APPLY")}
            </Button>
          </Box>
        )}
      </Box>
    );
  },
);
