import { FC, memo } from "react";

import { RefreshRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { ArrivalHotels } from "../arrival-hotels";
import { FilterCheckboxWrapper } from "../filters";
import { FilterPriceWrapper } from "../filters/filter-price-wrapper";
import { styles } from "./styles";

import { updateSearch } from "@/widgets/search/tour-search-form/model";

import { Condition } from "@/shared/lib/condition";
import { SearchFiltersMounted } from "@/shared/model/onboardings/search-filters-onboarding";
import {
  $filtersList,
  resetSelectedFilters,
} from "@/shared/model/store/filters";
import {
  resetSelectedMaxPrice,
  resetSelectedMinPrice,
} from "@/shared/model/store/prices";
import { resetSearchHotelsQuery } from "@/shared/model/store/search";
import { ScrollToTop, LoaderButton } from "@/shared/ui";

type TProps = {
  isLoading?: boolean;
  isDesktop?: boolean;
  isScrollToTopVisible: boolean;
};

export const TourFilters: FC<TProps> = memo(
  ({ isDesktop = false, isLoading = false, isScrollToTopVisible }) => {
    const t = useTranslations();

    useGate(SearchFiltersMounted, {
      isDesktop,
    });

    const [
      filtersList,
      resetSearchHotelsQueryFn,
      resetSelectedFiltersFn,
      resetSelectedMaxPriceFn,
      resetSelectedMinPriceFn,
      updateSearchFn,
    ] = useUnit([
      $filtersList,
      resetSearchHotelsQuery,
      resetSelectedFilters,
      resetSelectedMaxPrice,
      resetSelectedMinPrice,
      updateSearch,
    ]);

    const handleFilterReset = () => {
      // TODO: might've been better to move this logic into model
      resetSearchHotelsQueryFn();
      resetSelectedFiltersFn();
      resetSelectedMaxPriceFn();
      resetSelectedMinPriceFn();
      updateSearchFn();
    };

    return (
      <Box sx={styles.filters} id="search-filters-onboarding">
        <Box sx={styles.wrapper}>
          <ArrivalHotels />
        </Box>
        <FilterPriceWrapper
          expanded
          title={t("BUDGET")}
          isDesktop={isDesktop}
        />
        {filtersList?.map(({ id, code, name, options }) => (
          <FilterCheckboxWrapper
            id={id}
            key={id}
            expanded
            code={code}
            title={name}
            options={options}
            isDesktop={isDesktop}
          />
        ))}
        <LoaderButton
          variant="outlined"
          loading={isLoading}
          sx={styles.resetFilters}
          onClick={handleFilterReset}
          customIcon={<RefreshRounded sx={styles.resetIcon} />}
        >
          <Typography>{t("RESET_FILTERS")}</Typography>
        </LoaderButton>
        <Condition match={isScrollToTopVisible}>
          <ScrollToTop />
        </Condition>
      </Box>
    );
  },
);
