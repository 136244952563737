import { FC, memo } from "react";

import { useUnit } from "effector-react";

import { DesktopArrival } from "./desktop-arrival";
import { MobileArrival } from "./mobile-arrival";

import { useSearchBreakpoints } from "@/shared/lib/hooks";
import { getArrivalDataQuery } from "@/shared/model/api/search-form";
import { $searchType } from "@/shared/model/store/common";
import { ESearchType } from "@/shared/model/types/search";

export const Arrival: FC = memo(() => {
  const searchType = useUnit($searchType);

  const { isPhone } = useSearchBreakpoints({ type: searchType });

  const { pending: isLoading } = useUnit(getArrivalDataQuery);

  if (isPhone) {
    return <MobileArrival isLoading={isLoading} />;
  }

  return (
    <DesktopArrival
      isLoading={isLoading}
      fullInputWidth={searchType === ESearchType.Hotel}
    />
  );
});
