import { FC, memo } from "react";

import { useUnit } from "effector-react";

import { DesktopQuantity } from "../DesktopQuantity";
import { MobileQuantity } from "../MobileQuantity";

import { useSearchBreakpoints } from "@/shared/lib/hooks";
import { $searchType } from "@/shared/model/store/common";

export const Quantity: FC = memo(() => {
  const searchType = useUnit($searchType);

  const { isPhone } = useSearchBreakpoints({ type: searchType });

  if (isPhone) {
    return <MobileQuantity />;
  }

  return <DesktopQuantity />;
});
