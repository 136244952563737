import { ESearchType } from "@/shared/model/types/search";

export const TOOLTIP_DEFAULT_TEXT = "Сначала выберите направление";
export const TOOLTIP_CALENDAR_TEXT = "SELECT_TOUR_DATES";
export const TOOLTIP_REGIONS_TEXT = "Выберите минимум один регион";

export enum EInputTypes {
  Arrival = "arrival",
  Quantity = "quantity",
  Calendar = "calendar",
  Departure = "departure",
}

export const TYPES_FOR_GROUPED_ACTUALIZE_RESPONSE = [
  ESearchType.Main,
  ESearchType.Constructor,
];

export const SEARCH_BUTTON_ID_ON_SEARCH = "search_page_search_btn_test";
export const SEARCH_BUTTON_ID_ON_MAIN = "main_page_search_btn_test";
export const SEARCH_BUTTON_ID_ON_HOTEL = "hotel_page_search_btn_test";
