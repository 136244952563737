import isArray from "lodash/isArray";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";

// TODO: fsd
import {
  ErrorTypes,
  MAX_AGE_OF_INFANT,
} from "@/entities/search/Quantity/lib/constants";

import { setArrivalError } from "@/shared/model/store/arrival";
import { setCalendarError } from "@/shared/model/store/calendar";
import { setDepartureError } from "@/shared/model/store/departure";
import { setQuantityError } from "@/shared/model/store/quantity";
import { BACKEND_KEYS } from "@/shared/model/types/common";

type TIncomingData = {
  [BACKEND_KEYS.nights]?: number;
  [BACKEND_KEYS.adults]?: number;
  [BACKEND_KEYS.arrival]?: string;
  [BACKEND_KEYS.children]?: number;
  [BACKEND_KEYS.departure]?: string;
  [BACKEND_KEYS.rangeEndDay]?: string;
  [BACKEND_KEYS.rangeEndNight]?: number;
  [BACKEND_KEYS.rangeStartDay]?: string;
  [BACKEND_KEYS.rangeStartNight]?: number;
  [BACKEND_KEYS.childrenAges]?: (null | number)[];
  [BACKEND_KEYS.selectedRegionIds]?: null | string[];
};

export const isRouterValueFilled = (value: unknown): value is string =>
  isString(value) && Boolean(value);

export const checkIfDepatureFilled = (
  departure: unknown,
): departure is string => {
  return isString(departure) && !!departure;
};

export const checkIfArrivalFilled = (arrival: unknown): arrival is string => {
  return isString(arrival) && !!arrival;
};

export const checkIfArrivalRegionsValid = (
  regions: unknown,
): regions is string[] => {
  return isArray(regions) && regions.every((region) => isString(region));
};

export const checkIfNightsValid = (
  numberOfNights: unknown,
): numberOfNights is number => {
  return isNumber(numberOfNights) && numberOfNights > 0;
};

export const checkIfStartDataValid = (
  startDate: unknown,
): startDate is string => {
  return isString(startDate) && !!startDate;
};

export const checkIfEndDateValid = (endDate: unknown): endDate is string => {
  return isString(endDate) && !!endDate;
};

export const checkIfAdultsValid = (adults: unknown): adults is number => {
  return isNumber(adults) && adults > 0 && adults <= 8;
};

export const checkIfChildrenValid = (children: unknown): children is number => {
  return isNumber(children) && children >= 0;
};

export const checkIfChildrenAgesValid = (
  childrenAges: unknown,
  children: unknown,
) =>
  !Array.isArray(childrenAges) ||
  (childrenAges.every((age) => age !== null) &&
    childrenAges.length === children);

export const checkAmountOfInfants = (adults: number, childrenAges: unknown) => {
  if (!isArray(childrenAges)) {
    return true;
  }

  const numberOfInfants =
    childrenAges.filter(
      (age) => typeof age === "number" && age < MAX_AGE_OF_INFANT,
    ).length || 0;

  return numberOfInfants <= adults;
};

export const checkIfRouterValuesValid = (data: TIncomingData) => {
  const isDepValid = isRouterValueFilled(data[BACKEND_KEYS.departure]);
  const isArrValid = isRouterValueFilled(data[BACKEND_KEYS.arrival]);
  const isRegIdsValid = checkIfArrivalRegionsValid(
    data[BACKEND_KEYS.selectedRegionIds],
  );

  const isNightsStartValid = checkIfNightsValid(
    data[BACKEND_KEYS.rangeStartNight],
  );
  const isNightsEndValid = checkIfNightsValid(data[BACKEND_KEYS.rangeEndNight]);
  const isTotalNightsValid = checkIfNightsValid(data[BACKEND_KEYS.nights]);

  const isStartValid = isRouterValueFilled(data[BACKEND_KEYS.rangeStartDay]);
  const isEndValid = isRouterValueFilled(data[BACKEND_KEYS.rangeEndDay]);

  const isAdultsValid = checkIfAdultsValid(data[BACKEND_KEYS.adults]);
  const isChildrenValid = checkIfChildrenValid(data[BACKEND_KEYS.children]);

  const isChildrenAgesValid = checkIfChildrenAgesValid(
    data[BACKEND_KEYS.childrenAges],
    data[BACKEND_KEYS.children],
  );

  const isInfantsFieldsValid = checkAmountOfInfants(
    data[BACKEND_KEYS.adults] ?? 0,
    data[BACKEND_KEYS.childrenAges],
  );

  const separateFieldsValidity = {
    [BACKEND_KEYS.arrival]: isArrValid,
    [BACKEND_KEYS.adults]: isAdultsValid,
    [BACKEND_KEYS.departure]: isDepValid,
    [BACKEND_KEYS.rangeEndDay]: isEndValid,
    [BACKEND_KEYS.children]: isChildrenValid,
    [BACKEND_KEYS.nights]: isTotalNightsValid,
    [BACKEND_KEYS.rangeStartDay]: isStartValid,
    [BACKEND_KEYS.rangeEndNight]: isNightsEndValid,
    [BACKEND_KEYS.selectedRegionIds]: isRegIdsValid,
    [BACKEND_KEYS.childrenAges]: isChildrenAgesValid,
    [BACKEND_KEYS.rangeStartNight]: isNightsStartValid,
  };

  const validityByGroups = {
    isDepartureValid: isDepValid,
    isArrivalValid: isArrValid && isRegIdsValid,
    isQuantityValid: isAdultsValid && isChildrenValid,
    isCalendarValid:
      isTotalNightsValid &&
      isEndValid &&
      isNightsEndValid &&
      isStartValid &&
      isNightsStartValid,
  };

  const isFormValid =
    !Object.values(validityByGroups).some((v) => !v) &&
    isInfantsFieldsValid &&
    isChildrenAgesValid;

  const dispatchErrors = () => {
    if (!validityByGroups.isDepartureValid) {
      setDepartureError();
    }

    if (!validityByGroups.isArrivalValid) {
      setArrivalError();
    }

    if (!validityByGroups.isCalendarValid) {
      setCalendarError();
    }

    if (!validityByGroups.isQuantityValid) {
      setQuantityError(ErrorTypes.Quantity);
    }

    if (!isChildrenAgesValid && data[BACKEND_KEYS.children] !== 0) {
      setQuantityError(ErrorTypes.ChildrenAges);
    }

    if (!isInfantsFieldsValid) {
      setQuantityError(ErrorTypes.Infants);
    }
  };

  return {
    isFormValid,
    dispatchErrors,
    validityByGroups,
    separateFieldsValidity,
  };
};
