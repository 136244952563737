export const styles = {
  title: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
  },

  textBlock: {
    rowGap: "2px",
    display: "flex",
    maxWidth: "150px",
    flexDirection: "column",
  },

  subTitle: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "content.secondary",
  },

  button: {
    height: "auto",
    fontWeight: 500,
    fontSize: "12px",
    marginLeft: "auto",
    lineHeight: "16px",
    padding: "8px 16px",
    letterSpacing: "0.2px",
  },

  logoWrapper: {
    width: "100%",
    height: "48px",
    display: "flex",
    maxWidth: "48px",
    borderRadius: "12px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "accent.brand.primary",
  },
};
