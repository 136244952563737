import { FC } from "react";

import { Stack, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { ArrivalSkeleton } from "../arrival-skeleton";
import { styles } from "./styles";

import { SearchFormNotFound } from "@/entities/search/search-form-not-found";

import { Condition } from "@/shared/lib/condition";
import { priceFormatter } from "@/shared/lib/helpers";
import { useCustomTheme } from "@/shared/lib/hooks";
import { TArrivalLocation } from "@/shared/model/types/header-menu";
import { Image, ListLabel, ArrivalItem } from "@/shared/ui";

type TProps = {
  isLoading?: boolean;
  arrivals?: TArrivalLocation[];
  arrival?: null | TArrivalLocation;
  onClick: (item: TArrivalLocation) => void;
};

export const ArrivalListContent: FC<TProps> = ({
  onClick,
  arrival,
  arrivals,
  isLoading,
}) => {
  const theme = useCustomTheme();
  const t = useTranslations();

  if (isLoading) {
    return Array.from({ length: 6 }, (_, index) => (
      <ArrivalSkeleton key={index} />
    ));
  }

  if (!arrivals?.length) {
    return <SearchFormNotFound />;
  }

  return (
    <>
      <ListLabel theme={theme}>{t("ALL")}</ListLabel>

      {arrivals?.map((item) => {
        return (
          <ArrivalItem
            theme={theme}
            key={item.id}
            disabled={isLoading}
            onClick={() => onClick(item)}
            id={`arrival_${item.code}_test`}
            selected={item.code === arrival?.code}
          >
            <Image
              width={60}
              height={50}
              rounded="sm"
              alt={item.name}
              src={item.cover}
              imageWrapperSx={styles.imageWrapper}
            />
            <Stack spacing={1}>
              <Typography sx={styles.itemMainText}>{item.name}</Typography>
              <Condition match={Boolean(item.price)}>
                <Typography sx={styles.itemPriceText}>
                  {t("PRICE_FROM", {
                    amount: priceFormatter(item.price),
                  })}
                </Typography>
              </Condition>
            </Stack>
          </ArrivalItem>
        );
      })}
    </>
  );
};
