import { ChangeEvent } from "react";

import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { updateSort } from "@/widgets/search/search-page-content/model";

import { $sortList, $selectedSortValue } from "@/shared/model/store/sort";
import { ESortListValue } from "@/shared/model/types/sort";
import { RadioSelect } from "@/shared/ui";

export const Sort = () => {
  const t = useTranslations();
  const [selectedSortValue, sortList, updateSortFn] = useUnit([
    $selectedSortValue,
    $sortList,
    updateSort,
  ]);

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateSortFn(event.target.value as ESortListValue);
  };

  return (
    <RadioSelect
      id="sort_list_test"
      onChange={handleSortChange}
      value={selectedSortValue ?? ""}
      popoverStyles={styles.sortSelect}
      inputWrapperStyles={{
        height: "32px",
      }}
      options={
        sortList?.map(({ value, label }) => ({
          value,
          label: t(label),
        })) ?? []
      }
    />
  );
};
