import { FC, useCallback } from "react";

import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/kk";
import "dayjs/locale/ru";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { Trigger } from "../Calendar/CalendarTrigger";
import { FormattedDate } from "../Calendar/FormattedDate";
import { Datepicker } from "../Datepicker";
import { styles, tourDetailsStyles, defaultPopoverStyles } from "./styles";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { mainOnboarding } from "@/shared/model/onboardings/main-onboarding";
import {
  openCalendarContent,
  closeCalendarContent,
  $isCalendarContentOpen,
} from "@/shared/model/store/calendar";
import { $searchType } from "@/shared/model/store/common";
import { ESearchType } from "@/shared/model/types/search";
import { Popover } from "@/shared/ui";

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);

export const DesktopCalendar: FC = () => {
  const [isContentOpen, closeContentFn, openContentFn] = useUnit([
    $isCalendarContentOpen,
    closeCalendarContent,
    openCalendarContent,
  ]);

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const t = useTranslations();

  const handleOpenPopover = useCallback(() => {
    sendDataLayerEventFn({
      event: "navTourmenuClick",
      data: {
        clickText: "Дата вылета",
      },
    });

    if (mainOnboarding.isActive()) {
      mainOnboarding.show("calendar-modal");
    }

    openContentFn();
  }, [openContentFn, sendDataLayerEventFn]);

  const handleClose = () => {
    if (!mainOnboarding.isActive()) {
      closeContentFn();
    }
  };

  const handleApply = () => {
    if (mainOnboarding.isActive()) {
      mainOnboarding.show("quantity");
    }

    handleClose();
  };

  const searchType = useUnit($searchType);
  const isTourDetailsPage = searchType === ESearchType.Hotel;

  return (
    <Popover
      open={isContentOpen}
      closePopover={handleClose}
      fullInputWidth={isTourDetailsPage}
      trigger={<Trigger open={isContentOpen} onClick={handleOpenPopover} />}
      popoverStyles={
        isTourDetailsPage ? tourDetailsStyles : defaultPopoverStyles
      }
    >
      <Box sx={styles.wrapper} id="calendar-modal-onboarding">
        <Box sx={styles.header}>
          <Typography sx={styles.headerTitle}>
            {t("SELECT_TOUR_DATES")}
          </Typography>
        </Box>
        <Box sx={styles.calendarWrapper}>
          <Datepicker />
        </Box>
        <Box sx={styles.footer}>
          <FormattedDate />

          <Button variant="contained" onClick={handleApply}>
            {t("APPLY")}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
