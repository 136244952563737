import { FC } from "react";

import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { closeModal, $isModalOpen } from "../../model/app-bonuses-modal";
import { styles } from "./styles";

import { QrBlock } from "@/entities/app-downloading-block";
import { DOWNLOAD_APP_QR } from "@/entities/app-downloading-block/lib/constants";

import { POPUP_DEEPLINK_URL } from "@/shared/config";
import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import { DialogWrapper, AppDownloadButtons } from "@/shared/ui";

export const AppBonusesModal: FC = () => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [closeModalFn, isModalOpen] = useUnit([closeModal, $isModalOpen]);

  const handleCloseModal = () => {
    closeModalFn();
  };

  const handleDownloadClick = () => {
    if (typeof window !== "undefined") {
      window.open(POPUP_DEEPLINK_URL as string, "_blank");
    }
  };

  return (
    <DialogWrapper
      open={isModalOpen}
      onClose={handleCloseModal}
      WrapperProps={styles.wrapperProps}
      PaperProps={{ sx: styles.paperPropsSx }}
    >
      <IconButton
        aria-label="close"
        sx={styles.closeIcon}
        onClick={handleCloseModal}
      >
        <Close />
      </IconButton>

      <Box sx={styles.contentWrapper}>
        <Condition match={!isPhone}>
          <Box sx={styles.qrBlockWrapper}>
            <QrBlock
              textSx={styles.qrText}
              imageWrapperSx={styles.qrImageWrapper}
              imageSrc={DOWNLOAD_APP_QR.qrBonusesModal}
            />
          </Box>
        </Condition>
        <Box sx={styles.textWrapper}>
          <Typography component="h2" sx={styles.title}>
            {t("WANT_TO_GET_BONUSES_FOR_REGISTERING")}
          </Typography>

          <Typography sx={styles.subtitle}>
            {t("DOWNLOAD_THE_APP_REGISTER_AND_TRAVEL_WITH_MAXIMUM_BENEFIT")}
          </Typography>

          <Typography sx={[styles.subtitle, styles.smallSubtitle]}>
            {t("ACCUMULATED_BONUS_CAN_ONLY_BE_USED_WHEN_PURCHASING_TOUR")}
          </Typography>
          <Condition match={!isPhone}>
            <AppDownloadButtons />
          </Condition>
          <Condition match={isPhone}>
            <Button
              variant="outlined"
              sx={styles.downloadButton}
              onClick={handleDownloadClick}
            >
              {t("DOWNLOAD_THE_APP")} Kaztour.kz
            </Button>
          </Condition>
        </Box>
      </Box>
    </DialogWrapper>
  );
};
