import { SxProps } from "@mui/material";
import { mainTheme } from "shared/ui/styles";

export const styles = {
  imageWrapper: { height: "45px" },

  itemMainText: {
    fontSize: "16px",
    lineHeight: "16px",
  },

  itemPriceText: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "grey.light",
  },

  title: {
    fontWeight: 500,
    lineHeight: "24px",
    [mainTheme.breakpoints.down("smd")]: {
      fontSize: "20px",
    },
  },

  sectionHeader: {
    padding: "16px",

    [mainTheme.breakpoints.down("smd")]: {
      gap: "10px",
      display: "flex",
      fontSize: "20px",
      fontWeight: "500",
      alignItems: "center",
      lineHeight: "23.2px",
      color: "content.primary",
    },
  },

  section: {
    display: "flex",
    flexDirection: "column",
    [mainTheme.breakpoints.down("smd")]: {
      border: "none",
    },

    [mainTheme.breakpoints.up("smd")]: {
      overflow: "hidden",
    },

    "&[disabled]": {
      userSelect: "none",
      pointerEvents: "none",
      color: "content.grey-200",
    },
  } as SxProps,

  list: {
    overflowY: "auto",
    overflowX: "hidden",
    padding: "2px 16px 16px",
    overscrollBehavior: "contain",
    ...mainTheme.scrollbars.default,

    [mainTheme.breakpoints.down("smd")]: {
      padding: "0 16px 16px",
    },
    ".Mui-disabled": {
      "&:hover": {
        opacity: "1",
      },
    },

    region: {
      [mainTheme.breakpoints.down("smd")]: {
        marginBottom: "75px",
      },
    },

    ".MuiFormControlLabel-root": {
      transition: "0.2s",
      "&:hover": {
        opacity: "0.7",
      },
    },
  },
};
