export const styles = {
  mapButtonText: {
    color: "white",
    fontSize: "14px",
  },

  foundText: {
    fontSize: "14px",
    color: "content.secondary",
  },

  buttonBox: {
    left: "50%",
    zIndex: "1000",
    bottom: "30px",
    position: "fixed",
    transform: "translateX(-50%)",
  },

  searchHeader: {
    display: "flex",
    fontSize: "18px",
    alignItems: "center",
    margin: "24px 0 12px",
    justifyContent: "space-between",
  },

  mapButton: {
    gap: "6px",
    height: "48px",
    width: "123px",
    display: "flex",
    borderRadius: "36px",
    alignItems: "center",
    flexDirection: "row",
    padding: "9px 16px 9px 12px",
    boxShadow: "0px 2px 4px rgba(74, 85, 104, 0.12)",
    backgroundColor: "rgba(4, 23, 44, 0.88) !important",

    "& :hover": {
      boxShadow: "0px 2px 4px rgba(74, 85, 104, 0.12)",
      backgroundColor: "rgba(4, 23, 44, 0.88) !important",
    },
  },
};
