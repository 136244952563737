/* eslint-disable perfectionist/sort-objects */
import { sample, createApi, createStore } from "effector";
import { produce } from "immer";

import {
  ErrorTypes,
  MAX_ADULTS,
  MAX_CHILDREN,
} from "@/entities/search/Quantity/lib/constants";

import { DEFAULT_AMOUNT_OF_ADULTS } from "@/shared/lib/constants";

export const $adults = createStore<number>(DEFAULT_AMOUNT_OF_ADULTS);
export const $children = createStore<number>(0);
export const $childrenAges = createStore<(null | number)[]>([]);
export const $errorType = createStore<null | ErrorTypes>(null);
export const $isQuantityContentOpen = createStore(false);

export const { openQuantityContent, closeQuantityContent } = createApi(
  $isQuantityContentOpen,
  {
    openQuantityContent: () => true,
    closeQuantityContent: () => false,
  },
);

export const { setQuantityError, resetQuantityError } = createApi($errorType, {
  resetQuantityError: () => null,
  setQuantityError: (_, payload: ErrorTypes) => payload,
});

export const { setAdults, decrementAdults, incrementAdults } = createApi(
  $adults,
  {
    setAdults: (_, payload: number) => payload,
    decrementAdults: (adults) => {
      if (adults > 1) {
        return adults - 1;
      }

      return adults;
    },
    incrementAdults: (adults) => {
      if (adults < MAX_ADULTS) {
        return adults + 1;
      }

      return adults;
    },
  },
);

export const { setChildren, decrementChildren, incrementChildren } = createApi(
  $children,
  {
    setChildren: (_, payload: number) => payload,
    decrementChildren: (children) => {
      if (children > 0) {
        return children - 1;
      }

      return children;
    },
    incrementChildren: (children) => {
      if (children < MAX_CHILDREN) {
        return children + 1;
      }

      return children;
    },
  },
);

sample({
  clock: [decrementChildren],
  source: {
    children: $children,
    childrenAges: $childrenAges,
  },
  fn: ({ childrenAges }) => {
    return produce(childrenAges, (draft) => {
      draft.pop();
    });
  },
  target: $childrenAges,
});

sample({
  clock: [incrementChildren],
  source: {
    children: $children,
    childrenAges: $childrenAges,
  },
  fn: ({ childrenAges }) => {
    return produce(childrenAges, (draft) => {
      draft.push(null);
    });
  },
  target: $childrenAges,
});

export const { setChildrenAges, setChildrenAgesByIndex } = createApi(
  $childrenAges,
  {
    setChildrenAges: (_, payload: number[]) => payload,
    setChildrenAgesByIndex: (
      state,
      { index, value }: { index: number; value: number },
    ) => {
      if (!state) {
        return [value];
      }

      return produce(state, (draft) => {
        draft[index] = value;
      });
    },
  },
);
