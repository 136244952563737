import { FC, memo } from "react";

import { Collapse } from "@mui/material";

import { styles } from "./styles";

import { TourSearchProgressBar } from "@/shared/ui";

type TProps = {
  isVisible: boolean;
};

export const ProgressCollapse: FC<TProps> = memo(({ isVisible }) => {
  return (
    <Collapse unmountOnExit in={isVisible} easing="linear">
      <TourSearchProgressBar sx={styles.progress} />
    </Collapse>
  );
});
