import { FC } from "react";

import { Box, SvgIcon, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { $$headingCaptionModel } from "../model";
import { styles, altStyles, defaultStyles } from "./styles";

import HomeCreditLogo from "@/public/icons/banks/homecredit.svg";

export const HomePageTexts: FC = () => {
  const t = useTranslations();

  const { type, title, notice, subtitle, installment } = useUnit(
    $$headingCaptionModel.$normalizedHeadingData,
  );

  switch (type) {
    case "homecredit":
      return (
        <Box sx={styles.wrapper}>
          <Typography variant="h1" sx={styles.title}>
            {title}
          </Typography>
          <Typography variant="h2" sx={altStyles.subtitle}>
            {subtitle}
          </Typography>
          <Box sx={altStyles.tagsWrapper}>
            <Box sx={altStyles.logoWrapper}>
              <SvgIcon
                inheritViewBox
                sx={altStyles.svg}
                alt="Home Credit Bank"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                component={HomeCreditLogo}
              />
            </Box>
            {installment && (
              <Typography sx={altStyles.installment}>
                0-0-{installment}
              </Typography>
            )}
          </Box>
          {notice && <Typography sx={altStyles.licence}>{notice}</Typography>}
        </Box>
      );
    default:
      return (
        <Box sx={subtitle ? styles.wrapper : defaultStyles.wrapper}>
          <Typography
            variant="h1"
            sx={subtitle ? styles.title : defaultStyles.title}
          >
            {title || t("FALL_IN_LOVE_WITH_TRAVEL_WITH_US")}
          </Typography>
          {subtitle && (
            <Typography variant="h2" sx={styles.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      );
  }
};
