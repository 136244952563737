import { FC, useState, useCallback } from "react";

import { Button } from "@mui/material";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { FormattedDate } from "../Calendar";
import { Datepicker } from "../Datepicker";

import { useGetCalendarTexts } from "@/shared/lib/hooks";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { $arrival, setArrivalError } from "@/shared/model/store/arrival";
import {
  $endDate,
  $startDate,
  $isCalendarError,
  resetCalendarError,
} from "@/shared/model/store/calendar";
import { $searchType } from "@/shared/model/store/common";
import { ESearchType } from "@/shared/model/types/search";
import { SwipeableDrawer, MobileSearchBox } from "@/shared/ui";

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);

export const MobileCalendar: FC = () => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const t = useTranslations();
  const [openMobileContent, setOpenMobileContent] = useState(false);

  const [
    arrival,
    searchType,
    startDate,
    endDate,
    isError,

    setArrivalErrorFn,
    resetCalendarErrorFn,
  ] = useUnit([
    $arrival,
    $searchType,
    $startDate,
    $endDate,
    $isCalendarError,

    setArrivalError,
    resetCalendarError,
  ]);

  const handleOpenMobileContent = useCallback(() => {
    sendDataLayerEventFn({
      event: "navTourmenuClick",
      data: {
        clickText: "Дата вылета",
      },
    });

    if (!arrival && searchType !== ESearchType.Hotel) {
      setArrivalErrorFn();
      return;
    }

    if (isError) {
      resetCalendarErrorFn();
    }

    setOpenMobileContent(true);
  }, [
    arrival,
    isError,
    resetCalendarErrorFn,
    searchType,
    sendDataLayerEventFn,
    setArrivalErrorFn,
  ]);

  const handleCloseMobileContent = useCallback(
    () => setOpenMobileContent(false),
    [],
  );

  const handlePopoverClose = useCallback(() => {
    handleCloseMobileContent();
  }, [handleCloseMobileContent]);

  const { datesText, nightsText } = useGetCalendarTexts({
    endDate,
    startDate,
    isInput: true,
  });

  return (
    <>
      <MobileSearchBox
        isError={isError}
        subtitle={nightsText}
        fieldName={t("TOUR_DATE")}
        fieldId="calendar_field_test"
        onClick={handleOpenMobileContent}
        title={startDate && endDate ? datesText : undefined}
      />

      <SwipeableDrawer
        isOpen={openMobileContent}
        title={t("SELECT_TOUR_DATES")}
        onOpen={handleOpenMobileContent}
        onClose={handleCloseMobileContent}
        Footer={
          <>
            <FormattedDate />
            <Button fullWidth variant="contained" onClick={handlePopoverClose}>
              {t("APPLY")}
            </Button>
          </>
        }
      >
        <Datepicker />
      </SwipeableDrawer>
    </>
  );
};
