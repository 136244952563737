import {
  ErrorTypes,
  TOOLTIP_INFANTS_TEXT,
  TOOLTIP_CHILD_AGE_TEXT,
  TOOLTIP_MAX_QUANTITY_TEXT,
} from "./constants";

export const getErrorText = (errorType: null | ErrorTypes) => {
  switch (errorType) {
    case ErrorTypes.Quantity:
      return TOOLTIP_MAX_QUANTITY_TEXT;
    case ErrorTypes.ChildrenAges:
      return TOOLTIP_CHILD_AGE_TEXT;
    case ErrorTypes.Infants:
      return TOOLTIP_INFANTS_TEXT;
    default:
      return false;
  }
};
