import { SxProps } from "@mui/material";

import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

const DESKTOP_HEADER_PATH = "/img/common/bg-header-desk.png";
const MOBILE_HEADER_PATH = "/img/common/bg-header-mob.png";

export const styles = {
  buttonSx: {
    width: "100px",
  },

  kawai: {
    zIndex: 1,
    margin: "-30px auto 30px",
  },

  formWrapper: {
    padding: "16px",
    borderRadius: "16px",
    backgroundColor: "#1789DB",
  },

  wrapper: {
    maxWidth: "75%",
    position: "relative",
    margin: "0 auto 40px",

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
  },

  form: {
    display: "flex",
    padding: "10px",
    columnGap: "8px",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "dominant.white",
  },

  inputs: {
    display: "flex",
    columnGap: "8px",
    maxWidth: "955px", // input width (183px) * 5 (count of input) + gap between inputs(40px)
    position: "relative",
    alignItems: "center",
    width: "-webkit-fill-available",

    "& > *": {
      flex: 1,
    },
  },

  highLight: {
    fontWeight: 700,
    textShadow: "none",
    padding: "6px 16px",
    borderRadius: "12px",
    display: "inline-block",
    color: "accent.brand.primary",
    backgroundColor: "accent.brand.lightest",

    [mainTheme.breakpoints.down("sm")]: {
      padding: "4px 8px",
    },
  },

  mainWrapper: {
    display: "flex",
    paddingTop: "138px",
    marginBottom: "36px",
    position: "relative",
    paddingBottom: "42px",
    flexDirection: "column",
    color: "content.primary",
    justifyContent: "flex-end",

    [mainTheme.breakpoints.down("smd")]: {
      paddingTop: 0,
      marginBottom: "0",
      paddingBottom: "43px",
    },
  },
};

export const mobileStyles = {
  inner: {
    padding: "20px 12px",
    borderRadius: "16px",
    backgroundColor: "#1789DB",
  },

  textWrapper: {
    zIndex: 0,
    width: "100%",
    padding: "0 16px",
    margin: "42px auto 32px",
  },

  buttonSx: {
    marginBottom: "24px",

    [mainTheme.breakpoints.down("smd")]: {
      marginBottom: "0",
    },
  },

  wrapper: {
    zIndex: 0,
    width: "100%",
    padding: "0 16px",
    borderRadius: "8px",
    position: "relative",
    margin: "-12px auto 0",
    backgroundColor: "unset",
  },

  inputs: {
    display: "flex",
    marginBottom: "8px",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },
};

export const backgroundStyles = {
  image: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: "0 0 24px 24px",

    // eslint-disable-next-line perfectionist/sort-objects
    background: `url("${DESKTOP_HEADER_PATH}") bottom no-repeat`,
    // eslint-disable-next-line perfectionist/sort-objects
    backgroundSize: "cover",

    [mainTheme.breakpoints.down("lg")]: {
      marginTop: "-66px",
      height: "calc(100% + 66px)",
    },

    [mainTheme.breakpoints.down("md")]: {
      width: "100%",
      height: "107%",
      borderRadius: "0 0 16px 16px",
      background: `url("${MOBILE_HEADER_PATH}") 30% no-repeat`,
      // eslint-disable-next-line perfectionist/sort-objects
      backgroundSize: "cover",
    },

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down("sm")]: {
      height: "110%",
    },

    "&::after": {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      clear: "both",
      content: '""',
      display: "block",
      position: "absolute",
      background:
        "linear-gradient(180deg,rgba(2, 24, 49, 0.4) 0%,rgba(17, 58, 104, 0) 100%)",
    },
  } as SxProps,
};
