import { mainTheme } from "@/shared/ui/styles/theme";

export const styles = {
  mainContentTablet: {
    width: "stretch",
  },

  mainContent: {
    maxWidth: "586px",
    flex: "2 0 586px",
  },

  searchWrapper: {
    width: "100%",
    display: "flex",
    columnGap: "16px",
  },

  mobileSearchWrapper: {
    columnGap: "0",
    display: "flex",
    width: "stretch",
  },

  mobileMap: {
    width: "900px",
    height: "100%",

    [mainTheme.breakpoints.down("smd")]: {
      height: "100%",
      maxWidth: "100%",
    },
  },

  map: {
    top: "92px",
    height: "1024px",
    flex: "0 2 100%",
    overflow: "hidden",
    maxHeight: "100vh",
    position: "sticky",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: "content.grey-100",

    [mainTheme.breakpoints.between(756, 980)]: {
      width: "stretch",
    },
  },
};
