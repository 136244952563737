import { FC, memo } from "react";

import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { HotelsSearchGate } from "../../model";
import { HotelCard } from "../hotel-card";

import { Condition } from "@/shared/lib/condition";
import {
  $filteredTours,
  $searchHotelsQuery,
  setSearchHotelsQuery,
  resetSearchHotelsQuery,
} from "@/shared/model/store/search";
import { List, SearchInput } from "@/shared/ui";

export const MobileArrivalHotels: FC = memo(() => {
  const t = useTranslations();

  useGate(HotelsSearchGate);

  const [
    filteredTours,
    searchHotelsQuery,

    resetSearchHotelsQueryFn,
    setSearchHotelsQueryFn,
  ] = useUnit([
    $filteredTours,
    $searchHotelsQuery,

    resetSearchHotelsQuery,
    setSearchHotelsQuery,
  ]);

  return (
    <>
      <SearchInput
        showClearIcon
        sxInput={null}
        disabled={false}
        buttonProps={{}}
        onClick={() => null}
        searchButtonText={""}
        showSearchButton={false}
        value={searchHotelsQuery}
        placeholder={t("FIND_HOTEL")}
        onChange={setSearchHotelsQueryFn}
        onClear={resetSearchHotelsQueryFn}
      />

      <Condition
        match={Boolean(
          Number(filteredTours?.data?.length) > 0 && searchHotelsQuery,
        )}
      >
        <List
          sx={{
            padding: 0,
          }}
        >
          {filteredTours?.data?.map((tour) => (
            <HotelCard
              withImage
              hotel={tour.hotel}
              key={`hotel-${tour.hotel.id}`}
              onClick={setSearchHotelsQueryFn}
            />
          ))}
        </List>
      </Condition>
    </>
  );
});
