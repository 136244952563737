import { createGate } from "effector-react";
import { persist } from "effector-storage/query";

import { $searchHotelsQuery } from "@/shared/model/store/search";

export const HotelsSearchGate = createGate();

persist({
  key: "hotel",
  store: $searchHotelsQuery,
  pickup: HotelsSearchGate.open,
});
