import { FC } from "react";

import { styles } from "./styles";

import { CustomCheckbox } from "@/shared/ui";

import UncheckedIcon from "@/public/icons/inputs/unchecked.svg";

type TProps = {
  id?: string;
  isChecked: boolean;
  isDesktop: boolean;
  handleSelectionChanged: (v: string) => void;
  item: {
    id: string;
    name: string;
  };
};

export const FilterCheckboxes: FC<TProps> = ({
  id,
  item,
  isDesktop,
  isChecked,
  handleSelectionChanged,
}) => {
  return (
    <CustomCheckbox
      id={id}
      size="small"
      label={item.name}
      checked={isChecked}
      sx={styles.controlLabel}
      onChange={() => handleSelectionChanged(item.id)}
      unCheckedIcon={!isDesktop ? <UncheckedIcon /> : null}
    />
  );
};
