import { mainTheme } from "shared/ui/styles";

export const styles = {
  wrapper: {
    display: "flex",
    padding: "0 16px",
    overflowY: "auto",
    overflowX: "hidden",
    flexDirection: "column",

    [mainTheme.breakpoints.down("md")]: {
      padding: "0",
      height: "100%",
      justifyContent: "space-between",
    },
  },
};
