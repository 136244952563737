import { FC, memo } from "react";

import { Box, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/kk";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";

import { CalendarLoader } from "./CalendarLoader";
import { DesktopDatePickerPaneWrapper } from "./DesktopDatePickerPaneWrapper";
import { MobileDatePickerPaneWrapper } from "./MobileDatePickerPaneWrapper";
import { datepickerStyles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export const Datepicker: FC = memo(() => {
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("smd"));

  return (
    <Box sx={datepickerStyles} className={isDesktop ? "expanded" : ""}>
      {!isDesktop ? (
        <MobileDatePickerPaneWrapper />
      ) : (
        <DesktopDatePickerPaneWrapper />
      )}

      <CalendarLoader />
    </Box>
  );
});
