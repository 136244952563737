import { FC, useCallback } from "react";

import { Button, SxProps } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { ArrivalCountries } from "../arrival-countries";
import { ArrivalRegions } from "../arrival-regions";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { mainOnboarding } from "@/shared/model/onboardings/main-onboarding";
import {
  $isError,
  $regions,
  $arrival,
  $arrivalStep,
  resetArrivalError,
  setArrivalCountriesStep,
  openArrivalMobileContent,
  closeArrivalMobileContent,
  $isArrivalMobileContentOpen,
} from "@/shared/model/store/arrival";
import { ARRIVAL_STEPS } from "@/shared/model/types/search";
import { MobileSearchBox, SwipeableDrawer } from "@/shared/ui";

const gridSectionWithBorder = {
  ...styles.section,
  ...styles.sectionBorder,
} as SxProps;

type TProps = {
  isLoading: boolean;
};

export const MobileArrival: FC<TProps> = ({ isLoading }) => {
  const [sendDataLayerEventFn, setArrivalCountriesStepFn] = useUnit([
    sendDataLayerEvent,
    setArrivalCountriesStep,
  ]);
  const t = useTranslations();

  const [
    isError,
    arrival,
    arrivalRegions,
    arrivalStep,
    isMobileContentOpen,
    openMobileContentFn,
    closeMobileContentFn,
    resetArrivalErrorFn,
  ] = useUnit([
    $isError,
    $arrival,
    $regions,
    $arrivalStep,
    $isArrivalMobileContentOpen,
    openArrivalMobileContent,
    closeArrivalMobileContent,
    resetArrivalError,
  ]);

  // TODO: normalize data
  const regionsNames = arrival?.locations
    .filter((item) => arrivalRegions?.includes(item.id))
    .map((item) => item.name)
    .join(", ");

  const handleOpenContent = useCallback(() => {
    sendDataLayerEventFn({
      event: "navTourmenuClick",
      data: {
        clickText: "Куда",
      },
    });

    if (isError) {
      resetArrivalErrorFn();
    }

    openMobileContentFn();
  }, [isError, openMobileContentFn, resetArrivalErrorFn, sendDataLayerEventFn]);

  const handleClose = () => {
    if (mainOnboarding.isActive()) {
      mainOnboarding.show("calendar");
    }

    closeMobileContentFn();
  };

  return (
    <>
      <MobileSearchBox
        fieldName={t("WHERE_TO")}
        onClick={handleOpenContent}
        fieldId="arrival_field_test"
        isError={isError || !arrivalRegions}
        title={regionsNames || arrival?.name || ""}
        tooltipText={t(
          !arrivalRegions
            ? "SELECT_AT_LEAST_ONE_REGION"
            : "FIRST_SELECT_DESTINATION",
        )}
      />

      <SwipeableDrawer
        onOpen={handleOpenContent}
        isOpen={isMobileContentOpen}
        onClose={closeMobileContentFn}
        backButton={arrivalStep === ARRIVAL_STEPS.REGIONS}
        childrenWrapperSx={{ padding: 0, height: "100%" }}
        title={t(
          arrivalStep === ARRIVAL_STEPS.COUNTRIES
            ? "SELECT_COUNTRY"
            : "SELECT_REGION",
        )}
        onBackButtonClick={
          arrivalStep === ARRIVAL_STEPS.REGIONS
            ? setArrivalCountriesStepFn
            : closeMobileContentFn
        }
        Footer={
          <Button
            fullWidth
            variant="contained"
            onClick={handleClose}
            disabled={!arrival || !arrivalRegions}
          >
            {t("APPLY")}
          </Button>
        }
      >
        <Condition match={arrivalStep === ARRIVAL_STEPS.COUNTRIES}>
          <ArrivalCountries isPhone isLoading={isLoading} />
        </Condition>
        <Condition match={arrivalStep === ARRIVAL_STEPS.REGIONS}>
          <ArrivalRegions
            isPhone
            isLoading={isLoading}
            onClose={closeMobileContentFn}
            sectionStyles={gridSectionWithBorder}
          />
        </Condition>
      </SwipeableDrawer>
    </>
  );
};
