import { mainTheme } from "shared/ui/styles";

const dominantWhite = "dominant.white";

export const styles = {
  qrText: {
    color: dominantWhite,
    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "12px",
      textAlign: "left",
      lineHeight: "16px",
    },
  },

  wrapperProps: {
    "& .MuiDialog-container": {
      alignItems: "center",
    },
    "& .MuiDialog-paper": {
      margin: "15px",
      borderRadius: "24px",
    },
  },

  downloadButton: {
    padding: "8px",
    height: "32px",
    fontWeight: 500,
    fontSize: "12px",
    marginTop: "4px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
  },

  textWrapper: {
    rowGap: "12px",
    display: "flex",
    width: "fit-content",
    flexDirection: "column",
    [mainTheme.breakpoints.down("sm")]: {
      rowGap: "16px",
    },
  },

  closeIcon: {
    top: "16px",
    right: "16px",
    position: "absolute",
    color: dominantWhite,
    [mainTheme.breakpoints.down("sm")]: {
      top: "24px",
      right: "12px",
    },
  },

  smallSubtitle: {
    fontSize: "12px",
    lineHeight: "24px",
    color: "accent.brand.light",
    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },

  subtitle: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "24px",
    color: dominantWhite,
    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },

  title: {
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "38px",
    width: "fit-content",
    color: dominantWhite,
    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "28px",
    },
  },

  qrImageWrapper: {
    padding: "8px",
    width: "195px",
    height: "195px",
    overflow: "hidden",
    [mainTheme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      marginBottom: 0,
      flexBasis: "110px",
    },
  },

  contentWrapper: {
    width: "100%",
    display: "flex",
    columnGap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    [mainTheme.breakpoints.down("sm")]: {
      rowGap: "20px",
      flexDirection: "column-reverse",
    },
  },

  qrBlockWrapper: {
    display: "flex",
    maxWidth: "243px",
    alignItems: "center",
    flexDirection: "column",
    [mainTheme.breakpoints.down("sm")]: {
      maxWidth: "none",
      columnGap: "16px",
      padding: "9px 11px",
      flexDirection: "row",
    },
  },

  paperPropsSx: {
    maxWidth: "759px",
    minHeight: "415px",
    backgroundSize: "cover",
    width: "100% !important",
    padding: "72px 45px 60px",
    backgroundPosition: "right",
    backgroundImage: "url('/img/popupImages/bonusesPopup.jpg')",
    "& .MuiDialogContent-root": {
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    [mainTheme.breakpoints.down("sm")]: {
      maxWidth: "343px",
      paddingTop: "24px",
      minHeight: "272px",
      overflowY: "visible",
      padding: "32px 20px",
      backgroundImage: "url('/img/popupImages/bonusesPopupMob.jpg')",
      "& .MuiDialogContent-root": {
        alignItems: "start",
      },
    },
  },
};
