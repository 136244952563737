export const styles = {
  loader: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    bottom: 0,
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, 0.8)",
  },
};
