import { FC } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { DEEPLINK_URL } from "@/shared/config";

import KaztourLogo from "@/public/img/logos/WhiteLogoWithoutText.svg";

export const MobileAppBlock: FC = () => {
  const t = useTranslations();

  const handleClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.location.href = DEEPLINK_URL!;
  };

  return (
    <>
      <Box sx={styles.logoWrapper}>
        <KaztourLogo width="40px" height="40px" />
      </Box>
      <Box sx={styles.textBlock}>
        <Typography component="h2" sx={styles.title}>
          Kaztour.kz
        </Typography>
        <Typography sx={styles.subTitle}>
          {t("BOOK_TOURS_MOBILE_APP")}
        </Typography>
      </Box>
      <Button sx={styles.button} variant="contained" onClick={handleClick}>
        {t("OPEN")}
      </Button>
    </>
  );
};
