import { SxProps } from "@mui/material";
import { mainTheme } from "shared/ui/styles";

export const styles = {
  contentWrapper: {
    rowGap: "16px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },

  inputsWrapper: {
    display: "flex",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },

  tourSearchMobileInfoWrapper: {
    display: "flex",
    border: "1px solid",
    borderRadius: "8px",
    padding: "8px 12px",
    alignItems: "center",
    borderColor: "content.grey-200",
    justifyContent: "space-between",
    backgroundColor: "background.default",
  },

  wrapper: {
    zIndex: 1,
    padding: 0,
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",

    [mainTheme.breakpoints.down("searchMobile")]: {
      display: "none",
    },

    [mainTheme.breakpoints.between("searchMobile", "md")]: {
      width: "100%",
      height: "100%",
    },
  },

  mainWrapper: {
    display: "flex",
    margin: "24px 0",
    flexDirection: "column",
    color: "content.primary",
    justifyContent: "flex-end",

    [mainTheme.breakpoints.up("searchMobile")]: {
      top: "0",
      zIndex: "1",
      position: "sticky",
    },

    [mainTheme.breakpoints.down("searchMobile")]: {
      border: "none",
      padding: "0 16px",
      margin: "0 -16px",
      backgroundColor: "white",
    },
  } as SxProps,

  inputs: {
    display: "flex",
    columnGap: "8px",
    position: "relative",
    alignItems: "center",
    width: "-webkit-fill-available",

    "& > *": {
      flex: 1,
    },

    [mainTheme.breakpoints.up("md")]: {
      display: "flex",
      width: "stretch",
      flexDirection: "row",
      scrollbarWidth: "none",
      justifyContent: "flex-start",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  } as SxProps,

  stickyWrapper: {
    marginTop: "0",
    marginBottom: "0",
    paddingTop: "24px",
    background: "white",

    paddingBottom: "24px",
    borderRadius: "0px 0px 16px 16px",
    paddingLeft: "calc(((100vw - 100%) / 2) - 9px)",
    paddingRight: "calc(((100vw - 100%) / 2) - 9px)",

    boxShadow: "0px 1px 0px rgba(164, 179, 190, 0.25)",
    marginLeft: "calc(-1 * ((100vw - 100%) / 2) + 9px)",
    marginRight: "calc(-1 * ((100vw - 100%) / 2) + 9px)",
  },
};
