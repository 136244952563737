/* eslint-disable perfectionist/sort-objects */
import { sample, combine } from "effector";
import { createGate } from "effector-react";

import { getDepartureDataQuery } from "@/shared/model/api/search-form";
import { $defaultDeparture } from "@/shared/model/store/default-departure";
import {
  $departure,
  setDeparture,
  $departureFilter,
} from "@/shared/model/store/departure";
import { TDepartureLocation } from "@/shared/model/types/header-menu";

export const DepartureDataGate = createGate<undefined>();

sample({
  clock: DepartureDataGate.open,
  target: getDepartureDataQuery.refresh,
});

sample({
  clock: getDepartureDataQuery.finished.success,
  source: $defaultDeparture,
  filter: $departure.map((v) => !v),
  fn: (defaultDeparture) => defaultDeparture as unknown as TDepartureLocation,
  target: setDeparture,
});

export const $filteredDepartures = combine(
  getDepartureDataQuery.$data,
  $departureFilter,
  (departures, filter) => {
    return departures?.data?.filter((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase()),
    );
  },
  {
    skipVoid: false,
  },
);
