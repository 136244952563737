import { FC } from "react";

import { Box, Skeleton } from "@mui/material";
import * as CSS from "csstype";

import { styles } from "./styles";

export const ArrivalSkeleton: FC = () => {
  return (
    <Box sx={styles.wrapper as CSS.Properties}>
      <Skeleton height={49} variant="rounded" />
      <Skeleton height={49} variant="rounded" />
    </Box>
  );
};
