/* eslint-disable perfectionist/sort-objects */
import { offset } from "@floating-ui/dom";
import { sample, createEvent, createEffect } from "effector";
import { createGate } from "effector-react";
import Cookies from "js-cookie";
import Shepherd from "shepherd.js";

import { sendDataLayerEvent } from "../analytics";
import {
  $defaultDeparture,
  $previousDefaultDeparture,
} from "../store/default-departure";

import { startMainModalTimerLogic } from "@/widgets/global-modals";

import { IS_PROD_ENV } from "@/shared/config";
import { SEARCH_FILTERS_ONBOARDING_COOKIE } from "@/shared/lib/constants";
import { getTranslateFn } from "@/shared/lib/helpers/locales";
import {
  commonStepsConfig,
  setupProgressIndicator,
} from "@/shared/lib/helpers/onboardings";

const startOnboardingLogic = createEvent();
export const SearchFiltersMounted = createGate<{
  isDesktop: boolean;
}>();

const startOnboardingFx = createEffect(async () => {
  if (!searchFiltersOnboarding) return;

  const t = await getTranslateFn();
  searchFiltersOnboarding.addSteps(getSteps(t)).start();

  ["complete", "cancel"].forEach((event) =>
    searchFiltersOnboarding.once(event, () => {
      Cookies.set(SEARCH_FILTERS_ONBOARDING_COOKIE, "true", { expires: 15 });

      startMainModalTimerLogic();
    }),
  );
});

sample({
  clock: SearchFiltersMounted.open,
  target: startOnboardingLogic,
});

sample({
  clock: startOnboardingLogic,
  source: {
    defaultDeparture: $defaultDeparture,
    isDesktop: SearchFiltersMounted.state.map(({ isDesktop }) => isDesktop),
  },
  filter: ({ isDesktop, defaultDeparture }) =>
    IS_PROD_ENV &&
    defaultDeparture !== null &&
    isDesktop &&
    Cookies.get(SEARCH_FILTERS_ONBOARDING_COOKIE) !== "true" &&
    !searchFiltersOnboarding.isActive(),
  target: startOnboardingFx,
});

sample({
  clock: $defaultDeparture.updates,
  source: {
    previousDeparture: $previousDefaultDeparture,
    searchFiltersMounted: SearchFiltersMounted.status,
  },
  filter: ({ previousDeparture, searchFiltersMounted }) =>
    searchFiltersMounted && previousDeparture === null,
  target: startOnboardingLogic,
});

const getSteps = (t: (string: string) => string) => [
  {
    id: "search-filters",
    title: t("FILTERS"),
    attachTo: { on: "right-start", element: "#search-filters-onboarding" },
    text: t("FILTER_FOUND_TOURS_BY_PARAMETERS_IN_THIS_BLOCK"),
    arrow: false,
    when: {
      show: () => setupProgressIndicator(false),
    },
    buttons: [
      {
        type: "complete",
        text: t("FINISH"),
        classes: "shepherd-button-primary shepherd-button-next",
        action: () => {
          sendDataLayerEvent({
            event: "searchOnboarding",
          });

          searchFiltersOnboarding.complete();
        },
      },
    ],
    ...commonStepsConfig,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
  },
];

export const searchFiltersOnboarding = new Shepherd.Tour({
  exitOnEsc: true,
  useModalOverlay: true,
  keyboardNavigation: false,
  defaultStepOptions: {
    classes: "custom-onboarding",
    cancelIcon: {
      enabled: true,
    },
  },
});
