import { SortBy, SortOrder } from "../../common";

export enum ESortListValue {
  ASC_PRICE = "asc_price",
  POPULARITY = "popularity",
  DESC_PRICE = "desc_price",
  HOTEL_RATING = "hotel_rating",
}

export type TSortList = {
  label: string;
  value: ESortListValue;
};

export const SELECTED_SEARCH = {
  [ESortListValue.ASC_PRICE]: {
    sort_by: SortBy.PRICE,
    sort_order: SortOrder.ASC,
  },
  [ESortListValue.DESC_PRICE]: {
    sort_by: SortBy.PRICE,
    sort_order: SortOrder.DESC,
  },
  [ESortListValue.POPULARITY]: {
    sort_by: SortBy.POPULARITY,
    sort_order: SortOrder.DESC,
  },
  [ESortListValue.HOTEL_RATING]: {
    sort_order: SortOrder.DESC,

    sort_by: SortBy.HOTEL_RATING,
  },
};
