import { SxProps } from "@mui/material";

import { mainTheme } from "@/shared/ui/styles";

const commonStyles = {
  wrapper: {
    zIndex: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },

  title: {
    zIndex: 0,
    fontWeight: 500,
    lineHeight: "1em",
    fontSize: "3.5rem",
    textAlign: "center",
    letterSpacing: "0.2px",
    color: "dominant.white",
    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  },
};

export const styles = {
  span: {
    whiteSpace: "nowrap",
  },

  wrapper: {
    ...commonStyles.wrapper,

    [mainTheme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },

  title: {
    ...commonStyles.title,
    margin: "16px auto 16px",

    [mainTheme.breakpoints.down("smd")]: {
      fontSize: "32px",
      textAlign: "center",
      margin: "0 0 4px 0",
      lineHeight: "37.12px",
    },

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down(520)]: {
      maxWidth: "60%",
      fontSize: "24px",
      textAlign: "left",
      lineHeight: "28px",
    },

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down(350)]: {
      maxWidth: "80%",
    },
  } as SxProps,

  subtitle: {
    zIndex: 0,
    fontWeight: 500,
    maxWidth: "90%",
    fontSize: "39px",
    textAlign: "center",
    margin: "0 auto 36px",
    color: "dominant.white",
    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down("smd")]: {
      fontSize: "28px",
      textAlign: "center",
      margin: "0 0 24px 0",
      lineHeight: "37.12px",
    },

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "left",
      lineHeight: "22px",
      margin: "0 0 12px 0",
      paddingBottom: "12px",
    },
  } as SxProps,
};

export const altStyles = {
  svg: {
    width: "100%",
    height: "32px",
  },

  licence: {
    fontSize: "8px",
    lineHeight: "10px",
    margin: "4px 0 14px",
    color: "dominant.white",

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
  },

  wrapper: {
    zIndex: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",

    [mainTheme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },

  tagsWrapper: {
    height: "57px",
    display: "flex",
    columnGap: "6px",
    alignItems: "center",
    justifyContent: "center",

    [mainTheme.breakpoints.down("sm")]: {
      height: "37px",
      justifyContent: "flex-start",
    },
  },

  logoWrapper: {
    height: "100%",
    display: "flex",
    background: "white",
    padding: "6px 16px",
    alignItems: "center",
    borderRadius: "12px",
    justifyContent: "center",

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "60%",
    },
  },

  installment: {
    display: "flex",
    fontWeight: 700,
    fontSize: "39px",
    padding: "6px 16px",
    alignItems: "center",
    borderRadius: "12px",
    whiteSpace: "nowrap",
    background: "#DB0124",
    color: "dominant.white",
    justifyContent: "center",

    [mainTheme.breakpoints.down("sm")]: {
      height: "100%",
      fontSize: "19px",
    },
  },

  title: {
    zIndex: 0,
    fontWeight: 700,
    fontSize: "4rem",
    lineHeight: "1em",
    textAlign: "center",
    letterSpacing: "0.2px",
    color: "dominant.white",
    margin: "16px auto 16px",
    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",

    [mainTheme.breakpoints.down("smd")]: {
      fontSize: "32px",
      textAlign: "center",
      margin: "0 0 4px 0",
      lineHeight: "37.12px",
    },

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down("sm")]: {
      maxWidth: "60%",
      fontSize: "24px",
      textAlign: "left",
      lineHeight: "28px",
    },
  } as SxProps,

  subtitle: {
    zIndex: 0,

    fontWeight: 500,
    maxWidth: "90%",
    fontSize: "39px",
    textAlign: "center",
    margin: "0 auto 12px",
    color: "dominant.white",
    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down("smd")]: {
      fontSize: "28px",
      textAlign: "center",
      margin: "0 0 24px 0",
      lineHeight: "37.12px",
    },

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "left",
      lineHeight: "22px",
      margin: "0 0 12px 0",
      paddingBottom: "12px",
    },
  } as SxProps,
};

export const defaultStyles = {
  wrapper: {
    ...commonStyles.wrapper,
    mb: "24px",

    [mainTheme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },

  title: {
    ...commonStyles.title,
    width: "70%",
    textAlign: "center",

    [mainTheme.breakpoints.down("lg")]: {
      width: "80%",
    },

    [mainTheme.breakpoints.down("md")]: {
      width: "70%",
      fontSize: "56px",
    },

    [mainTheme.breakpoints.down("sm")]: {
      width: "80%",
      fontSize: "28px",
      textAlign: "left",
      lineHeight: "32px",
    },
  },
};
