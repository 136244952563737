import { mainTheme } from "shared/ui/styles";

export const styles = {
  phonesImageWrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",

    [mainTheme.breakpoints.down("smd")]: {
      display: "none",
    },
  },

  title: {
    margin: 0,
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "38px",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "24px",
      lineHeight: "28px",
    },
  },

  textBlockWrapper: {
    rowGap: "24px",
    display: "flex",
    margin: "39px 0",
    flexBasis: "416px",
    flexDirection: "column",

    [mainTheme.breakpoints.down("md")]: {
      rowGap: "15px",
      margin: "20px",
    },
  },

  qrBlockWrapper: {
    marginTop: "39px",
    flexBasis: "258px",
    paddingRight: "63px",

    [mainTheme.breakpoints.down("md")]: {
      marginTop: "20px",
    },

    [mainTheme.breakpoints.down("lg")]: {
      paddingRight: 0,
      flexBasis: "195px",
    },
  },

  wrapper: {
    display: "flex",
    paddingX: "50px",
    minHeight: "330px",
    borderRadius: "16px",
    justifyContent: "space-between",
    backgroundColor: "accent.brand.lightest",

    [mainTheme.breakpoints.down("md")]: {
      paddingX: "20px",
      columnGap: "10px",
      minHeight: "275px",
    },
  },
};
