import Image from "next/image";
import { FC } from "react";

import { Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { RoundedBorderContainer } from "@/shared/ui";

import flipFlopsInTheSand from "@/public/img/common/flipFlopsInTheSand.png";

export const SearchUninitialized: FC = () => {
  const t = useTranslations();

  return (
    <RoundedBorderContainer sx={styles.container}>
      <Typography sx={styles.title}>{t("TOURS_NOT_FOUND")}</Typography>
      <Typography sx={styles.text}>
        {t("PLEASE_FILL_FORM_ABOVE_TO_FIND_TOURS")}
      </Typography>
      <Image
        width={360}
        height={102}
        alt="tour not found"
        src={flipFlopsInTheSand}
        style={{
          height: "auto",
          maxWidth: "100%",
        }}
      />
    </RoundedBorderContainer>
  );
};
