"use client";
import { FC } from "react";
import Countdown from "react-countdown";

import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import { TEN_MINUTES } from "../../lib/constants";
import { LeadModal } from "./lead-modal";

import { useLocalStorage } from "@/shared/lib/hooks/useLocalStorage";

const CURRENT_TIME = Number(dayjs());
const id = uuidv4();

export const DelayedLeadModal: FC = () => {
  // sync modal timing within all tabs
  const [modalOpeningTime, setModalOpeningTime] = useLocalStorage<
    null | string | number
  >("modalOpeningTime", 0);

  // define when the modal was opened in order to arrange next opening
  const [lastTimeModalOpened, setLastTimeModalOpened] = useLocalStorage<
    null | string | number
  >("lastTimeModalOpened", 0);

  const [pageId, setPageId] = useLocalStorage("pageId", "");

  const wasModalOpenedMoreThan24HoursAgo = Boolean(
    dayjs().diff(lastTimeModalOpened, "hour") >= 12,
  );

  const shouldMountCountdown =
    !lastTimeModalOpened || pageId === id || wasModalOpenedMoreThan24HoursAgo;

  const wasModalOpened = pageId === id || wasModalOpenedMoreThan24HoursAgo;

  const handleUpdateOpeningTime = (v: number) => {
    setPageId(id);
    setLastTimeModalOpened(v);
  };

  const timer =
    modalOpeningTime && dayjs().diff(Number(modalOpeningTime), "second") <= 1
      ? Number(modalOpeningTime)
      : CURRENT_TIME + TEN_MINUTES;

  if (!shouldMountCountdown) {
    return null;
  }

  return (
    <Countdown
      date={timer}
      intervalDelay={1000}
      onComplete={() => setPageId(id)}
      onTick={(time) => {
        setModalOpeningTime(
          modalOpeningTime ? modalOpeningTime : CURRENT_TIME + time.total,
        );
      }}
      renderer={({ completed }) => {
        if (completed) {
          return (
            <LeadModal
              wasModalOpened={wasModalOpened}
              handleUpdateOpeningTime={handleUpdateOpeningTime}
            />
          );
        }

        return null;
      }}
    />
  );
};
