export const styles = {
  wrapper: {
    maxWidth: "calc(100% - 24px)",
  },
  title: {
    maxWidth: "90%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};
