export const styles = {
  wrapper: {
    width: "100%",
  },

  searchWrapper: {
    width: "100%",
    display: "flex",
    columnGap: "16px",
  },
};
