import { mainTheme } from "shared/ui/styles";

export const styles = {
  popperStyles: {
    width: "212px",
  },

  sxPopperInsideBox: {
    height: "52px",
  },

  inputWrapperStyles: {
    [mainTheme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  popoverStyles: {
    zIndex: 1200,
    width: "120%",
    minWidth: "270px",
    maxWidth: "300px",
  },
};
