import { memo } from "react";

import { Box, Skeleton, Typography } from "@mui/material";

import { styles } from "./styles";

export const Loader = memo(() => {
  return (
    <Box sx={styles.mainContentHeader}>
      <Typography sx={styles.foundToursText}>
        <Skeleton width={"150px"} />
      </Typography>

      <Skeleton width={"100px"} />
    </Box>
  );
});
