import { mainTheme } from "@/shared/ui/styles/theme";

export const styles = {
  foundToursText: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "content.secondary",
  },

  mainContentHeader: {
    display: "flex",
    marginBottom: "24px",
    alignItems: "center",
    justifyContent: "space-between",

    [mainTheme.breakpoints.between(758, 980)]: {
      marginBottom: "16px !important",
    },

    [mainTheme.breakpoints.down("smd")]: {
      marginBottom: 0,
      marginTop: "16px",
    },
  },
};
