import { FC, useState, useCallback } from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Slider, Collapse, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { updateSearch } from "@/widgets/search/tour-search-form/model";

import { Condition } from "@/shared/lib/condition";
import { priceFormatter } from "@/shared/lib/helpers";
import {
  $prices,
  $selectedMinPrice,
  $selectedMaxPrice,
  setSelectedMaxPrice,
  setSelectedMinPrice,
} from "@/shared/model/store/prices";
import { Input } from "@/shared/ui";

type TFilterPriceWrapper = {
  title: string;
  expanded: boolean;
  isDesktop?: boolean;
};

type TBudgetInput = {
  id: string;
  placeholder?: string;
  value: string | number;
  onChange: (arg: string) => void;
};

const SLIDER_STEP = 1000;

const BudgetInput: FC<TBudgetInput> = ({
  id,
  value,
  placeholder,
  onChange = () => null,
}) => {
  return (
    <Input
      id={id}
      onChange={onChange}
      value={String(value)}
      placeholder={placeholder}
      sxInput={styles.priceInputs.input}
    />
  );
};

export const FilterPriceWrapper: FC<TFilterPriceWrapper> = ({
  title = "",
  expanded = false,
  isDesktop = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const [
    selectedMinPrice,
    selectedMaxPrice,
    prices,
    setSelectedMinPriceFn,
    setSelectedMaxPriceFn,
    updateSearchFn,
  ] = useUnit([
    $selectedMinPrice,
    $selectedMaxPrice,
    $prices,
    setSelectedMinPrice,
    setSelectedMaxPrice,
    updateSearch,
  ]);
  const t = useTranslations();
  const minInputText = t("PRICE_FROM", {
    amount: priceFormatter(String(selectedMinPrice)),
  });
  const maxInputText = t("PRICE_TO", {
    amount: priceFormatter(String(selectedMaxPrice)),
  });

  const handleChange = useCallback(
    (_: Event, newValue: number | number[]) => {
      if (typeof newValue === "number") {
        return;
      }

      setSelectedMinPriceFn(newValue[0]);
      setSelectedMaxPriceFn(newValue[1]);

      updateSearchFn();
    },
    [setSelectedMaxPriceFn, setSelectedMinPriceFn, updateSearchFn],
  );

  const handleChangeMinPrice = (value: string) => {
    setSelectedMinPriceFn(value);
    updateSearchFn();
  };

  const handleChangeMaxPrice = (value: string) => {
    setSelectedMaxPriceFn(value);
    updateSearchFn();
  };

  return (
    <Box sx={styles.wrapper}>
      <Box
        sx={styles.header}
        onClick={() => setIsExpanded((prevState) => !prevState)}
      >
        <Typography sx={styles.title}>{title}</Typography>

        <Condition match={isDesktop}>
          <Box sx={isExpanded ? styles.expandedIcon : styles.expandIcon}>
            <KeyboardArrowDown fontSize="small" />
          </Box>
        </Condition>
      </Box>
      <Collapse in={isExpanded}>
        <Box sx={styles.content}>
          <Box sx={styles.priceInputs}>
            <BudgetInput
              value={selectedMinPrice}
              id="budget_min_input_test"
              placeholder={minInputText}
              onChange={handleChangeMinPrice}
            />
            <BudgetInput
              value={selectedMaxPrice}
              id="budget_max_input_test"
              placeholder={maxInputText}
              onChange={handleChangeMaxPrice}
            />
          </Box>

          <Box sx={styles.sliderWrapper}>
            <Slider
              max={prices?.max}
              min={prices?.min}
              sx={styles.slider}
              step={SLIDER_STEP}
              onChange={handleChange}
              value={[selectedMinPrice, selectedMaxPrice]}
            />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
