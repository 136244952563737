import { FC, memo, useCallback } from "react";

import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import {
  $endDate,
  $startDate,
  setHoveredDate,
  $maxNightsCount,
  resetHoveredDate,
} from "@/shared/model/store/calendar";
import { TDays } from "@/shared/model/types/search";
import { CustomTooltip } from "@/shared/ui";

type TProps = {
  day: TDays;
  children: null | JSX.Element;
};

const TOOLTIP_DELAY = 700;

export const DaysTooltip: FC<TProps> = memo(({ day, children }) => {
  const [
    startDate,
    endDate,
    maxNightsCount,

    setHoveredDateFn,
    resetHoveredDateFn,
  ] = useUnit([
    $startDate,
    $endDate,
    $maxNightsCount,

    setHoveredDate,
    resetHoveredDate,
  ]);

  const t = useTranslations();

  const getTooltipTitle = useCallback(
    (day: TDays) => {
      if (day.isAvailable) return;

      if (startDate && endDate) {
        return t("THERE_ARE_NO_TOURS_AVAILABLE_FOR_THIS_DATE");
      }

      if (
        startDate &&
        dayjs(day.date).isAfter(
          dayjs(startDate).add(maxNightsCount ?? 0, "day"),
        )
      ) {
        return t("MAXIMUM_TOUR_DURATION_SHOULD_BE_LESS_THAN_NIGHTS", {
          nights: maxNightsCount ?? 0,
        });
      }

      if (startDate && Math.abs(dayjs(day.date).diff(startDate, "day")) <= 2) {
        return t("MINIMUM_TOUR_DURATION_SHOULD_BE_MORE_THAN_NIGHTS", {
          nights: 3,
        });
      }

      return t("THERE_ARE_NO_TOURS_AVAILABLE_FOR_THIS_DATE");
    },
    [endDate, maxNightsCount, startDate, t],
  );

  const handleMouseOver = useCallback(
    (day: TDays, isDateDisabled: boolean) => {
      if (
        !startDate ||
        dayjs(startDate).isSame(day.date, "day") ||
        dayjs(day.date).isBefore(startDate) ||
        (startDate && endDate)
      ) {
        return;
      }

      if (!isDateDisabled) {
        setHoveredDateFn(day.date);
      }
    },
    [endDate, setHoveredDateFn, startDate],
  );

  const handleMouseLeave = useCallback(() => {
    resetHoveredDateFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTooltip
      arrow
      placement="top"
      enterTouchDelay={0}
      sx={styles.tooltip}
      enterDelay={TOOLTIP_DELAY}
      title={getTooltipTitle(day)}
      enterNextDelay={TOOLTIP_DELAY}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={() => handleMouseOver(day, !day.isAvailable)}
    >
      <Box>{children}</Box>
    </CustomTooltip>
  );
});
