import { combine } from "effector";

import { $arrival, $regions } from "@/shared/model/store/arrival";
import { $nights, $endDate, $startDate } from "@/shared/model/store/calendar";
import { $departure } from "@/shared/model/store/departure";
import {
  $adults,
  $children,
  $childrenAges,
} from "@/shared/model/store/quantity";
import { BACKEND_KEYS } from "@/shared/model/types/common";

export const $searchForm = combine(
  $adults,
  $children,
  $childrenAges,
  $departure,
  $arrival,
  $startDate,
  $endDate,
  $regions,
  $nights,
  (
    adults,
    children,
    childrenAges,
    departure,
    arrival,
    startDate,
    endDate,
    regions,
    nights,
  ) => ({
    [BACKEND_KEYS.nights]: nights,
    [BACKEND_KEYS.adults]: adults,
    [BACKEND_KEYS.children]: children,
    [BACKEND_KEYS.rangeEndDay]: endDate,
    [BACKEND_KEYS.rangeEndNight]: nights,
    [BACKEND_KEYS.rangeStartNight]: nights,
    [BACKEND_KEYS.rangeStartDay]: startDate,
    [BACKEND_KEYS.childrenAges]: childrenAges,
    [BACKEND_KEYS.selectedRegionIds]: regions,
    [BACKEND_KEYS.arrival]: arrival ? arrival.code : "",
    [BACKEND_KEYS.departure]: departure ? departure.code : "",
  }),
);
