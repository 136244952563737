/* eslint-disable perfectionist/sort-objects */
import { sample, createApi, createStore, createEvent } from "effector";
import { spread } from "patronum";

import {
  getInitialSearchQuery,
  getActualizedSearchQuery,
} from "../../api/search";
import { TPrices } from "../../types/tours";

export const $selectedMinPrice = createStore<number>(0);
export const $selectedMaxPrice = createStore<number>(0);
export const $prices = createStore<null | TPrices>(null);

export const resetSelectedMaxPrice = createEvent();
export const resetSelectedMinPrice = createEvent();
export const setSelectedMaxPrice = createEvent<string | number>();
export const setSelectedMinPrice = createEvent<string | number>();

sample({
  clock: resetSelectedMaxPrice,
  source: $prices,
  fn: (prices) => prices?.max ?? 0,
  target: $selectedMaxPrice,
});

sample({
  clock: resetSelectedMinPrice,
  source: $prices,
  fn: (prices) => prices?.min ?? 0,
  target: $selectedMinPrice,
});

$selectedMaxPrice.on(setSelectedMaxPrice, (_, payload) => Number(payload));
$selectedMinPrice.on(setSelectedMinPrice, (_, payload) => Number(payload));

export const { setPrices, resetPrices } = createApi($prices, {
  resetPrices: () => null,
  setPrices: (_, payload: TPrices) => payload,
});

export const DEBOUNCE_TIME = 500;

sample({
  clock: getInitialSearchQuery.finished.success,
  source: getInitialSearchQuery.$data,
  filter: (data) => !!data?.meta?.prices,
  fn: (data) => data?.meta?.prices as TPrices,
  target: setPrices,
});

sample({
  clock: getActualizedSearchQuery.finished.success,
  source: {
    prices: $prices,
    data: getActualizedSearchQuery.$data,
  },
  filter: ({ data, prices }) =>
    !!data?.meta?.prices?.min &&
    !!data?.meta?.prices?.max &&
    ((prices?.min === 0 && prices?.max === 0) || !prices),
  fn: ({ data }) => data?.meta?.prices as TPrices,
  target: setPrices,
});

sample({
  source: $prices.map((v) => v ?? {}),
  target: spread({
    min: $selectedMinPrice ?? 0,
    max: $selectedMaxPrice ?? 0,
  }),
});
