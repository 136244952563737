import { FC } from "react";

import PlaceIcon from "@mui/icons-material/Place";
import { Box, Button, Typography } from "@mui/material";
import { useUnit } from "effector-react";

import { styles } from "./styles";

import { openMobileMap } from "@/shared/model/store/common";
import { ScrollToTop } from "@/shared/ui";

export const MobileMapButton: FC = () => {
  const openMobileMapFn = useUnit(openMobileMap);

  return (
    <>
      <Box sx={styles.buttonBox}>
        <Button sx={styles.mapButton} onClick={openMobileMapFn}>
          <PlaceIcon htmlColor="white" />
          <Typography sx={styles.mapButtonText}>На карте</Typography>
        </Button>
      </Box>

      <ScrollToTop />
    </>
  );
};
