import { FC } from "react";

import { Tune } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { FilterCheckboxWrapper } from "../../filters";
import { FilterPriceWrapper } from "../../filters/filter-price-wrapper";
import { MobileArrivalHotels } from "../mobile-arrival-hotels";
import { styles } from "./styles";

import { updateSearch } from "@/widgets/search/tour-search-form/model";

import { Condition } from "@/shared/lib/condition";
import {
  $isMobileFilterDrawer,
  openMobileFilterDrawer,
  closeMobileFilterDrawer,
} from "@/shared/model/store/common";
import {
  $filtersList,
  resetSelectedFilters,
} from "@/shared/model/store/filters";
import {
  resetSelectedMaxPrice,
  resetSelectedMinPrice,
} from "@/shared/model/store/prices";
import {
  resetSelectedHotel,
  resetSearchHotelsQuery,
} from "@/shared/model/store/search";
import { SwipeableDrawer } from "@/shared/ui";

export const MobileFilter: FC = () => {
  const t = useTranslations();

  const [
    filtersList,

    resetSearchHotelsQueryFn,
    resetSelectedHotelFn,
    resetFiltersFn,
    resetMaxPriceFn,
    resetMinPriceFn,

    updateSearchFn,

    isMobileFilterDrawer,
    openMobileFilterDrawerFn,
    closeMobileFilterDrawerFn,
  ] = useUnit([
    $filtersList,

    resetSearchHotelsQuery,
    resetSelectedHotel,
    resetSelectedFilters,
    resetSelectedMaxPrice,
    resetSelectedMinPrice,

    updateSearch,

    $isMobileFilterDrawer,
    openMobileFilterDrawer,
    closeMobileFilterDrawer,
  ]);

  const handleFilterReset = () => {
    // TODO: might've been better to move this logic into model
    resetSearchHotelsQueryFn();
    resetSelectedHotelFn();
    resetFiltersFn();
    resetMaxPriceFn();
    resetMinPriceFn();
    updateSearchFn();
  };

  return (
    <>
      <Box
        onClick={openMobileFilterDrawerFn}
        sx={[styles.filter, { alignItems: "end" }]}
      >
        <Typography marginRight="5px">{t("FILTERS")}</Typography>
        <Tune sx={{ fontSize: "17px" }} />
      </Box>

      <SwipeableDrawer
        title={t("FILTERS")}
        footerSx={styles.buttons}
        isOpen={isMobileFilterDrawer}
        onOpen={openMobileFilterDrawerFn}
        onClose={closeMobileFilterDrawerFn}
        Footer={
          <>
            <Button fullWidth variant="outlined" onClick={handleFilterReset}>
              {t("RESET")}
            </Button>
            <Button
              fullWidth
              type="button"
              variant="contained"
              onClick={closeMobileFilterDrawerFn}
            >
              {t("APPLY")}
            </Button>
          </>
        }
      >
        <Box sx={styles.wrapper}>
          <MobileArrivalHotels />

          <FilterPriceWrapper expanded title={t("BUDGET")} />

          <Condition match={Boolean(filtersList.length)}>
            {filtersList.map(({ id, name, code, options }) => (
              <FilterCheckboxWrapper
                key={id}
                code={code}
                title={name}
                options={options}
              />
            ))}
          </Condition>
        </Box>
      </SwipeableDrawer>
    </>
  );
};
