export const styles = {
  content: {
    marginLeft: "6px",
  },

  icon: {
    color: "unset",
    fontSize: "18px",
  },

  geolocation: {
    fontSize: "14px",
    lineHeight: "16px",
  },

  deleteIcon: {
    fontSize: "15px",
    color: "dominant.white",
  },

  info: {
    fontSize: "12px",
    lineHeight: "16px",
    width: "max-content",
    color: "dominant.white",
  },

  geolocations: {
    gap: "4px",
    display: "flex",
    marginRight: "12px",
    marginBottom: "4px",
    whiteSpace: "nowrap",
    alignItems: "center",
  },

  card: {
    display: "flex",
    cursor: "pointer",
    transition: "0.2s",
    borderRadius: "8px",
    marginRight: "12px",
    padding: "10px 12px",
    position: "relative",
    background: "#1D96EC",
    color: "dominant.white",

    "&:hover": {
      opacity: "0.7",
    },
  },

  delete: {
    top: -10,
    right: -10,
    padding: "3px",
    display: "flex",
    cursor: "pointer",
    border: "1px solid",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "transparent",
    backgroundColor: "#2B6BB2",

    "&:hover": {
      opacity: 100,
      backgroundColor: "#1D96EC",
      borderColor: "dominant.white",
      boxShadow: "0px 0px 10px rgba(74, 85, 104, 0.45)",
    },
  },
};
