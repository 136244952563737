import { FC, memo } from "react";

import { useGate, useUnit } from "effector-react";

import { DatePickerGate } from "../../model/worker";
import { DatepickerPane } from "./DatepickerPane";

import { $mobileMonths } from "@/shared/model/store/calendar";

export const MobileDatePickerPaneWrapper: FC = memo(() => {
  useGate(DatePickerGate, "mobile");

  const monthDays = useUnit($mobileMonths);

  return (
    <>
      {monthDays?.map((month, i) => (
        <DatepickerPane
          days={month.days}
          date={month.date}
          key={`month-mobile-${i}`}
        />
      ))}
    </>
  );
});
