import { mainTheme } from "shared/ui/styles";

export const datepickerStyles = {
  borderRadius: "12px",

  leftMonthBox: {
    paddingLeft: "36px",
  },

  "&.expanded": {
    display: "flex",
    alignItems: "baseline",
  },
};

export const paneStyles = {
  datepickerDayLabel: {
    zIndex: 1,
    fontSize: "16px",
  },
  container: {
    padding: "16px 0px",

    [mainTheme.breakpoints.down("smd")]: {
      padding: "0px",
      marginBottom: "24px",
    },
  },
  monthWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",

    [mainTheme.breakpoints.down("smd")]: {
      marginBottom: "6px",
    },
  },
  wrapper: {
    top: "38px",
    padding: "16px",
    border: "1px solid",
    borderRadius: "8px",
    position: "relative",
    borderColor: "dominant.cloud",
    backgroundColor: "dominant.white",
    boxShadow: "0px 0px 15px rgba(74, 85, 104, 0.1)",
  },
  monthName: {
    fontWeight: 500,
    margin: "0 auto",
    textAlign: "center",
    textTransform: "capitalize",

    [mainTheme.breakpoints.down("smd")]: {
      margin: 0,
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "18px",
      color: "content.primary",
    },
  },
};
