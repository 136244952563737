import { mainTheme } from "shared/ui/styles";

export const styles = {
  contentWrapper: {
    maxWidth: "auto",
  },

  title: {
    fontWeight: 500,
    fontSize: "32px",
    maxWidth: "512px",
    marginBottom: "8px",
    color: "dominant.white",

    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "30px",
      marginBottom: "8px",
    },
  },

  form: {
    padding: "20px",
    maxWidth: "320px",
    backgroundColor: `${mainTheme.palette.dominant.cloud} !important`,
    [mainTheme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [mainTheme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },

  wrapperProps: {
    "& .MuiDialog-container": {
      alignItems: {
        xs: "center",
        sm: "center",
      },
    },

    "& .MuiDialog-paper": {
      margin: {
        xs: "15px",
      },
      borderRadius: {
        xs: "24px",
        sm: "24px",
      },
    },
  },

  subtitle: {
    fontWeight: 400,
    fontSize: "24px",
    fontStyle: "normal",
    marginBottom: "33px",
    lineHeight: "normal",
    color: "dominant.white",

    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "20px",
      maxWidth: "273px",
      marginBottom: "99px",
    },
  },

  closeIcon: {
    top: "16px",
    right: "16px",
    position: "absolute",
    color: "dominant.white",

    [mainTheme.breakpoints.down("sm")]: {
      padding: 0,
      left: "50%",
      top: "auto",
      right: "auto",
      width: "34px",
      height: "34px",
      bottom: "-50px",
      border: "1px solid white",
      transform: "translateX(-50%)",
    },
  },

  idleUserPaperSx: {
    padding: "36px",
    maxWidth: "680px",
    minHeight: "510px",
    backgroundSize: "cover",
    width: "100% !important",
    backgroundPosition: "right",

    "& .MuiDialogContent-root": {
      padding: 0,
    },

    backgroundImage: "url('/img/popupImages/IdleUserPicDesk.png')",

    [mainTheme.breakpoints.down("sm")]: {
      padding: "16px",
      maxWidth: "343px",
      paddingTop: "24px",
      minHeight: "549px",
      overflowY: "visible",
      backgroundImage: "url('/img/popupImages/IdleUserPicMob.png')",
    },
  },
};
