import { useMemo } from "react";

import { Box } from "@mui/material";

import {
  CARD_GUTTER,
  MOBILE_ROW_HEIGHT,
  DESKTOP_ROW_HEIGHT,
} from "../../model/constants";
import { styles } from "./styles";

import { TourListLoader } from "@/entities/tours";

import { useSearchBreakpoints } from "@/shared/lib/hooks";
import { ESearchType } from "@/shared/model/types/search";

export const Loader = () => {
  const { isTablet, isDesktop } = useSearchBreakpoints({
    type: ESearchType.Main,
  });

  const rowHeight = useMemo(
    () =>
      isDesktop || isTablet
        ? DESKTOP_ROW_HEIGHT + CARD_GUTTER
        : MOBILE_ROW_HEIGHT + CARD_GUTTER,
    [isDesktop, isTablet],
  );

  return (
    <Box sx={isDesktop || isTablet ? styles.loader : styles.mobileLoader}>
      {Array.from({ length: 5 }, (_, index) => (
        <TourListLoader
          key={index}
          sx={{ height: rowHeight }}
          isDesktop={isDesktop || isTablet}
        />
      ))}
    </Box>
  );
};
