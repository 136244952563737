import { FC, useMemo } from "react";

import { Search } from "@mui/icons-material";
import { Box, SxProps, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { DEFAULT_AMOUNT_OF_ADULTS } from "@/shared/lib/constants";
import { $arrival, $regions } from "@/shared/model/store/arrival";
import { $nights, $startDate } from "@/shared/model/store/calendar";
import { $adults, $children } from "@/shared/model/store/quantity";

type TProps = {
  sx: SxProps;
  onClick: () => void;
};

export const TourSearchMobileInfo: FC<TProps> = ({
  sx = {},
  onClick = () => {},
}) => {
  const t = useTranslations();
  const [nights, adults, children, startDate, arrival, arrivalRegions] =
    useUnit([$nights, $adults, $children, $startDate, $arrival, $regions]);

  // TODO: normalize data
  const regionsNames = arrival?.locations
    .filter((item) => arrivalRegions?.includes(item.id))
    .map((item) => item.name)
    .join(", ");

  const tourSearchInfo = useMemo(() => {
    if (!startDate || !nights || !adults) {
      return t("ENTER_COMPLETE_DATA");
    }

    const daysText = `${dayjs(startDate).format("DD MMM")} - ${dayjs(startDate)
      .add(nights, "days")
      .format("DD MMM")}, `;

    const rangeText = `${t("NIGHT_DECLINATIONS", { count: nights })}, `;

    const adultsText = t("ADULT_DECLINATIONS_SHORT", {
      count: adults ?? DEFAULT_AMOUNT_OF_ADULTS,
    });

    const childrenText =
      Number(children) > 0
        ? `, ${t("CHILDREN_DECLINATIONS_SHORT", { count: children })}`
        : "";
    return daysText + rangeText + adultsText + childrenText;
  }, [adults, children, startDate, nights, t]);

  return (
    <Box sx={sx} onClick={onClick}>
      <Box sx={styles.wrapper}>
        <Typography sx={styles.title}>
          {regionsNames || arrival?.name || "-"}
        </Typography>
        <Typography marginTop={"4px"} color={"grey.light"}>
          {tourSearchInfo}
        </Typography>
      </Box>
      <Search sx={{ color: "content.grey-200" }} />
    </Box>
  );
};
