import { FC } from "react";

import { List, SxProps, ListItem } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { AppDownloadButtons } from "@/shared/ui";

const LIST_ITEMS = [
  "TOUR_BOOKING_ONE_CLICK",
  "TRAVEL_NOTIFICATIONS",
  "EXCLUSIVE_OFFERS",
];

type TProps = {
  listSx?: SxProps;
};

export const ListBlock: FC<TProps> = ({ listSx = {} }) => {
  const t = useTranslations();

  return (
    <>
      <List sx={{ ...styles.list, ...listSx } as SxProps}>
        {LIST_ITEMS.map((item, index) => (
          <ListItem key={index} sx={styles.listItem}>
            {t(item)}
          </ListItem>
        ))}
      </List>
      <AppDownloadButtons />
    </>
  );
};
